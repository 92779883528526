import bubbleroomLogga from "../Img/bubbleroomAd.png";
import timarcoLogga from "../Img/timarcoAd.png";
import nkLogga from "../Img/nkAd.png";
import mqLogga from "../Img/mqAd.png";
import bjornborgLogga from "../Img/bjornborgAD.png";
import lindexLogga from "../Img/lindexAd.png";
import ginaLogga from "../Img/ginaAd.png";
import weekdayLooga from "../Img/weekdayAd.png";
import arketLogga from "../Img/arketAd.png";

import React, { useState, useEffect, useContext } from "react";
import { Box } from "@mui/material";
import OfferCards from "../components/OfferCards";
// import Ad from "../components/Ad";
import { SearchContext } from "../components/SearchContext";


import nk1 from "../Img/nk1.jpg"
import nk2 from "../Img/nk2.jpg"
import nk3 from "../Img/nk3.jpg"
import nk4 from "../Img/nk4.jpg"
import nk5 from "../Img/nk5.jpg"
import nk6 from "../Img/nk6.jpg"
import { Helmet } from "react-helmet-async";

const storeRows = [
  {
    name: "Bubbleroom",
    row: [
      { id: 7, price:'349.95:-', discount:'-30%', title: "double slit denim skirt", description: "Lång jeanskjol med hög slits fram och bak.", imgPro: nk1, url: "https://www.ginatricot.com/se/klader/kjolar/jeanskjolar/denim-skirt-221195621" },
      { id: 8, price:'149.95:-', discount:'-50%', title: "puff sleeve top", description: "Off-the-shoulder topp med en normal<br> passform.", imgPro: nk2, url: "https://www.ginatricot.com/se/klader/blusarskjortor/blusar-med-puffarm/puff-sleeve-top-238509000" },
      { id: 9, price:'139.95:-', discount:'-50%', title: "tie front top", description: "Knyttopp med kort puffärm. Toppen är<br> rosa och har knytstängning framtill.", imgPro: nk3, url: "https://www.ginatricot.com/se/klader/blusarskjortor/blusar-med-puffarm/tie-front-top-238983185" },
      { id: 10, price:'349.95:-', discount:'-30%', title: "flouncy long denim skirt", description: "Lång jeanskjol med volang i nederkant. ", imgPro: nk4, url: "https://www.ginatricot.com/se/klader/kjolar/jeanskjolar/denim-skirt-220235050" },
      { id: 11, price:'89.95:-', discount:'-30%', title: "clean hoops earrings", description: "Supplier: Alpha Fashion co. Ltd Production<br> unit: Dongguan City Zhanguan Hardware<br> Products Co., Ltd", imgPro: nk5, url: "https://www.ginatricot.com/se/accessoarer/smycken/orhangen/earrings-217907110" },
      { id: 12, price:'139.95:-', discount:'-50%', title: "tie front top", description: "Blommig knyttopp med kort puffärm. ", imgPro: nk6, url: "https://www.ginatricot.com/se/klader/toppar/knyttoppar/tie-front-top-238986890" },
    ],
    image: bubbleroomLogga,
  },
  {
    name: "Ginatricot",
    row: [
      { id: 7, price:'349.95:-', discount:'-30%', title: "double slit denim skirt", description: "Lång jeanskjol med hög slits fram och bak.", imgPro: nk1, url: "https://www.ginatricot.com/se/klader/kjolar/jeanskjolar/denim-skirt-221195621" },
      { id: 8, price:'149.95:-', discount:'-50%', title: "puff sleeve top", description: "Off-the-shoulder topp med en normal<br> passform.", imgPro: nk2, url: "https://www.ginatricot.com/se/klader/blusarskjortor/blusar-med-puffarm/puff-sleeve-top-238509000" },
      { id: 9, price:'139.95:-', discount:'-50%', title: "tie front top", description: "Knyttopp med kort puffärm. Toppen är<br> rosa och har knytstängning framtill.", imgPro: nk3, url: "https://www.ginatricot.com/se/klader/blusarskjortor/blusar-med-puffarm/tie-front-top-238983185" },
      { id: 10, price:'349.95:-', discount:'-30%', title: "flouncy long denim skirt", description: "Lång jeanskjol med volang i nederkant. ", imgPro: nk4, url: "https://www.ginatricot.com/se/klader/kjolar/jeanskjolar/denim-skirt-220235050" },
      { id: 11, price:'89.95:-', discount:'-30%', title: "clean hoops earrings", description: "Supplier: Alpha Fashion co. Ltd Production<br> unit: Dongguan City Zhanguan Hardware<br> Products Co., Ltd", imgPro: nk5, url: "https://www.ginatricot.com/se/accessoarer/smycken/orhangen/earrings-217907110" },
      { id: 12, price:'139.95:-', discount:'-50%', title: "tie front top", description: "Blommig knyttopp med kort puffärm. ", imgPro: nk6, url: "https://www.ginatricot.com/se/klader/toppar/knyttoppar/tie-front-top-238986890" },
    ],
    image: ginaLogga,
  },
  {
    name: "NK",
    row: [
      { id: 7, price:'349.95:-', discount:'-30%', title: "double slit denim skirt", description: "Lång jeanskjol med hög slits fram och bak.", imgPro: nk1, url: "https://www.ginatricot.com/se/klader/kjolar/jeanskjolar/denim-skirt-221195621" },
      { id: 8, price:'149.95:-', discount:'-50%', title: "puff sleeve top", description: "Off-the-shoulder topp med en normal<br> passform.", imgPro: nk2, url: "https://www.ginatricot.com/se/klader/blusarskjortor/blusar-med-puffarm/puff-sleeve-top-238509000" },
      { id: 9, price:'139.95:-', discount:'-50%', title: "tie front top", description: "Knyttopp med kort puffärm. Toppen är<br> rosa och har knytstängning framtill.", imgPro: nk3, url: "https://www.ginatricot.com/se/klader/blusarskjortor/blusar-med-puffarm/tie-front-top-238983185" },
      { id: 10, price:'349.95:-', discount:'-30%', title: "flouncy long denim skirt", description: "Lång jeanskjol med volang i nederkant. ", imgPro: nk4, url: "https://www.ginatricot.com/se/klader/kjolar/jeanskjolar/denim-skirt-220235050" },
      { id: 11, price:'89.95:-', discount:'-30%', title: "clean hoops earrings", description: "Supplier: Alpha Fashion co. Ltd Production<br> unit: Dongguan City Zhanguan Hardware<br> Products Co., Ltd", imgPro: nk5, url: "https://www.ginatricot.com/se/accessoarer/smycken/orhangen/earrings-217907110" },
      { id: 12, price:'139.95:-', discount:'-50%', title: "tie front top", description: "Blommig knyttopp med kort puffärm. ", imgPro: nk6, url: "https://www.ginatricot.com/se/klader/toppar/knyttoppar/tie-front-top-238986890" },
    ],
    image: nkLogga,
  },
  {
    name: "Timarco",
    row: [
      { id: 7, price:'349.95:-', discount:'-30%', title: "double slit denim skirt", description: "Lång jeanskjol med hög slits fram och bak.", imgPro: nk1, url: "https://www.ginatricot.com/se/klader/kjolar/jeanskjolar/denim-skirt-221195621" },
      { id: 8, price:'149.95:-', discount:'-50%', title: "puff sleeve top", description: "Off-the-shoulder topp med en normal<br> passform.", imgPro: nk2, url: "https://www.ginatricot.com/se/klader/blusarskjortor/blusar-med-puffarm/puff-sleeve-top-238509000" },
      { id: 9, price:'139.95:-', discount:'-50%', title: "tie front top", description: "Knyttopp med kort puffärm. Toppen är<br> rosa och har knytstängning framtill.", imgPro: nk3, url: "https://www.ginatricot.com/se/klader/blusarskjortor/blusar-med-puffarm/tie-front-top-238983185" },
      { id: 10, price:'349.95:-', discount:'-30%', title: "flouncy long denim skirt", description: "Lång jeanskjol med volang i nederkant. ", imgPro: nk4, url: "https://www.ginatricot.com/se/klader/kjolar/jeanskjolar/denim-skirt-220235050" },
      { id: 11, price:'89.95:-', discount:'-30%', title: "clean hoops earrings", description: "Supplier: Alpha Fashion co. Ltd Production<br> unit: Dongguan City Zhanguan Hardware<br> Products Co., Ltd", imgPro: nk5, url: "https://www.ginatricot.com/se/accessoarer/smycken/orhangen/earrings-217907110" },
      { id: 12, price:'139.95:-', discount:'-50%', title: "tie front top", description: "Blommig knyttopp med kort puffärm. ", imgPro: nk6, url: "https://www.ginatricot.com/se/klader/toppar/knyttoppar/tie-front-top-238986890" },
    ],
    image: timarcoLogga,
  },
  {
    name: "MQ",
    row: [
      { id: 7, price:'349.95:-', discount:'-30%', title: "double slit denim skirt", description: "Lång jeanskjol med hög slits fram och bak.", imgPro: nk1, url: "https://www.ginatricot.com/se/klader/kjolar/jeanskjolar/denim-skirt-221195621" },
      { id: 8, price:'149.95:-', discount:'-50%', title: "puff sleeve top", description: "Off-the-shoulder topp med en normal<br> passform.", imgPro: nk2, url: "https://www.ginatricot.com/se/klader/blusarskjortor/blusar-med-puffarm/puff-sleeve-top-238509000" },
      { id: 9, price:'139.95:-', discount:'-50%', title: "tie front top", description: "Knyttopp med kort puffärm. Toppen är<br> rosa och har knytstängning framtill.", imgPro: nk3, url: "https://www.ginatricot.com/se/klader/blusarskjortor/blusar-med-puffarm/tie-front-top-238983185" },
      { id: 10, price:'349.95:-', discount:'-30%', title: "flouncy long denim skirt", description: "Lång jeanskjol med volang i nederkant. ", imgPro: nk4, url: "https://www.ginatricot.com/se/klader/kjolar/jeanskjolar/denim-skirt-220235050" },
      { id: 11, price:'89.95:-', discount:'-30%', title: "clean hoops earrings", description: "Supplier: Alpha Fashion co. Ltd Production<br> unit: Dongguan City Zhanguan Hardware<br> Products Co., Ltd", imgPro: nk5, url: "https://www.ginatricot.com/se/accessoarer/smycken/orhangen/earrings-217907110" },
      { id: 12, price:'139.95:-', discount:'-50%', title: "tie front top", description: "Blommig knyttopp med kort puffärm. ", imgPro: nk6, url: "https://www.ginatricot.com/se/klader/toppar/knyttoppar/tie-front-top-238986890" },
    ],
    image: mqLogga,
  },
  {
    name: "Bjornborg",
    row: [
      { id: 7, price:'349.95:-', discount:'-30%', title: "double slit denim skirt", description: "Lång jeanskjol med hög slits fram och bak.", imgPro: nk1, url: "https://www.ginatricot.com/se/klader/kjolar/jeanskjolar/denim-skirt-221195621" },
      { id: 8, price:'149.95:-', discount:'-50%', title: "puff sleeve top", description: "Off-the-shoulder topp med en normal<br> passform.", imgPro: nk2, url: "https://www.ginatricot.com/se/klader/blusarskjortor/blusar-med-puffarm/puff-sleeve-top-238509000" },
      { id: 9, price:'139.95:-', discount:'-50%', title: "tie front top", description: "Knyttopp med kort puffärm. Toppen är<br> rosa och har knytstängning framtill.", imgPro: nk3, url: "https://www.ginatricot.com/se/klader/blusarskjortor/blusar-med-puffarm/tie-front-top-238983185" },
      { id: 10, price:'349.95:-', discount:'-30%', title: "flouncy long denim skirt", description: "Lång jeanskjol med volang i nederkant. ", imgPro: nk4, url: "https://www.ginatricot.com/se/klader/kjolar/jeanskjolar/denim-skirt-220235050" },
      { id: 11, price:'89.95:-', discount:'-30%', title: "clean hoops earrings", description: "Supplier: Alpha Fashion co. Ltd Production<br> unit: Dongguan City Zhanguan Hardware<br> Products Co., Ltd", imgPro: nk5, url: "https://www.ginatricot.com/se/accessoarer/smycken/orhangen/earrings-217907110" },
      { id: 12, price:'139.95:-', discount:'-50%', title: "tie front top", description: "Blommig knyttopp med kort puffärm. ", imgPro: nk6, url: "https://www.ginatricot.com/se/klader/toppar/knyttoppar/tie-front-top-238986890" },
    ],
    image: bjornborgLogga,
  },
  {
    name: "Lindex",
    row: [
      { id: 7, price:'349.95:-', discount:'-30%', title: "double slit denim skirt", description: "Lång jeanskjol med hög slits fram och bak.", imgPro: nk1, url: "https://www.ginatricot.com/se/klader/kjolar/jeanskjolar/denim-skirt-221195621" },
      { id: 8, price:'149.95:-', discount:'-50%', title: "puff sleeve top", description: "Off-the-shoulder topp med en normal<br> passform.", imgPro: nk2, url: "https://www.ginatricot.com/se/klader/blusarskjortor/blusar-med-puffarm/puff-sleeve-top-238509000" },
      { id: 9, price:'139.95:-', discount:'-50%', title: "tie front top", description: "Knyttopp med kort puffärm. Toppen är<br> rosa och har knytstängning framtill.", imgPro: nk3, url: "https://www.ginatricot.com/se/klader/blusarskjortor/blusar-med-puffarm/tie-front-top-238983185" },
      { id: 10, price:'349.95:-', discount:'-30%', title: "flouncy long denim skirt", description: "Lång jeanskjol med volang i nederkant. ", imgPro: nk4, url: "https://www.ginatricot.com/se/klader/kjolar/jeanskjolar/denim-skirt-220235050" },
      { id: 11, price:'89.95:-', discount:'-30%', title: "clean hoops earrings", description: "Supplier: Alpha Fashion co. Ltd Production<br> unit: Dongguan City Zhanguan Hardware<br> Products Co., Ltd", imgPro: nk5, url: "https://www.ginatricot.com/se/accessoarer/smycken/orhangen/earrings-217907110" },
      { id: 12, price:'139.95:-', discount:'-50%', title: "tie front top", description: "Blommig knyttopp med kort puffärm. ", imgPro: nk6, url: "https://www.ginatricot.com/se/klader/toppar/knyttoppar/tie-front-top-238986890" },
    ],
    image: lindexLogga,
  },
  {
    name: "Weekday",
    row: [
      { id: 7, price:'349.95:-', discount:'-30%', title: "double slit denim skirt", description: "Lång jeanskjol med hög slits fram och bak.", imgPro: nk1, url: "https://www.ginatricot.com/se/klader/kjolar/jeanskjolar/denim-skirt-221195621" },
      { id: 8, price:'149.95:-', discount:'-50%', title: "puff sleeve top", description: "Off-the-shoulder topp med en normal<br> passform.", imgPro: nk2, url: "https://www.ginatricot.com/se/klader/blusarskjortor/blusar-med-puffarm/puff-sleeve-top-238509000" },
      { id: 9, price:'139.95:-', discount:'-50%', title: "tie front top", description: "Knyttopp med kort puffärm. Toppen är<br> rosa och har knytstängning framtill.", imgPro: nk3, url: "https://www.ginatricot.com/se/klader/blusarskjortor/blusar-med-puffarm/tie-front-top-238983185" },
      { id: 10, price:'349.95:-', discount:'-30%', title: "flouncy long denim skirt", description: "Lång jeanskjol med volang i nederkant. ", imgPro: nk4, url: "https://www.ginatricot.com/se/klader/kjolar/jeanskjolar/denim-skirt-220235050" },
      { id: 11, price:'89.95:-', discount:'-30%', title: "clean hoops earrings", description: "Supplier: Alpha Fashion co. Ltd Production<br> unit: Dongguan City Zhanguan Hardware<br> Products Co., Ltd", imgPro: nk5, url: "https://www.ginatricot.com/se/accessoarer/smycken/orhangen/earrings-217907110" },
      { id: 12, price:'139.95:-', discount:'-50%', title: "tie front top", description: "Blommig knyttopp med kort puffärm. ", imgPro: nk6, url: "https://www.ginatricot.com/se/klader/toppar/knyttoppar/tie-front-top-238986890" },
    ],
    image: weekdayLooga,
  },
  {
    name: "Arket",
    row: [
      { id: 7, price:'349.95:-', discount:'-30%', title: "double slit denim skirt", description: "Lång jeanskjol med hög slits fram och bak.", imgPro: nk1, url: "https://www.ginatricot.com/se/klader/kjolar/jeanskjolar/denim-skirt-221195621" },
      { id: 8, price:'149.95:-', discount:'-50%', title: "puff sleeve top", description: "Off-the-shoulder topp med en normal<br> passform.", imgPro: nk2, url: "https://www.ginatricot.com/se/klader/blusarskjortor/blusar-med-puffarm/puff-sleeve-top-238509000" },
      { id: 9, price:'139.95:-', discount:'-50%', title: "tie front top", description: "Knyttopp med kort puffärm. Toppen är<br> rosa och har knytstängning framtill.", imgPro: nk3, url: "https://www.ginatricot.com/se/klader/blusarskjortor/blusar-med-puffarm/tie-front-top-238983185" },
      { id: 10, price:'349.95:-', discount:'-30%', title: "flouncy long denim skirt", description: "Lång jeanskjol med volang i nederkant. ", imgPro: nk4, url: "https://www.ginatricot.com/se/klader/kjolar/jeanskjolar/denim-skirt-220235050" },
      { id: 11, price:'89.95:-', discount:'-30%', title: "clean hoops earrings", description: "Supplier: Alpha Fashion co. Ltd Production<br> unit: Dongguan City Zhanguan Hardware<br> Products Co., Ltd", imgPro: nk5, url: "https://www.ginatricot.com/se/accessoarer/smycken/orhangen/earrings-217907110" },
      { id: 12, price:'139.95:-', discount:'-50%', title: "tie front top", description: "Blommig knyttopp med kort puffärm. ", imgPro: nk6, url: "https://www.ginatricot.com/se/klader/toppar/knyttoppar/tie-front-top-238986890" },
    ],
    image: arketLogga,
  },

];

function shuffle(array) {
  return array.sort(() => Math.random() - 0.5);
}

function Clothes() {
  const [shuffledRowsWithImages, setShuffledRowsWithImages] = useState([]);
  const { searchText } = useContext(SearchContext);
  useEffect(() => {
    setShuffledRowsWithImages(shuffle([...storeRows]));
  }, []);

  const filteredRows = shuffledRowsWithImages.filter((item) =>
    item?.name?.toLowerCase().includes(searchText.toLowerCase())
  );
  return (
    <>

<Helmet>
  <title>SuperDeals.nu - Fantastiska deals på kläder</title>
  <meta
    name="description"
    content="Upptäck fantastiska erbjudanden på kläder på SuperDeals.nu. Hitta de bästa rabatterna på damkläder, herrkläder, barnkläder och mer."
  />
  <meta
    name="keywords"
    content="kläder, deals på kläder, superdeals kläder, erbjudanden på damkläder, billiga herrkläder, rabatter på barnkläder, damkläder, herrkläder, barnkläder, mode, trendiga kläder, klänningar, jeans, t-shirts, jackor, accessoarer, kläder rea, kläder rabatt, SuperDeals.nu, klädrea, märkeskläder, online shopping, moderiktiga kläder, stil, sommarkläder, vinterkläder"
  />
  <link rel="canonical" href="https://www.superdeals.nu/klader" />
  {/* <meta property="og:title" content="SuperDeals.nu - Fantastiska deals på kläder" />
  <meta property="og:description" content="Upptäck fantastiska erbjudanden på kläder på SuperDeals.nu. Hitta de bästa rabatterna på damkläder, herrkläder, barnkläder och mer." />
  <meta property="og:url" content="https://www.superdeals.nu/klader" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://www.superdeals.nu/images/og-image.jpg" />
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="SuperDeals.nu - Fantastiska deals på kläder" />
  <meta name="twitter:description" content="Upptäck fantastiska erbjudanden på kläder på SuperDeals.nu. Hitta de bästa rabatterna på damkläder, herrkläder, barnkläder och mer." />
  <meta name="twitter:image" content="https://www.superdeals.nu/images/twitter-image.jpg" /> */}
</Helmet>




      {/* <Ad type="large" /> */}
      {filteredRows.map((item, index) => (
        <Box key={index} my={2}>
          <img src={item.image} alt="" style={{display: 'block', marginLeft: 'auto', marginRight:'auto'}}/>
          <OfferCards stores={item.row} name={item} storeId={`store${index + 1}`} />
          {/* {index !== filteredRows.length - 1 && <Ad type="small" />} */}
        </Box>
      ))}
    </>
  );
}

export default Clothes;
