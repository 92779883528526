import React from "react";
import { Container, Box, Typography, Grid } from "@mui/material";

function MissionValues() {
  return (
    <Box sx={{ backgroundColor: "background.paper", py: 5 }}>
      <Container maxWidth="md">
        <Typography variant="h4" gutterBottom>
          Uppdrag & Värderingar
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              Vårt Uppdrag
            </Typography>
            <Typography variant="body1" paragraph>
              På Superdeals.nu strävar vi efter att göra online shopping enkelt och prisvärt för våra användare. Vårt uppdrag är att samla de bästa erbjudandena från olika butiker och göra det lättare för dig att jämföra och välja det som passar dig bäst.
            </Typography>
            <Typography variant="body1" paragraph>
              Vi är dedikerade till att skapa en plattform som inte bara erbjuder bra deals, utan också en användarvänlig och tillförlitlig upplevelse varje gång du besöker oss. Vår passion för teknik och innovation driver oss att ständigt förbättra och optimera Superdeals.nu.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              Våra Värderingar
            </Typography>
            <Typography variant="body1" paragraph>
              På Superdeals.nu lever vi efter starka värderingar som styr vårt arbete och interaktioner:
            </Typography>
            <ul>
              <li>
                <Typography variant="body1">
                  <strong>Kundfokus:</strong> Vi sätter våra användares behov och förväntningar i centrum av allt vi gör.
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  <strong>Kvalitet och Tillförlitlighet:</strong> Vi strävar efter att erbjuda högkvalitativa tjänster och upprätthålla en hög standard av tillförlitlighet i allt vi levererar.
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  <strong>Ärlighet och Transparens:</strong> Vi agerar med ärlighet och transparens gentemot våra användare, partners och varandra.
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  <strong>Innovation:</strong> Vi omfamnar förändring och strävar efter ständig innovation för att förbättra vår plattform och användarupplevelse.
                </Typography>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default MissionValues;
