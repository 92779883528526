import React from "react";
import { Container, Box, Typography, TextField, Button, Grid } from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';

function ContactUs() {
  return (
    <Box sx={{ backgroundColor: "background.paper", py: 5 }}>
      <Container maxWidth="md">
        <Typography variant="h4" gutterBottom>
          Kontakta oss
        </Typography>
        <Typography variant="body1" paragraph>
          Om du har några frågor, kommentarer eller förslag, tveka inte att kontakta oss. Vi ser fram emot att höra från dig!
        </Typography>
        <Grid container spacing={3} sx={{ mt: 2 }}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              Kontaktinformation
            </Typography>
            <Box display="flex" alignItems="center" mb={1}>
              <LocationOnIcon color="primary" />
              <Typography variant="body1" ml={1}>
                Superdeals.nu
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" mb={1}>
              <PhoneIcon color="primary" />
              <Typography variant="body1" ml={1}>
                Telefon: +46 707138891
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" mb={1}>
              <EmailIcon color="primary" />
              <Typography variant="body1" ml={1}>
                E-post: kontakt@superdeals.nu
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              Skicka ett meddelande
            </Typography>
            <TextField
              fullWidth
              label="Namn"
              variant="outlined"
              margin="normal"
            />
            <TextField
              fullWidth
              label="E-post"
              variant="outlined"
              margin="normal"
            />
            <TextField
              fullWidth
              label="Meddelande"
              variant="outlined"
              margin="normal"
              multiline
              rows={4}
            />
            <Button variant="contained" color="primary" sx={{ mt: 2 }}>
              Skicka
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ContactUs;
