// src/PrivacyPolicy.js
import React from 'react';
import { Container, Typography, List, ListItem } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Container style={{ padding: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Integritetspolicy för Superdeals.nu
      </Typography>
      
      <Typography variant="h6" gutterBottom>
        Introduktion
      </Typography>
      <Typography variant="body1" paragraph>
        Välkommen till www.superdeals.nu ("Webbplatsen"). Vi värnar om din integritet och är engagerade i att skydda dina personuppgifter. Denna integritetspolicy förklarar hur vi samlar in, använder, och skyddar din information när du använder vår Webbplats.
      </Typography>
      
      <Typography variant="h6" gutterBottom>
        Insamling av information
      </Typography>
      <Typography variant="body1" paragraph>
        Vi kan samla in följande typer av information från dig när du besöker vår Webbplats:
      </Typography>
      <List>
        <ListItem>
          Personuppgifter: Namn, e-postadress, telefonnummer, etc. som du frivilligt ger oss när du registrerar dig för nyhetsbrev eller kontaktar oss.
        </ListItem>
        <ListItem>
          Användningsdata: Information om hur du använder Webbplatsen, såsom vilka sidor du besöker, vilka produkter du jämför och vilka länkar du klickar på.
        </ListItem>
        <ListItem>
          Teknisk data: IP-adress, webbläsartyp, enhetsinformation, etc.
        </ListItem>
      </List>
      
      <Typography variant="h6" gutterBottom>
        Användning av information
      </Typography>
      <Typography variant="body1" paragraph>
        Den information vi samlar in används för följande ändamål:
      </Typography>
      <List>
        <ListItem>
          För att förbättra vår Webbplats: Vi analyserar användningsdata för att förstå hur vår Webbplats används och för att göra förbättringar.
        </ListItem>
        <ListItem>
          För att kommunicera med dig: Vi kan använda dina kontaktuppgifter för att skicka nyhetsbrev, svara på dina frågor och ge dig uppdateringar om våra tjänster.
        </ListItem>
        <ListItem>
          För marknadsföring: Med ditt samtycke kan vi skicka dig erbjudanden och kampanjer från tredje parter som vi tror kan vara av intresse för dig.
        </ListItem>
      </List>
      
      <Typography variant="h6" gutterBottom>
        Delning av information
      </Typography>
      <Typography variant="body1" paragraph>
        Vi delar inte dina personuppgifter med tredje parter utan ditt samtycke, förutom i följande fall:
      </Typography>
      <List>
        <ListItem>
          Tjänsteleverantörer: Vi kan dela din information med betrodda tredjepartsleverantörer som hjälper oss att driva Webbplatsen och tillhandahålla våra tjänster.
        </ListItem>
        <ListItem>
          Lagkrav: Vi kan avslöja din information om det krävs enligt lag eller för att skydda våra rättigheter och säkerhet.
        </ListItem>
      </List>
      
      <Typography variant="h6" gutterBottom>
        Cookies och spårningsteknologier
      </Typography>
      <Typography variant="body1" paragraph>
        Vi använder cookies och liknande spårningsteknologier för att förbättra din upplevelse på Webbplatsen. Cookies hjälper oss att förstå hur du använder Webbplatsen och låter oss anpassa innehållet och annonserna efter dina intressen. Du kan hantera dina cookie-inställningar via din webbläsare.
      </Typography>
      
      <Typography variant="h6" gutterBottom>
        Dina rättigheter
      </Typography>
      <Typography variant="body1" paragraph>
        Du har rätt att:
      </Typography>
      <List>
        <ListItem>
          Begära åtkomst till de personuppgifter vi har om dig.
        </ListItem>
        <ListItem>
          Begära rättelse av felaktiga eller ofullständiga personuppgifter.
        </ListItem>
        <ListItem>
          Begära radering av dina personuppgifter under vissa omständigheter.
        </ListItem>
        <ListItem>
          Invända mot behandling av dina personuppgifter för direktmarknadsföring.
        </ListItem>
      </List>
      <Typography variant="body1" paragraph>
        För att utöva dina rättigheter, vänligen kontakta oss på kontakt@superdeals.nu.
      </Typography>
      
      <Typography variant="h6" gutterBottom>
        Ändringar i denna policy
      </Typography>
      <Typography variant="body1" paragraph>
        Vi kan uppdatera denna integritetspolicy från tid till annan. Vi kommer att meddela dig om eventuella förändringar genom att publicera den nya policyn på denna sida. Vi uppmuntrar dig att regelbundet granska denna policy för att hålla dig informerad om hur vi skyddar din information.
      </Typography>
      
      <Typography variant="h6" gutterBottom>
        Kontakta oss
      </Typography>
      <Typography variant="body1" paragraph>
        Om du har några frågor om denna integritetspolicy eller vår hantering av dina personuppgifter, vänligen kontakta oss på:
      </Typography>
      <Typography variant="body1">
        <strong>Superdeals.nu</strong><br />
        E-post: kontakt@superdeals.nu
      </Typography>
    </Container>
  );
};

export default PrivacyPolicy;
