import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Grid,
  CircularProgress,
  Box,
  CardContent,
  CardMedia,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import axios from 'axios';
import Card from "@mui/material/Card";


function AquaLimone() {


    const TRADEDOUBLER_API_URL = '/api/products';

    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      const fetchProducts = async () => {
        try {
          const response = await axios.get(TRADEDOUBLER_API_URL);
          setProducts(response.data.products); // Justera detta beroende på den exakta strukturen av API-responsen
          setLoading(false);
        } catch (error) {
          setError(error);
          setLoading(false);
        }
      };
  
      fetchProducts();
    }, []);
  
    if (loading) return <Box display="flex" justifyContent="center" mt={5}><CircularProgress /></Box>;
    if (error) {
      console.error('Error loading products:', error);
      return (
        <Box display="flex" justifyContent="center" mt={5}>
          <Typography color="error">Error loading products: {error.message}</Typography>
        </Box>
      );
    }




  return (
   
    
    <Container>
      <Typography variant="h2" component="h1" gutterBottom>
        Products
      </Typography>
      <Grid container spacing={4}>
        {products.map(product => (
          <Grid item key={product.id} xs={12} sm={6} md={4}>
            <Card>
              <CardMedia
                component="img"
                height="200"
                image={product.productImage.url}
                // alt={product.brand}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {product.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {product.description}
                </Typography>
                <Typography variant="h6" component="div" mt={2}>
                  Price: {product.price}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>


  )
}

export default AquaLimone