import beijerLogga from "../Img/beijerAd.png";
import out1Logga from "../Img/out1Ad.png";
import proffsmagasinetLogga from "../Img/proffsmagasinetAd.png";
import servicefinderLogga from "../Img/servicefinderAd.png";
import skanskabyggvarorLogga from "../Img/skanskabyggvarorAd.png";
import verktygsproffsenLogga from "../Img/verktygsproffsenAd.png";
import wexthusetLogga from "../Img/wexthusetAd.png";

import React, { useState, useEffect, useContext } from "react";
import { Box } from "@mui/material";
import OfferCards from "../components/OfferCards";
// import Ad from "../components/Ad";
import { SearchContext } from "../components/SearchContext";
import bygg1 from "../Img/bygg1.jpg"
import bygg2 from "../Img/bygg2.jpg"
import bygg3 from "../Img/bygg3.jpg"
import bygg4 from "../Img/bygg4.jpg"
import bygg5 from "../Img/bygg5.jpg"
import bygg6 from "../Img/bygg6.jpg"
import { Helmet } from "react-helmet-async";

const storeRows = [
    {
        name: "Beijer",
        row: [
          { id: 433, price:'224:-', discount:'-28%', title: "TRÄSKYDD SIOO:X ALTAN<br> PREMIUM", description: "Premium Träskydd Altan i kombination med<br> Premium Ytskydd Altan", imgPro: bygg1, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/oljor-traskydd/traskydd/traskydd-sioo-x-altan-premium-333505742226" },
          { id: 434, price:'392:-', discount:'-21%', title: "FASADFÄRG ONE SUPER TECH", description: "One Super Tech från Nordsjö är en <br>självrengörande akrylattäckfärg avsedd för<br> målning på träfasader.", imgPro: bygg2, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/utomhusfarg/fasadfarg/fasadfarg-one-super-tech" },
          { id: 435, price:'135:-', discount:'-10%', title: "SKARVSLADD H05VV-F 3G1.5 VIT", description: "Skarvsladd (ej hängbar frp)", imgPro: bygg3, url: "https://www.beijerbygg.se/privat/sv/produkter/elinstallation/skarvsladd-kabelvindor/skarvsladd/skarvsladd-h05vv-f-3g1-5-vit" },
          { id: 436, price:'2 640:-', discount:'-12%', title: "NO TINOVA VX+ 2 IN 1 BW 10 L", description: "Träfasadfärg utomhus, vattenburen grund-<br> och täckfärg BW", imgPro: bygg4, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/utomhusfarg/fasadfarg/no-tinova-vx-2-in-1-bw-10-l" },
          { id: 437, price:'241:-', discount:'-18%', title: "GRUNDOLJA TINOVA WOOD BASE <br>1L OILBPR NORDSJÖ UTOMHUS", description: "Tinova Wood Base Oil BPR", imgPro: bygg5, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/oljor-traskydd/traskydd/grundolja-tinova-wood-base-1l-oilbpr-nordsjo-utomhus-900235060" },
          { id: 438, price:'571:-', discount:'-18%', title: "GRUNDOLJA TINOVA WOOD BASE<br> 2,5 OILBPR NORDSJÖ UTOMHUS<br> 2,5L", description: "Tinova Wood Base Oil BPR", imgPro: bygg6, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/oljor-traskydd/traskydd/grundolja-tinova-wood-base-2-5-oilbpr-nordsjo-utomhus-2-5l-900235061" },
        ],
        image: beijerLogga,
      },
      {
        name: "Out1",
        row: [
            { id: 433, price:'224:-', discount:'-28%', title: "TRÄSKYDD SIOO:X ALTAN<br> PREMIUM", description: "Premium Träskydd Altan i kombination med<br> Premium Ytskydd Altan", imgPro: bygg1, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/oljor-traskydd/traskydd/traskydd-sioo-x-altan-premium-333505742226" },
            { id: 434, price:'392:-', discount:'-21%', title: "FASADFÄRG ONE SUPER TECH", description: "One Super Tech från Nordsjö är en <br>självrengörande akrylattäckfärg avsedd för<br> målning på träfasader.", imgPro: bygg2, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/utomhusfarg/fasadfarg/fasadfarg-one-super-tech" },
            { id: 435, price:'135:-', discount:'-10%', title: "SKARVSLADD H05VV-F 3G1.5 VIT", description: "Skarvsladd (ej hängbar frp)", imgPro: bygg3, url: "https://www.beijerbygg.se/privat/sv/produkter/elinstallation/skarvsladd-kabelvindor/skarvsladd/skarvsladd-h05vv-f-3g1-5-vit" },
            { id: 436, price:'2 640:-', discount:'-12%', title: "NO TINOVA VX+ 2 IN 1 BW 10 L", description: "Träfasadfärg utomhus, vattenburen grund-<br> och täckfärg BW", imgPro: bygg4, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/utomhusfarg/fasadfarg/no-tinova-vx-2-in-1-bw-10-l" },
            { id: 437, price:'241:-', discount:'-18%', title: "GRUNDOLJA TINOVA WOOD BASE <br>1L OILBPR NORDSJÖ UTOMHUS", description: "Tinova Wood Base Oil BPR", imgPro: bygg5, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/oljor-traskydd/traskydd/grundolja-tinova-wood-base-1l-oilbpr-nordsjo-utomhus-900235060" },
            { id: 438, price:'571:-', discount:'-18%', title: "GRUNDOLJA TINOVA WOOD BASE<br> 2,5 OILBPR NORDSJÖ UTOMHUS<br> 2,5L", description: "Tinova Wood Base Oil BPR", imgPro: bygg6, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/oljor-traskydd/traskydd/grundolja-tinova-wood-base-2-5-oilbpr-nordsjo-utomhus-2-5l-900235061" },
        ],
        image: out1Logga,
      },
      {
        name: "Proffsmagasinet",
        row: [
          { id: 445, price:'224:-', discount:'-28%', title: "TRÄSKYDD SIOO:X ALTAN<br> PREMIUM", description: "Premium Träskydd Altan i kombination med<br> Premium Ytskydd Altan", imgPro: bygg1, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/oljor-traskydd/traskydd/traskydd-sioo-x-altan-premium-333505742226" },
          { id: 446, price:'392:-', discount:'-21%', title: "FASADFÄRG ONE SUPER TECH", description: "One Super Tech från Nordsjö är en <br>självrengörande akrylattäckfärg avsedd för<br> målning på träfasader.", imgPro: bygg2, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/utomhusfarg/fasadfarg/fasadfarg-one-super-tech" },
          { id: 447, price:'135:-', discount:'-10%', title: "SKARVSLADD H05VV-F 3G1.5 VIT", description: "Skarvsladd (ej hängbar frp)", imgPro: bygg3, url: "https://www.beijerbygg.se/privat/sv/produkter/elinstallation/skarvsladd-kabelvindor/skarvsladd/skarvsladd-h05vv-f-3g1-5-vit" },
          { id: 448, price:'2 640:-', discount:'-12%', title: "NO TINOVA VX+ 2 IN 1 BW 10 L", description: "Träfasadfärg utomhus, vattenburen grund-<br> och täckfärg BW", imgPro: bygg4, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/utomhusfarg/fasadfarg/no-tinova-vx-2-in-1-bw-10-l" },
          { id: 449, price:'241:-', discount:'-18%', title: "GRUNDOLJA TINOVA WOOD BASE <br>1L OILBPR NORDSJÖ UTOMHUS", description: "Tinova Wood Base Oil BPR", imgPro: bygg5, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/oljor-traskydd/traskydd/grundolja-tinova-wood-base-1l-oilbpr-nordsjo-utomhus-900235060" },
          { id: 450, price:'571:-', discount:'-18%', title: "GRUNDOLJA TINOVA WOOD BASE<br> 2,5 OILBPR NORDSJÖ UTOMHUS<br> 2,5L", description: "Tinova Wood Base Oil BPR", imgPro: bygg6, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/oljor-traskydd/traskydd/grundolja-tinova-wood-base-2-5-oilbpr-nordsjo-utomhus-2-5l-900235061" },
        ],
        image: proffsmagasinetLogga,
      },
      {
        name: "Servicefinder",
        row: [
          { id: 451, price:'224:-', discount:'-28%', title: "TRÄSKYDD SIOO:X ALTAN<br> PREMIUM", description: "Premium Träskydd Altan i kombination med<br> Premium Ytskydd Altan", imgPro: bygg1, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/oljor-traskydd/traskydd/traskydd-sioo-x-altan-premium-333505742226" },
          { id: 452, price:'392:-', discount:'-21%', title: "FASADFÄRG ONE SUPER TECH", description: "One Super Tech från Nordsjö är en <br>självrengörande akrylattäckfärg avsedd för<br> målning på träfasader.", imgPro: bygg2, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/utomhusfarg/fasadfarg/fasadfarg-one-super-tech" },
          { id: 453, price:'135:-', discount:'-10%', title: "SKARVSLADD H05VV-F 3G1.5 VIT", description: "Skarvsladd (ej hängbar frp)", imgPro: bygg3, url: "https://www.beijerbygg.se/privat/sv/produkter/elinstallation/skarvsladd-kabelvindor/skarvsladd/skarvsladd-h05vv-f-3g1-5-vit" },
          { id: 454, price:'2 640:-', discount:'-12%', title: "NO TINOVA VX+ 2 IN 1 BW 10 L", description: "Träfasadfärg utomhus, vattenburen grund-<br> och täckfärg BW", imgPro: bygg4, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/utomhusfarg/fasadfarg/no-tinova-vx-2-in-1-bw-10-l" },
          { id: 455, price:'241:-', discount:'-18%', title: "GRUNDOLJA TINOVA WOOD BASE <br>1L OILBPR NORDSJÖ UTOMHUS", description: "Tinova Wood Base Oil BPR", imgPro: bygg5, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/oljor-traskydd/traskydd/grundolja-tinova-wood-base-1l-oilbpr-nordsjo-utomhus-900235060" },
          { id: 456, price:'571:-', discount:'-18%', title: "GRUNDOLJA TINOVA WOOD BASE<br> 2,5 OILBPR NORDSJÖ UTOMHUS<br> 2,5L", description: "Tinova Wood Base Oil BPR", imgPro: bygg6, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/oljor-traskydd/traskydd/grundolja-tinova-wood-base-2-5-oilbpr-nordsjo-utomhus-2-5l-900235061" },
        ],
        image: servicefinderLogga,
      },
      {
        name: "Skanskabyggvaror",
        row: [
          { id: 457, price:'224:-', discount:'-28%', title: "TRÄSKYDD SIOO:X ALTAN<br> PREMIUM", description: "Premium Träskydd Altan i kombination med<br> Premium Ytskydd Altan", imgPro: bygg1, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/oljor-traskydd/traskydd/traskydd-sioo-x-altan-premium-333505742226" },
          { id: 458, price:'392:-', discount:'-21%', title: "FASADFÄRG ONE SUPER TECH", description: "One Super Tech från Nordsjö är en <br>självrengörande akrylattäckfärg avsedd för<br> målning på träfasader.", imgPro: bygg2, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/utomhusfarg/fasadfarg/fasadfarg-one-super-tech" },
          { id: 459, price:'135:-', discount:'-10%', title: "SKARVSLADD H05VV-F 3G1.5 VIT", description: "Skarvsladd (ej hängbar frp)", imgPro: bygg3, url: "https://www.beijerbygg.se/privat/sv/produkter/elinstallation/skarvsladd-kabelvindor/skarvsladd/skarvsladd-h05vv-f-3g1-5-vit" },
          { id: 460, price:'2 640:-', discount:'-12%', title: "NO TINOVA VX+ 2 IN 1 BW 10 L", description: "Träfasadfärg utomhus, vattenburen grund-<br> och täckfärg BW", imgPro: bygg4, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/utomhusfarg/fasadfarg/no-tinova-vx-2-in-1-bw-10-l" },
          { id: 461, price:'241:-', discount:'-18%', title: "GRUNDOLJA TINOVA WOOD BASE <br>1L OILBPR NORDSJÖ UTOMHUS", description: "Tinova Wood Base Oil BPR", imgPro: bygg5, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/oljor-traskydd/traskydd/grundolja-tinova-wood-base-1l-oilbpr-nordsjo-utomhus-900235060" },
          { id: 462, price:'571:-', discount:'-18%', title: "GRUNDOLJA TINOVA WOOD BASE<br> 2,5 OILBPR NORDSJÖ UTOMHUS<br> 2,5L", description: "Tinova Wood Base Oil BPR", imgPro: bygg6, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/oljor-traskydd/traskydd/grundolja-tinova-wood-base-2-5-oilbpr-nordsjo-utomhus-2-5l-900235061" },
        ],
        image: skanskabyggvarorLogga,
      },
      {
        name: "Verktygsproffsen",
        row: [
          { id: 463, price:'224:-', discount:'-28%', title: "TRÄSKYDD SIOO:X ALTAN<br> PREMIUM", description: "Premium Träskydd Altan i kombination med<br> Premium Ytskydd Altan", imgPro: bygg1, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/oljor-traskydd/traskydd/traskydd-sioo-x-altan-premium-333505742226" },
          { id: 464, price:'392:-', discount:'-21%', title: "FASADFÄRG ONE SUPER TECH", description: "One Super Tech från Nordsjö är en <br>självrengörande akrylattäckfärg avsedd för<br> målning på träfasader.", imgPro: bygg2, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/utomhusfarg/fasadfarg/fasadfarg-one-super-tech" },
          { id: 465, price:'135:-', discount:'-10%', title: "SKARVSLADD H05VV-F 3G1.5 VIT", description: "Skarvsladd (ej hängbar frp)", imgPro: bygg3, url: "https://www.beijerbygg.se/privat/sv/produkter/elinstallation/skarvsladd-kabelvindor/skarvsladd/skarvsladd-h05vv-f-3g1-5-vit" },
          { id: 466, price:'2 640:-', discount:'-12%', title: "NO TINOVA VX+ 2 IN 1 BW 10 L", description: "Träfasadfärg utomhus, vattenburen grund-<br> och täckfärg BW", imgPro: bygg4, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/utomhusfarg/fasadfarg/no-tinova-vx-2-in-1-bw-10-l" },
          { id: 467, price:'241:-', discount:'-18%', title: "GRUNDOLJA TINOVA WOOD BASE <br>1L OILBPR NORDSJÖ UTOMHUS", description: "Tinova Wood Base Oil BPR", imgPro: bygg5, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/oljor-traskydd/traskydd/grundolja-tinova-wood-base-1l-oilbpr-nordsjo-utomhus-900235060" },
          { id: 468, price:'571:-', discount:'-18%', title: "GRUNDOLJA TINOVA WOOD BASE<br> 2,5 OILBPR NORDSJÖ UTOMHUS<br> 2,5L", description: "Tinova Wood Base Oil BPR", imgPro: bygg6, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/oljor-traskydd/traskydd/grundolja-tinova-wood-base-2-5-oilbpr-nordsjo-utomhus-2-5l-900235061" },
        ],
        image: verktygsproffsenLogga,
      },
      {
      name: "Wexthuset",
      row: [
        { id: 433, price:'224:-', discount:'-28%', title: "TRÄSKYDD SIOO:X ALTAN<br> PREMIUM", description: "Premium Träskydd Altan i kombination med<br> Premium Ytskydd Altan", imgPro: bygg1, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/oljor-traskydd/traskydd/traskydd-sioo-x-altan-premium-333505742226" },
        { id: 434, price:'392:-', discount:'-21%', title: "FASADFÄRG ONE SUPER TECH", description: "One Super Tech från Nordsjö är en <br>självrengörande akrylattäckfärg avsedd för<br> målning på träfasader.", imgPro: bygg2, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/utomhusfarg/fasadfarg/fasadfarg-one-super-tech" },
        { id: 435, price:'135:-', discount:'-10%', title: "SKARVSLADD H05VV-F 3G1.5 VIT", description: "Skarvsladd (ej hängbar frp)", imgPro: bygg3, url: "https://www.beijerbygg.se/privat/sv/produkter/elinstallation/skarvsladd-kabelvindor/skarvsladd/skarvsladd-h05vv-f-3g1-5-vit" },
        { id: 436, price:'2 640:-', discount:'-12%', title: "NO TINOVA VX+ 2 IN 1 BW 10 L", description: "Träfasadfärg utomhus, vattenburen grund-<br> och täckfärg BW", imgPro: bygg4, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/utomhusfarg/fasadfarg/no-tinova-vx-2-in-1-bw-10-l" },
        { id: 437, price:'241:-', discount:'-18%', title: "GRUNDOLJA TINOVA WOOD BASE <br>1L OILBPR NORDSJÖ UTOMHUS", description: "Tinova Wood Base Oil BPR", imgPro: bygg5, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/oljor-traskydd/traskydd/grundolja-tinova-wood-base-1l-oilbpr-nordsjo-utomhus-900235060" },
        { id: 438, price:'571:-', discount:'-18%', title: "GRUNDOLJA TINOVA WOOD BASE<br> 2,5 OILBPR NORDSJÖ UTOMHUS<br> 2,5L", description: "Tinova Wood Base Oil BPR", imgPro: bygg6, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/oljor-traskydd/traskydd/grundolja-tinova-wood-base-2-5-oilbpr-nordsjo-utomhus-2-5l-900235061" },
      ],
      image: wexthusetLogga,
    },


];

function shuffle(array) {
  return array.sort(() => Math.random() - 0.5);
}

function Bygg() {
  const [shuffledRowsWithImages, setShuffledRowsWithImages] = useState([]);
  const { searchText } = useContext(SearchContext);
  useEffect(() => {
    setShuffledRowsWithImages(shuffle([...storeRows]));
  }, []);

  const filteredRows = shuffledRowsWithImages.filter((item) =>
    item?.name?.toLowerCase().includes(searchText.toLowerCase())
  );
  return (
    <>

<Helmet>
  <title>SuperDeals.nu - Fantastiska deals på bygg- och trädgårdsprodukter</title>
  <meta
    name="description"
    content="Upptäck fantastiska erbjudanden på bygg- och trädgårdsprodukter på SuperDeals.nu. Hitta de bästa rabatterna på verktyg, trädgårdsmöbler, växter och mer."
  />
  <meta
    name="keywords"
    content="bygg, trädgård, byggprodukter, trädgårdsprodukter, deals på byggprodukter, superdeals bygg, erbjudanden på verktyg, billiga trädgårdsmöbler, rabatter på växter, verktyg, trädgårdsmöbler, växter, utemöbler, byggmaterial, byggverktyg, trädgårdsredskap, trädgårdsdekorationer, bygg och trädgård rea, bygg och trädgård rabatt, SuperDeals.nu, gör-det-själv, trädgårdsskötsel, hus och trädgård, utomhusprojekt"
  />
  <link rel="canonical" href="https://www.superdeals.nu/bygg&tradgard" />
  {/* <meta property="og:title" content="SuperDeals.nu - Fantastiska deals på bygg- och trädgårdsprodukter" />
  <meta property="og:description" content="Upptäck fantastiska erbjudanden på bygg- och trädgårdsprodukter på SuperDeals.nu. Hitta de bästa rabatterna på verktyg, trädgårdsmöbler, växter och mer." />
  <meta property="og:url" content="https://www.superdeals.nu/bygg&tradgard" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://www.superdeals.nu/images/og-image.jpg" />
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="SuperDeals.nu - Fantastiska deals på bygg- och trädgårdsprodukter" />
  <meta name="twitter:description" content="Upptäck fantastiska erbjudanden på bygg- och trädgårdsprodukter på SuperDeals.nu. Hitta de bästa rabatterna på verktyg, trädgårdsmöbler, växter och mer." />
  <meta name="twitter:image" content="https://www.superdeals.nu/images/twitter-image.jpg" /> */}
</Helmet>

      {/* <Ad type="large" /> */}
      {filteredRows.map((item, index) => (
        <Box key={index} my={2}>
          <img src={item.image} alt="" style={{display: 'block', marginLeft: 'auto', marginRight:'auto'}}/>
          <OfferCards stores={item.row} name={item} storeId={`store${index + 1}`} />
          {/* {index !== filteredRows.length - 1 && <Ad type="small" />} */}
        </Box>
      ))}
    </>
  );
}

export default Bygg;