import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import AllOffers from "./components/AllOffers";
import Electronics from "./category/Electronics";
import Beauty from "./category/Beauty";
import Clothes from "./category/Clothes";
import AboutUs from "./pages/AboutUs";
import MissionValues from "./pages/Mission&Values";
import ContactUs from "./pages/ContactUs";
import Support from "./pages/Support";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import HomePage from "./components/Home/Homepage";
import { SearchProvider } from "../src/components/SearchContext"; // Importera SearchProvider
import Accessoarer from "./category/Accessoarer";
import FAQ from "./pages/FAQ";
import CookiesPolicy from "./pages/Cookies";
import Bygg from "./category/Bygg";
import Barn from "./category/Barn";
import Fordon from "./category/Fordon&Tillbehor";
import AquaLimone from "./pages/AquaLimone";



function App() {

function Top() {

  const {pathname} = useLocation();

  useEffect(() => {
window.scrollTo({top: 0, behavior: 'smooth'})
  },[pathname])

}


  return (
    <SearchProvider>
    <Router>
      <Navbar />
      <Top/>
      <Box
        sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <Box component="main" sx={{ flex: "1 0 auto", pt: 8 }}>
          <Container sx={{ mt: 8, maxWidth: "100%" }}>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/se-mer/:storeId" element={<AllOffers />} />
              <Route path="/om-oss" element={<AboutUs />} />
              <Route path="/uppdrag-varderingar" element={<MissionValues />} />
              <Route path="/kontakt" element={<ContactUs />} />
              <Route path="/support" element={<Support />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/cookies" element={<CookiesPolicy />} />
              <Route path="/integritets-policy" element={<PrivacyPolicy />} />
              <Route path="/anvandarvillkor" element={<TermsOfService />} />
              <Route path="/elektronik" element={<Electronics />} />
              <Route path="/skonhet&halsa" element={<Beauty />} />
              <Route path="/klader" element={<Clothes />} />
              <Route path="/accessoarer" element={<Accessoarer/>}/>
              <Route path="/bygg&tradgard" element={<Bygg/>}/>
              <Route path="/barn&familj" element={<Barn/>}/>
              <Route path="/fordon&tillbehor" element={<Fordon/>}/>
              <Route path="/aqualimone" element={<AquaLimone/>}/>
            </Routes>
          </Container>
        </Box>
        <Footer sx={{ flexShrink: 0 }} />
      </Box>
    </Router>
    </SearchProvider>
  );
}

export default App;
