import hallbergguldLogga from "../Img/hallbergguldAd.png";
import urochpennLogga from "../Img/urochpennAd.png";
import klockiaLogga from "../Img/klockiaAd.png";
import guldfyndLogga from "../Img/guldfyndAD.png";
import alberktsguldLogga from "../Img/albrektsguldAd.png";

import React, { useState, useEffect, useContext } from "react";
import { Box } from "@mui/material";
import OfferCards from "../components/OfferCards";
import { SearchContext } from "../components/SearchContext";
import { Helmet } from "react-helmet-async";

const storeRows = [
  {
    name: "Hallbergguld",
    row: [
      {
        id: 1,
        price: "995 kr",
        discount: "-18%",
        title: "Armring i äkta silver",
        imgPro: 'https://media.viskan.com/v2/hallbergsprod/large/20007320_front.jpg',
        url: "https://www.hallbergsguld.se/article/armring_i_akta_silver_20007320?ref=superdeals.nu",
      },
      {
        id: 2,
        price: "35 995 kr",
        discount: "-10%",
        title: "Diamantring Las Vegas 1,03 ct",
        imgPro: 'https://media.viskan.com/v2/hallbergsprod/large/20026693_front.jpg',
        url: "https://www.hallbergsguld.se/article/diamantring_las_vegas_103_ct_20026693?ref=superdeals.nu",
      },
      {
        id: 3,
        price: "395 kr",
        discount: "-15%",
        title: "RÖrhängen i äkta silver",
        imgPro: 'https://media.viskan.com/v2/hallbergsprod/large/20142563_front.jpg',
        url: "https://www.hallbergsguld.se/article/orhangen_i_akta_silver_20142563?ref=superdeals.nu",
      },
      {
        id: 4,
        price: "4 495 kr",
        discount: "-10%",
        title: "Örhängen i 18K guld",
        imgPro: 'https://media.viskan.com/v2/hallbergsprod/normal/20135059_front.jpg',
        url: "https://www.hallbergsguld.se/article/orhangen_i_18k_guld_20135059?ref=superdeals.nu",
      },
      {
        id: 5,
        price: "475 kr",
        discount: "-8%",
        title: "Halsband i äkta silver",
        imgPro:'https://media.viskan.com/v2/hallbergsprod/normal/20146632_front.jpg',
        url: "https://www.hallbergsguld.se/article/halsband_i_akta_silver_20146632?ref=superdeals.nu",
      },
      {
        id: 6,
        price: "26 495 kr",
        discount: "-12%",
        title: "Diamantring Sorrento 0,50 ct",
        imgPro: 'https://media.viskan.com/v2/hallbergsprod/normal/20143269_front.jpg',
        url: "https://www.hallbergsguld.se/article/diamantring_sorrento_050_ct?ref=superdeals.nu",
      },
      {
        id: 7,
        price: "10 495 kr",
        discount: "-12%",
        title: "Diamanthalsband i 18K guld",
        imgPro: 'https://media.viskan.com/v2/hallbergsprod/large/20136947_front.jpg',
        url: "https://www.hallbergsguld.se/article/diamanthalsband_i_18k_guld_20136947?ref=superdeals.nu",
      },
      {
        id: 8,
        price: "42 995 kr",
        discount: "-12%",
        title: "Diamantring Rome 0,75 ct",
        imgPro: 'https://media.viskan.com/v2/hallbergsprod/normal/42287_front.jpg',
        url: "https://www.hallbergsguld.se/article/diamantring_rome_075_ct?ref=superdeals.nu",
      },
    ],
    image: hallbergguldLogga,
  },
  {
    name: "Albrektsguld",
    row: [
      {
        id: 9,
        price: "169 999 kr",
        discount: "-18%",
        title: "Diamantring i 18K guld",
        imgPro: 'https://media.viskan.com/v2/albrektsprod/large/20026946_front.jpg',
        url: "https://www.albrektsguld.se/article/diamantring_i_18k_guld_20026946?ref=superdeals.nu",
      },
      {
        id: 10,
        price: "19 999 kr",
        discount: "-10%",
        title: "Diamantörhängen i 18K guld",
        imgPro: 'https://media.viskan.com/v2/albrektsprod/large/20132034_front.jpg',
        url: "https://www.albrektsguld.se/article/diamantorhangen_i_18k_guld_20132034?ref=superdeals.nu",
      },
      {
        id: 11,
        price: "17 299 kr",
        discount: "-15%",
        title: "Halsband i 18K guld 45cm",
        imgPro: 'https://media.viskan.com/v2/albrektsprod/normal/20003181_front.jpg',
        url: "https://www.albrektsguld.se/article/halsband_i_18k_guld_45cm_20003181?ref=superdeals.nu",
      },
      {
        id: 12,
        price: "18 999 kr",
        discount: "-10%",
        title: "Diamantring i 18K guld",
        imgPro: 'https://media.viskan.com/v2/albrektsprod/normal/20028476_front.jpg',
        url: "https://www.albrektsguld.se/article/diamantring_i_18k_guld_20028476?ref=superdeals.nu",
      },
      {
        id: 13,
        price: "3 799 kr",
        discount: "-8%",
        title: "Armband i 18K guld 18,5cm",
        imgPro: 'https://media.viskan.com/v2/albrektsprod/normal/20071918_front.jpg',
        url: "https://www.albrektsguld.se/article/armband_i_18k_guld_185cm_20071918?ref=superdeals.nu",
      },
      {
        id: 14,
        price: "1 799 kr",
        discount: "-12%",
        title: "Örhängen i 18K guld",
        imgPro: 'https://media.viskan.com/v2/albrektsprod/normal/20005278_front.jpg',
        url: "https://www.albrektsguld.se/article/orhangen_i_18k_guld_20005278?ref=superdeals.nu",
      },
      {
        id: 15,
        price: "449 kr",
        discount: "-8%",
        title: "Ring i äkta silver",
        imgPro: 'https://media.viskan.com/v2/albrektsprod/large/20030739_front.jpg',
        url: "https://www.albrektsguld.se/article/ring_i_akta_silver_20030739?ref=superdeals.nu",
      },
      {
        id: 16,
        price: "249 kr",
        discount: "-12%",
        title: "Örhängen i äkta silver",
        imgPro: 'https://media.viskan.com/v2/albrektsprod/normal/20114700_front.jpg',
        url: "https://www.albrektsguld.se/article/orhangen_i_akta_silver_20114700?ref=superdeals.nu",
      },
    ],
    image: alberktsguldLogga,
  },
  // {
  //   name: "Klockia",
  //   row: [
  //     {
  //       id: 7,
  //       price: "16 999:-",
  //       discount: "-18%",
  //       title: "Ring med labbodlade diamanter",
  //       description:
  //         "Ring i återvunnet 18K gult guld med en oval<br> IGI-certifierad labbodlad diamant 0.50 ct.",
  //       imgPro: guld1,
  //       url: "https://www.albrektsguld.se/article/ring_med_labbodlade_diamanter_20152016",
  //     },
  //     {
  //       id: 8,
  //       price: "349:-",
  //       discount: "-10%",
  //       title: "Klocka med dekorativ länk",
  //       description:
  //         "Guldfärgad klocka med dekorativ länk<br> med vita kristaller.",
  //       imgPro: guld2,
  //       url: "https://www.albrektsguld.se/article/klocka_med_dekorativ_lank",
  //     },
  //     {
  //       id: 9,
  //       price: "7 999:-",
  //       discount: "-15%",
  //       title: "Ring med labbodlade diamanter",
  //       description:
  //         "Ring i återvunnet 18K vitt guld med 18<br>  labbodlade diamanter totalt 0.14 ct TWVS.",
  //       imgPro: guld3,
  //       url: "https://www.albrektsguld.se/article/ring_med_labbodlade_diamanter_20151891",
  //     },
  //     {
  //       id: 10,
  //       price: "199:-",
  //       discount: "-10%",
  //       title: "Halsband i stål",
  //       description: "Halsband i guldpläterat stål i längden<br> 45 + 8cm.",
  //       imgPro: guld4,
  //       url: "https://www.albrektsguld.se/article/halsband_i_stal_20152503",
  //     },
  //     {
  //       id: 11,
  //       price: "10 999:-",
  //       discount: "-8%",
  //       title: "Ring i 18K guld",
  //       description: "Klackring i 18K guld",
  //       imgPro: guld5,
  //       url: "https://www.albrektsguld.se/article/ring_i_18k_guld_20117510",
  //     },
  //     {
  //       id: 12,
  //       price: "449:-",
  //       discount: "-12%",
  //       title: "Herrklocka",
  //       description: "Klocka i svartfärgad metall",
  //       imgPro: guld6,
  //       url: "https://www.albrektsguld.se/article/herrklocka_20023416",
  //     },
  //   ],
  //   image: klockiaLogga,
  // },
  {
    name: "Guldfynd",
    row: [
      {
        id: 17,
        price: "8 998 kr",
        discount: "-18%",
        title: "Diamantring i 18K guld",
        imgPro: 'https://media.viskan.com/v2/idunaprod/normal/20147222_front.jpg',
        url: "https://www.guldfynd.se/article/diamantring_i_18k_guld_20147222?ref=superdeals.nu",
      },
      {
        id: 18,
        price: "7 498 kr",
        discount: "-10%",
        title: "Diamantörhängen i 18K guld",
        imgPro: 'https://media.viskan.com/v2/idunaprod/normal/20147227_front.jpg',
        url: "https://www.guldfynd.se/article/diamantorhangen_i_18k_guld_20147227?ref=superdeals.nu",
      },
      {
        id: 19,
        price: "4 998 kr",
        discount: "-15%",
        title: "Hängsmycke i 18K guld",
        imgPro: 'https://media.viskan.com/v2/idunaprod/normal/20142879_front.jpg',
        url: "https://www.guldfynd.se/article/hangsmycke_i_18k_guld_20142879?ref=superdeals.nu",
      },
      {
        id: 20,
        price: "11 498 kr",
        discount: "-10%",
        title: "Halsband i 18K guld 45cm",
        imgPro: 'https://media.viskan.com/v2/idunaprod/normal/20141989_front.jpg',
        url: "https://www.guldfynd.se/article/halsband_i_18k_guld_45cm_20141989?ref=superdeals.nu",
      },
      {
        id: 21,
        price: "7 398 kr",
        discount: "-8%",
        title: "Armband i 18K guld, 19cm",
        imgPro: 'https://media.viskan.com/v2/idunaprod/normal/20141978_front.jpg',
        url: "https://www.guldfynd.se/article/armband_i_18k_guld_19cm_20141978?ref=superdeals.nu",
      },
      {
        id: 22,
        price: "2 998 kr",
        discount: "-12%",
        title: "Ring i 18K guld",
        imgPro: 'https://media.viskan.com/v2/idunaprod/large/20141420_front.jpg',
        url: "https://www.guldfynd.se/article/ring_i_18k_guld_20141420?ref=superdeals.nu",
      },
      {
        id: 23,
        price: "3 798 kr",
        discount: "-8%",
        title: "Örhängen i 18K guld",
        imgPro: 'https://media.viskan.com/v2/idunaprod/normal/20135745_front.jpg',
        url: "https://www.guldfynd.se/article/orhangen_i_18k_guld_20135745?ref=superdeals.nu",
      },
      {
        id: 24,
        price: "11 898 kr",
        discount: "-12%",
        title: "Pärlhalsband i 18K guld 50 cm",
        imgPro: 'https://media.viskan.com/v2/idunaprod/normal/20114901_front.jpg',
        url: "https://www.guldfynd.se/article/parlhalsband_i_18k_guld_50_cm_20114901?ref=superdeals.nu",
      },
    ],
    image: guldfyndLogga,
  },
  // {
  //   name: "Urochpenn",
  //   row: [
  //     {
  //       id: 7,
  //       price: "16 999:-",
  //       discount: "-18%",
  //       title: "Ring med labbodlade diamanter",
  //       description:
  //         "Ring i återvunnet 18K gult guld med en oval<br> IGI-certifierad labbodlad diamant 0.50 ct.",
  //       imgPro: guld1,
  //       url: "https://www.albrektsguld.se/article/ring_med_labbodlade_diamanter_20152016",
  //     },
  //     {
  //       id: 8,
  //       price: "349:-",
  //       discount: "-10%",
  //       title: "Klocka med dekorativ länk",
  //       description:
  //         "Guldfärgad klocka med dekorativ länk<br> med vita kristaller.",
  //       imgPro: guld2,
  //       url: "https://www.albrektsguld.se/article/klocka_med_dekorativ_lank",
  //     },
  //     {
  //       id: 9,
  //       price: "7 999:-",
  //       discount: "-15%",
  //       title: "Ring med labbodlade diamanter",
  //       description:
  //         "Ring i återvunnet 18K vitt guld med 18<br>  labbodlade diamanter totalt 0.14 ct TWVS.",
  //       imgPro: guld3,
  //       url: "https://www.albrektsguld.se/article/ring_med_labbodlade_diamanter_20151891",
  //     },
  //     {
  //       id: 10,
  //       price: "199:-",
  //       discount: "-10%",
  //       title: "Halsband i stål",
  //       description: "Halsband i guldpläterat stål i längden<br> 45 + 8cm.",
  //       imgPro: guld4,
  //       url: "https://www.albrektsguld.se/article/halsband_i_stal_20152503",
  //     },
  //     {
  //       id: 11,
  //       price: "10 999:-",
  //       discount: "-8%",
  //       title: "Ring i 18K guld",
  //       description: "Klackring i 18K guld",
  //       imgPro: guld5,
  //       url: "https://www.albrektsguld.se/article/ring_i_18k_guld_20117510",
  //     },
  //     {
  //       id: 12,
  //       price: "449:-",
  //       discount: "-12%",
  //       title: "Herrklocka",
  //       description: "Klocka i svartfärgad metall",
  //       imgPro: guld6,
  //       url: "https://www.albrektsguld.se/article/herrklocka_20023416",
  //     },
  //   ],
  //   image: urochpennLogga,
  // },
];

function shuffle(array) {
  return array.sort(() => Math.random() - 0.5);
}

function Accessoarer() {
  const [shuffledRowsWithImages, setShuffledRowsWithImages] = useState([]);
  const { searchText } = useContext(SearchContext);
  useEffect(() => {
    setShuffledRowsWithImages(shuffle([...storeRows]));
  }, []);

  const filteredRows = shuffledRowsWithImages.filter((item) =>
    item?.name?.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <>

<Helmet>
  <title>SuperDeals.nu - Grymma deals på accessoarer</title>
  <meta
    name="description"
    content="Hitta de bästa erbjudandena på accessoarer på SuperDeals.nu. Upptäck fantastiska rabatter på smycken, väskor, solglasögon och mer."
  />
  <meta
    name="keywords"
    content="accessoarer, deals på accessoarer, superdeals accessoarer, erbjudanden på smycken, billiga väskor, rabatter på solglasögon, smycken, väskor, solglasögon, bälten, halsdukar, mössor, handskar, accessoarer rea, accessoarer rabatt, modetillbehör, märkesaccessoarer, trendiga accessoarer, herraccessoarer, damaccessoarer, modeaccessoarer"
  />
  <link rel="canonical" href="https://www.superdeals.nu/accessoarer" />
</Helmet>

      {/* <Ad type="large" /> */}
      {filteredRows?.map((item, index) => (
        <Box key={index} my={2}>
          <img
            src={item.image}
            alt=""
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
          <OfferCards
            stores={item.row}
            name={item}
            storeId={`store${index + 1}`}
          />
          {/* {index !== filteredRows.length - 1 && <Ad type="small" />} */}
        </Box>
      ))}
    </>
  );
}

export default Accessoarer;
