
import React, { useState, useEffect, useContext } from "react";
import { Box, CardMedia } from "@mui/material";
import OfferCards from "../components/OfferCards";
// import Ad from "../components/Ad";
import { SearchContext } from "../components/SearchContext";

import autoexpertenLogga from "../Img/autoexpertenAd.png";
import bythjulLogga from "../Img/bythjulAd.png";
import dackonlineLogga from "../Img/dackonlineAd.png";
import dackskiftarnaLogga from "../Img/dackskiftarnaAd.png";
import ewheelLogga from "../Img/ewheelsAd.png";
import evifyLogga from "../Img/evifyAd.png";
import evobikeLogga from "../Img/evobikeAd.png";
import mekonomenLogga from "../Img/mekonomenAd.png";
import riddermarkbilLogga from "../Img/riddermarkbilAd.png";
import rullLogga from "../Img/rullAd.png";
import skruvatLogga from "../Img/skruvatAd.png";
import gummihusetLogga from "../Img/gummihusetAd.png";
import tirendoLogga from "../Img/tirendoAd.png"; 


import däck1 from "../Img/däck1.webp"
import däck2 from "../Img/däck2.webp"
import däck3 from "../Img/däck3.jpg"
import däck4 from "../Img/däck4.webp"
import däck5 from "../Img/däck5.webp"
import däck6 from "../Img/däck6.webp"
import tv from "../Img/hhh.avif"
import { Helmet } from "react-helmet-async";




const storeRows = [

      {
    name: "Autoexperten",
    row: [
      { id: 355, price:'610:-', discount:'-20%', title: "DYNAMO STREET-H MH01", description: "Sommardäck", imgPro: däck1, url: "https://www.dackskiftarna.se/dack/sommardack/dynamo-street-h-mh01-6922250412690-2021" },
      { id: 356, price:'525:-', discount:'-10%', title: "NOKIAN HAKKA GREEN 2", description: "Sommardäck", imgPro: däck2, url: "https://www.dackskiftarna.se/dack/sommardack/nokian-hakka-green-2-6419440173573-2018" },
      { id: 357, price:'870:-', discount:'-15%', title: "APOLLO APTERRA H/T 2 XL", description: "Sommardäck", imgPro: däck3, url: "https://www.dackskiftarna.se/dack/apollo-apterra-h-t-2-xl-8904156099531-2017" },
      { id: 358, price:'366:-', discount:'-20%', title: "BRIDGESTONE TURANZA ER300", description: "Sommardäck", imgPro: däck4, url: "https://www.dackskiftarna.se/dack/sommardack/bridgestone-turanza-er300-3286340777513-2017" },
      { id: 359, price:'475:-', discount:'-50%', title: "KUMHO ECOWING ES31", description: "Sommardäck", imgPro: däck5, url: "https://www.dackskiftarna.se/dack/sommardack/kumho-ecowing-es31-8808956238193-2021" },
      { id: 360, price:'2 333:-', discount:'-20%', title: "BRIDGESTONE POTENZA SPORT XL", description: "Sommardäck", imgPro: däck6, url: "https://www.dackskiftarna.se/dack/sommardack/bridgestone-potenza-sport-xl-3286342155814-2021" },
    ],
    image: autoexpertenLogga,
  },

    {
    name: "Mekonomen",
    row: [
      { id: 397, price:'610:-', discount:'-20%', title: "DYNAMO STREET-H MH01", description: "Sommardäck", imgPro: däck1, url: "https://www.dackskiftarna.se/dack/sommardack/dynamo-street-h-mh01-6922250412690-2021" },
      { id: 398, price:'525:-', discount:'-10%', title: "NOKIAN HAKKA GREEN 2", description: "Sommardäck", imgPro: däck2, url: "https://www.dackskiftarna.se/dack/sommardack/nokian-hakka-green-2-6419440173573-2018" },
      { id: 399, price:'870:-', discount:'-15%', title: "APOLLO APTERRA H/T 2 XL", description: "Sommardäck", imgPro: däck3, url: "https://www.dackskiftarna.se/dack/apollo-apterra-h-t-2-xl-8904156099531-2017" },
      { id: 400, price:'366:-', discount:'-20%', title: "BRIDGESTONE TURANZA ER300", description: "Sommardäck", imgPro: däck4, url: "https://www.dackskiftarna.se/dack/sommardack/bridgestone-turanza-er300-3286340777513-2017" },
      { id: 401, price:'475:-', discount:'-50%', title: "KUMHO ECOWING ES31", description: "Sommardäck", imgPro: däck5, url: "https://www.dackskiftarna.se/dack/sommardack/kumho-ecowing-es31-8808956238193-2021" },
      { id: 402, price:'2 333:-', discount:'-20%', title: "BRIDGESTONE POTENZA SPORT XL", description: "Sommardäck", imgPro: däck6, url: "https://www.dackskiftarna.se/dack/sommardack/bridgestone-potenza-sport-xl-3286342155814-2021" },
    ],
    image: mekonomenLogga,
  },

    {
    name: "Riddermarkbil",
    row: [
      { id: 403, price:'610:-', discount:'-20%', title: "DYNAMO STREET-H MH01", description: "Sommardäck", imgPro: däck1, url: "https://www.dackskiftarna.se/dack/sommardack/dynamo-street-h-mh01-6922250412690-2021" },
      { id: 404, price:'525:-', discount:'-10%', title: "NOKIAN HAKKA GREEN 2", description: "Sommardäck", imgPro: däck2, url: "https://www.dackskiftarna.se/dack/sommardack/nokian-hakka-green-2-6419440173573-2018" },
      { id: 405, price:'870:-', discount:'-15%', title: "APOLLO APTERRA H/T 2 XL", description: "Sommardäck", imgPro: däck3, url: "https://www.dackskiftarna.se/dack/apollo-apterra-h-t-2-xl-8904156099531-2017" },
      { id: 406, price:'366:-', discount:'-20%', title: "BRIDGESTONE TURANZA ER300", description: "Sommardäck", imgPro: däck4, url: "https://www.dackskiftarna.se/dack/sommardack/bridgestone-turanza-er300-3286340777513-2017" },
      { id: 407, price:'475:-', discount:'-50%', title: "KUMHO ECOWING ES31", description: "Sommardäck", imgPro: däck5, url: "https://www.dackskiftarna.se/dack/sommardack/kumho-ecowing-es31-8808956238193-2021" },
      { id: 408, price:'2 333:-', discount:'-20%', title: "BRIDGESTONE POTENZA SPORT XL", description: "Sommardäck", imgPro: däck6, url: "https://www.dackskiftarna.se/dack/sommardack/bridgestone-potenza-sport-xl-3286342155814-2021" },
    ],
    image: riddermarkbilLogga,
  }, // Koden fungerar hit

    {
    name: "Rull",
    row: [
      { id: 409, price:'', discount:'', title: "Offer 4", description: "Description of Offer 4", imgPro: tv, url: "" },
      { id: 410, price:'', discount:'', title: "Offer 5", description: "Description of Offer 5", imgPro: tv, url: "" },
      { id: 411, price:'', discount:'', title: "Offer 6", description: "Description of Offer 6", imgPro: tv, url: "" },
      { id: 412, price:'', discount:'', title: "Offer 7", description: "Description of Offer 7", imgPro: tv, url: "" },
      { id: 413, price:'', discount:'', title: "Offer 8", description: "Description of Offer 8", imgPro: tv, url: "" },
      { id: 414, price:'', discount:'', title: "Offer 9", description: "Description of Offer 9", imgPro: tv, url: "" },
    ],
    image: rullLogga,
  },

    {
    name: "Evobike",
    row: [
      { id: 391, price:'', discount:'', title: "Offer 4", description: "Description of Offer 4", imgPro: tv, url: "" },
      { id: 392, price:'', discount:'', title: "Offer 5", description: "Description of Offer 5", imgPro: tv, url: "" },
      { id: 393, price:'', discount:'', title: "Offer 6", description: "Description of Offer 6", imgPro: tv, url: "" },
      { id: 394, price:'', discount:'', title: "Offer 7", description: "Description of Offer 7", imgPro: tv, url: "" },
      { id: 395, price:'', discount:'', title: "Offer 8", description: "Description of Offer 8", imgPro: tv, url: "" },
      { id: 396, price:'', discount:'', title: "Offer 9", description: "Description of Offer 9", imgPro: tv, url: "" },
    ],
    image: evobikeLogga,
  }, 

    {
    name: "Skruvat",
    row: [
      { id: 415, price:'610:-', discount:'-20%', title: "DYNAMO STREET-H MH01", description: "Sommardäck", imgPro: däck1, url: "https://www.dackskiftarna.se/dack/sommardack/dynamo-street-h-mh01-6922250412690-2021" },
      { id: 416, price:'525:-', discount:'-10%', title: "NOKIAN HAKKA GREEN 2", description: "Sommardäck", imgPro: däck2, url: "https://www.dackskiftarna.se/dack/sommardack/nokian-hakka-green-2-6419440173573-2018" },
      { id: 417, price:'870:-', discount:'-15%', title: "APOLLO APTERRA H/T 2 XL", description: "Sommardäck", imgPro: däck3, url: "https://www.dackskiftarna.se/dack/apollo-apterra-h-t-2-xl-8904156099531-2017" },
      { id: 418, price:'366:-', discount:'-20%', title: "BRIDGESTONE TURANZA ER300", description: "Sommardäck", imgPro: däck4, url: "https://www.dackskiftarna.se/dack/sommardack/bridgestone-turanza-er300-3286340777513-2017" },
      { id: 419, price:'475:-', discount:'-50%', title: "KUMHO ECOWING ES31", description: "Sommardäck", imgPro: däck5, url: "https://www.dackskiftarna.se/dack/sommardack/kumho-ecowing-es31-8808956238193-2021" },
      { id: 420, price:'2 333:-', discount:'-20%', title: "BRIDGESTONE POTENZA SPORT XL", description: "Sommardäck", imgPro: däck6, url: "https://www.dackskiftarna.se/dack/sommardack/bridgestone-potenza-sport-xl-3286342155814-2021" },
    ],
    image: skruvatLogga,
  },

    {
    name: "Gummihuset",
    row: [
      { id: 421, price:'610:-', discount:'-20%', title: "DYNAMO STREET-H MH01", description: "Sommardäck", imgPro: däck1, url: "https://www.dackskiftarna.se/dack/sommardack/dynamo-street-h-mh01-6922250412690-2021" },
      { id: 422, price:'525:-', discount:'-10%', title: "NOKIAN HAKKA GREEN 2", description: "Sommardäck", imgPro: däck2, url: "https://www.dackskiftarna.se/dack/sommardack/nokian-hakka-green-2-6419440173573-2018" },
      { id: 423, price:'870:-', discount:'-15%', title: "APOLLO APTERRA H/T 2 XL", description: "Sommardäck", imgPro: däck3, url: "https://www.dackskiftarna.se/dack/apollo-apterra-h-t-2-xl-8904156099531-2017" },
      { id: 424, price:'366:-', discount:'-20%', title: "BRIDGESTONE TURANZA ER300", description: "Sommardäck", imgPro: däck4, url: "https://www.dackskiftarna.se/dack/sommardack/bridgestone-turanza-er300-3286340777513-2017" },
      { id: 425, price:'475:-', discount:'-50%', title: "KUMHO ECOWING ES31", description: "Sommardäck", imgPro: däck5, url: "https://www.dackskiftarna.se/dack/sommardack/kumho-ecowing-es31-8808956238193-2021" },
      { id: 426, price:'2 333:-', discount:'-20%', title: "BRIDGESTONE POTENZA SPORT XL", description: "Sommardäck", imgPro: däck6, url: "https://www.dackskiftarna.se/dack/sommardack/bridgestone-potenza-sport-xl-3286342155814-2021" },
    ],
    image: gummihusetLogga,
  },

    {
    name: "Tirendo",
    row: [
      { id: 427, price:'610:-', discount:'-20%', title: "DYNAMO STREET-H MH01", description: "Sommardäck", imgPro: däck1, url: "https://www.dackskiftarna.se/dack/sommardack/dynamo-street-h-mh01-6922250412690-2021" },
      { id: 428, price:'525:-', discount:'-10%', title: "NOKIAN HAKKA GREEN 2", description: "Sommardäck", imgPro: däck2, url: "https://www.dackskiftarna.se/dack/sommardack/nokian-hakka-green-2-6419440173573-2018" },
      { id: 429, price:'870:-', discount:'-15%', title: "APOLLO APTERRA H/T 2 XL", description: "Sommardäck", imgPro: däck3, url: "https://www.dackskiftarna.se/dack/apollo-apterra-h-t-2-xl-8904156099531-2017" },
      { id: 430, price:'366:-', discount:'-20%', title: "BRIDGESTONE TURANZA ER300", description: "Sommardäck", imgPro: däck4, url: "https://www.dackskiftarna.se/dack/sommardack/bridgestone-turanza-er300-3286340777513-2017" },
      { id: 431, price:'475:-', discount:'-50%', title: "KUMHO ECOWING ES31", description: "Sommardäck", imgPro: däck5, url: "https://www.dackskiftarna.se/dack/sommardack/kumho-ecowing-es31-8808956238193-2021" },
      { id: 432, price:'2 333:-', discount:'-20%', title: "BRIDGESTONE POTENZA SPORT XL", description: "Sommardäck", imgPro: däck6, url: "https://www.dackskiftarna.se/dack/sommardack/bridgestone-potenza-sport-xl-3286342155814-2021" },
    ],
    image: tirendoLogga,
  },

    {
    name: "Evify",
    row: [
      { id: 385, price:'', discount:'', title: "Offer 4", description: "Description of Offer 4", imgPro: tv, url: "" },
      { id: 386, price:'', discount:'', title: "Offer 5", description: "Description of Offer 5", imgPro: tv, url: "" },
      { id: 387, price:'', discount:'', title: "Offer 6", description: "Description of Offer 6", imgPro: tv, url: "" },
      { id: 388, price:'', discount:'', title: "Offer 7", description: "Description of Offer 7", imgPro: tv, url: "" },
      { id: 389, price:'', discount:'', title: "Offer 8", description: "Description of Offer 8", imgPro: tv, url: "" },
      { id: 390, price:'', discount:'', title: "Offer 9", description: "Description of Offer 9", imgPro: tv, url: "" },
    ],
    image: evifyLogga,
  },

    {
    name: "Ewheel",
    row: [
      { id: 379, price:'610:-', discount:'-20%', title: "DYNAMO STREET-H MH01", description: "Sommardäck", imgPro: däck1, url: "https://www.dackskiftarna.se/dack/sommardack/dynamo-street-h-mh01-6922250412690-2021" },
      { id: 380, price:'525:-', discount:'-10%', title: "NOKIAN HAKKA GREEN 2", description: "Sommardäck", imgPro: däck2, url: "https://www.dackskiftarna.se/dack/sommardack/nokian-hakka-green-2-6419440173573-2018" },
      { id: 381, price:'870:-', discount:'-15%', title: "APOLLO APTERRA H/T 2 XL", description: "Sommardäck", imgPro: däck3, url: "https://www.dackskiftarna.se/dack/apollo-apterra-h-t-2-xl-8904156099531-2017" },
      { id: 382, price:'366:-', discount:'-20%', title: "BRIDGESTONE TURANZA ER300", description: "Sommardäck", imgPro: däck4, url: "https://www.dackskiftarna.se/dack/sommardack/bridgestone-turanza-er300-3286340777513-2017" },
      { id: 383, price:'475:-', discount:'-50%', title: "KUMHO ECOWING ES31", description: "Sommardäck", imgPro: däck5, url: "https://www.dackskiftarna.se/dack/sommardack/kumho-ecowing-es31-8808956238193-2021" },
      { id: 384, price:'2 333:-', discount:'-20%', title: "BRIDGESTONE POTENZA SPORT XL", description: "Sommardäck", imgPro: däck6, url: "https://www.dackskiftarna.se/dack/sommardack/bridgestone-potenza-sport-xl-3286342155814-2021" },
    ],
    image: ewheelLogga,
  },

    {
    name: "Bythjul",
    row: [
      { id: 361, price:'610:-', discount:'-20%', title: "DYNAMO STREET-H MH01", description: "Sommardäck", imgPro: däck1, url: "https://www.dackskiftarna.se/dack/sommardack/dynamo-street-h-mh01-6922250412690-2021" },
      { id: 362, price:'525:-', discount:'-10%', title: "NOKIAN HAKKA GREEN 2", description: "Sommardäck", imgPro: däck2, url: "https://www.dackskiftarna.se/dack/sommardack/nokian-hakka-green-2-6419440173573-2018" },
      { id: 363, price:'870:-', discount:'-15%', title: "APOLLO APTERRA H/T 2 XL", description: "Sommardäck", imgPro: däck3, url: "https://www.dackskiftarna.se/dack/apollo-apterra-h-t-2-xl-8904156099531-2017" },
      { id: 364, price:'366:-', discount:'-20%', title: "BRIDGESTONE TURANZA ER300", description: "Sommardäck", imgPro: däck4, url: "https://www.dackskiftarna.se/dack/sommardack/bridgestone-turanza-er300-3286340777513-2017" },
      { id: 365, price:'475:-', discount:'-50%', title: "KUMHO ECOWING ES31", description: "Sommardäck", imgPro: däck5, url: "https://www.dackskiftarna.se/dack/sommardack/kumho-ecowing-es31-8808956238193-2021" },
      { id: 366, price:'2 333:-', discount:'-20%', title: "BRIDGESTONE POTENZA SPORT XL", description: "Sommardäck", imgPro: däck6, url: "https://www.dackskiftarna.se/dack/sommardack/bridgestone-potenza-sport-xl-3286342155814-2021" },
    ],
    image: bythjulLogga,
  }, // Koden fungerar felfritt hit med

    {
    name: "Dackonline",
    row: [
      { id: 367, price:'610:-', discount:'-20%', title: "DYNAMO STREET-H MH01", description: "Sommardäck", imgPro: däck1, url: "https://www.dackskiftarna.se/dack/sommardack/dynamo-street-h-mh01-6922250412690-2021" },
      { id: 368, price:'525:-', discount:'-10%', title: "NOKIAN HAKKA GREEN 2", description: "Sommardäck", imgPro: däck2, url: "https://www.dackskiftarna.se/dack/sommardack/nokian-hakka-green-2-6419440173573-2018" },
      { id: 369, price:'870:-', discount:'-15%', title: "APOLLO APTERRA H/T 2 XL", description: "Sommardäck", imgPro: däck3, url: "https://www.dackskiftarna.se/dack/apollo-apterra-h-t-2-xl-8904156099531-2017" },
      { id: 370, price:'366:-', discount:'-20%', title: "BRIDGESTONE TURANZA ER300", description: "Sommardäck", imgPro: däck4, url: "https://www.dackskiftarna.se/dack/sommardack/bridgestone-turanza-er300-3286340777513-2017" },
      { id: 371, price:'475:-', discount:'-50%', title: "KUMHO ECOWING ES31", description: "Sommardäck", imgPro: däck5, url: "https://www.dackskiftarna.se/dack/sommardack/kumho-ecowing-es31-8808956238193-2021" },
      { id: 372, price:'2 333:-', discount:'-20%', title: "BRIDGESTONE POTENZA SPORT XL", description: "Sommardäck", imgPro: däck6, url: "https://www.dackskiftarna.se/dack/sommardack/bridgestone-potenza-sport-xl-3286342155814-2021" },
    ],
    image: dackonlineLogga,
  },

  {
    name: "Däckskiftarna",
    row: [
      { id: 373, price:'610:-', discount:'-20%', title: "DYNAMO STREET-H MH01", description: "Sommardäck", imgPro: däck1, url: "https://www.dackskiftarna.se/dack/sommardack/dynamo-street-h-mh01-6922250412690-2021" },
      { id: 374, price:'525:-', discount:'-10%', title: "NOKIAN HAKKA GREEN 2", description: "Sommardäck", imgPro: däck2, url: "https://www.dackskiftarna.se/dack/sommardack/nokian-hakka-green-2-6419440173573-2018" },
      { id: 375, price:'870:-', discount:'-15%', title: "APOLLO APTERRA H/T 2 XL", description: "Sommardäck", imgPro: däck3, url: "https://www.dackskiftarna.se/dack/apollo-apterra-h-t-2-xl-8904156099531-2017" },
      { id: 376, price:'366:-', discount:'-20%', title: "BRIDGESTONE TURANZA ER300", description: "Sommardäck", imgPro: däck4, url: "https://www.dackskiftarna.se/dack/sommardack/bridgestone-turanza-er300-3286340777513-2017" },
      { id: 377, price:'475:-', discount:'-50%', title: "KUMHO ECOWING ES31", description: "Sommardäck", imgPro: däck5, url: "https://www.dackskiftarna.se/dack/sommardack/kumho-ecowing-es31-8808956238193-2021" },
      { id: 378, price:'2 333:-', discount:'-20%', title: "BRIDGESTONE POTENZA SPORT XL", description: "Sommardäck", imgPro: däck6, url: "https://www.dackskiftarna.se/dack/sommardack/bridgestone-potenza-sport-xl-3286342155814-2021" },
    ],
    image: dackskiftarnaLogga,
  },






];

function shuffle(array) {
  return array.sort(() => Math.random() - 0.5);
}

function Fordon() {
  const [shuffledRowsWithImages, setShuffledRowsWithImages] = useState([]);
  const { searchText } = useContext(SearchContext);
  useEffect(() => {
    setShuffledRowsWithImages(shuffle([...storeRows]));
  }, []);

  const filteredRows = shuffledRowsWithImages.filter((item) =>
    item?.name?.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <>

<Helmet>
  <title>SuperDeals.nu - Fantastiska deals på fordon och tillbehör</title>
  <meta
    name="description"
    content="Upptäck fantastiska erbjudanden på fordon och tillbehör på SuperDeals.nu. Hitta de bästa rabatterna på bildelar, motorcyklar, biltillbehör och mer."
  />
  <meta
    name="keywords"
    content="fordon, tillbehör, deals på fordon, superdeals fordon, erbjudanden på bildelar, billiga motorcyklar, rabatter på biltillbehör, bildelar, motorcyklar, biltillbehör, däck, bilbatterier, motorolja, fordonsreparation, fordonstillbehör, bilar, motorcykelutrustning, fordon rea, fordon rabatt, SuperDeals.nu, bilmekaniker, fordonsentusiaster, transportmedel"
  />
  <link rel="canonical" href="https://www.superdeals.nu/fordon&tillbehor" />
  {/* <meta property="og:title" content="SuperDeals.nu - Fantastiska deals på fordon och tillbehör" />
  <meta property="og:description" content="Upptäck fantastiska erbjudanden på fordon och tillbehör på SuperDeals.nu. Hitta de bästa rabatterna på bildelar, motorcyklar, biltillbehör och mer." />
  <meta property="og:url" content="https://www.superdeals.nu/fordon&tillbehor" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://www.superdeals.nu/images/og-image.jpg" />
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="SuperDeals.nu - Fantastiska deals på fordon och tillbehör" />
  <meta name="twitter:description" content="Upptäck fantastiska erbjudanden på fordon och tillbehör på SuperDeals.nu. Hitta de bästa rabatterna på bildelar, motorcyklar, biltillbehör och mer." />
  <meta name="twitter:image" content="https://www.superdeals.nu/images/twitter-image.jpg" /> */}
</Helmet>





      {/* <Ad type="large" /> */}
      {filteredRows?.map((item, index) => (
        <Box key={index} my={2}>
          <img src={item.image} alt="" style={{display: 'block', marginLeft: 'auto', marginRight:'auto'}}/>
          <OfferCards stores={item.row} name={item} storeId={`store${index + 1}`} />
          {/* {index !== filteredRows.length - 1 && <Ad type="small" />} */}
        </Box>
      ))}
    </>
  );
}

export default Fordon;
