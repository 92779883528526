import React from "react";
import { Container, Box, Typography, Grid } from "@mui/material";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <Box sx={{ backgroundColor: "primary.main", color: "white", py: 3, mt: 5 }}>
      <Container maxWidth="md" sx={{ textAlign: "center" }}>
        <Box sx={{ maxWidth: "md", margin: "0 auto" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" gutterBottom>
                Om Oss
              </Typography>
              <Typography variant="body2">
                <Link
                  to="/om-oss"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  Vår Story
                </Link>
              </Typography>
              <Typography variant="body2">
                <Link
                  to="/uppdrag-varderingar"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  Uppdrag och Värderingar
                </Link>
              </Typography>
              <Typography variant="body2">
                <Link
                  to="/aqualimone"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  Aqua Limone
                </Link>
              </Typography>

            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" gutterBottom>
                Kontakt
              </Typography>
              <Typography variant="body2">
                <Link
                  to="/kontakt"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  Kontakt
                </Link>
              </Typography>
              <Typography variant="body2">
                <Link
                  to="/faq"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  FAQ
                </Link>
              </Typography>
              <Typography variant="body2">
                <Link
                  to="/support"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  Support
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" gutterBottom>
                Villkor
              </Typography>
              <Typography variant="body2">
                <Link
                  to="/integritets-policy"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  Integritetspolicy
                </Link>
              </Typography>
              <Typography variant="body2">
                <Link
                  to="/Cookies"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  CookiesPolicy
                </Link>
              </Typography>
              <Typography variant="body2">
                <Link
                  to="/anvandarvillkor"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  Användarvillkor
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;
