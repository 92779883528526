import inetLogga from "../../Img/inetLogga.png";
import powerLogga from "../../Img/poweLogga.png";
import netonnetLogga from "../../Img/NetonnetLogga.png";
import webhallenLogga from "../../Img/webhallenAd.png";
import elonLogga from "../../Img/elonAd.png";
import ginaLogga from "../../Img/ginaAd.png";
import computersalgLogga from "../../Img/computersalgAd.png";
import lykoLogga from "../../Img/lykoAd.png";
import alberktsguldLogga from "../../Img/albrektsguldAd.png";
import lindexLogga from "../../Img/lindexAd.png";
import weekdayLooga from "../../Img/weekdayAd.png";
import bjornborgLogga from "../../Img/bjornborgAD.png";
import guldfyndLogga from "../../Img/guldfyndAD.png";
import bubbleroomLogga from "../../Img/bubbleroomAd.png";
import mqLogga from "../../Img/mqAd.png";
import nkLogga from "../../Img/nkAd.png";
import jollyroomLogga from "../../Img/jollyroomAd.png";
import babyvLogga from "../../Img/babyvAd.png";
import babylandLogga from "../../Img/babylandAd.png";
import albertLogga from "../../Img/albertAd.png";
import cerveraLogga from "../../Img/cerveraAd.png";
import hemtexLogga from "../../Img/hemtexAd.png";
import kitchentimeLogga from "../../Img/kitchentimeAd.png";
import vidaxlLogga from "../../Img/vidaxlAd.png";
import lampgallerianLogga from "../../Img/lampgallerianAd.png";
import buttericksLogga from "../../Img/buttericksAd.png";
import flyingtigerLogga from "../../Img/flyingtigerAd.png";
import proshopLogga from "../../Img/proshopAd.png";
import arkenzooLogga from "../../Img/arkenzooAd.png";
import vetzooLogga from "../../Img/vetzooAd.png";
import panduroLogga from "../../Img/panduroAd.png";
import coolstuffLogga from "../../Img/coolstuffAd.png";
import apoteketLogga from "../../Img/apoteketAd.png";
import bangerheadLogga from "../../Img/bangerheadAd.png";
import cocopandaLogga from "../../Img/cocopandaAd.png";
import beautycosLogga from "../../Img/beautycosAd.png";
import bodystoreLogga from "../../Img/bodystoreAd.png";
import comforthscandinaviaLogga from "../../Img/comforthscandinaviaAd.png";
import dozapoteketLogga from "../../Img/dozapoteketAd.png";
import elevenLogga from "../../Img/elevenAd.png";
import glowidLogga from "../../Img/glowidAd.png";
import greatlifeLogga from "../../Img/greatlifeAd.png";
import gymgrossistenLogga from "../../Img/gymgrossistenAd.png";
import hudoteketLogga from "../../Img/hudoteketAd.png";
import kicksLogga from "../../Img/kicksAd.png";
import lhcosmeticsLogga from "../../Img/lhcosmeticsAd.png";
import lifeLogga from "../../Img/lifeAd.png";
import maxulinLogga from "../../Img/maxulinAd.png";
import medsLogga from "../../Img/medsAd.png";
import nordicfeel from "../../Img/nordicfeelAd.png";
import osloskin from "../../Img/osloskinAd.png";
import rapunzelofswedenLogga from "../../Img/rapunzelofswedenAd.png";
import skincityLogga from "../../Img/skincityAd.png";
import staybeautifulLogga from "../../Img/staybeautifulAd.png";
import synoptikLogga from "../../Img/synoptikAd.png";
import vitaeproLogga from "../../Img/vitaeproAd.png";
import vuxenLogga from "../../Img/vuxenAd.png";
import wellobeLogga from "../../Img/wellobeAd.png";
import xlashLogga from "../../Img/xlashAd.png";
import youLogga from "../../Img/youAd.png";
import autoexpertenLogga from "../../Img/autoexpertenAd.png";
import bythjulLogga from "../../Img/bythjulAd.png";
import dackonlineLogga from "../../Img/dackonlineAd.png";
import dackskiftarnaLogga from "../../Img/dackskiftarnaAd.png";
import ewheelLogga from "../../Img/ewheelsAd.png";
import evifyLogga from "../../Img/evifyAd.png";
import evobikeLogga from "../../Img/evobikeAd.png";
import mekonomenLogga from "../../Img/mekonomenAd.png";
import riddermarkbilLogga from "../../Img/riddermarkbilAd.png";
import rullLogga from "../../Img/rullAd.png";
import skruvatLogga from "../../Img/skruvatAd.png";
import gummihusetLogga from "../../Img/gummihusetAd.png";
import tirendoLogga from "../../Img/tirendoAd.png";
import beijerLogga from "../../Img/beijerAd.png";
import out1Logga from "../../Img/out1Ad.png";
import proffsmagasinetLogga from "../../Img/proffsmagasinetAd.png";
import servicefinderLogga from "../../Img/servicefinderAd.png";
import skanskabyggvarorLogga from "../../Img/skanskabyggvarorAd.png";
import verktygsproffsenLogga from "../../Img/verktygsproffsenAd.png";
import wexthusetLogga from "../../Img/wexthusetAd.png";
import dustinLogga from "../../Img/dustinAd.png";
import kjellLogga from "../../Img/kjellochcompanyAd.png";
import inkclubLogga from "../../Img/inkclubAd.png";
import hifiklubbenLogga from "../../Img/hifiklubbenAd.png";
import hemsolLogga from "../../Img/hemsolAd.png";
import lyrecoLogga from "../../Img/hemsolAd.png";
import nordicinkLogga from "../../Img/nordicinkAd.png";
import simplyLogga from "../../Img/simplyAd.png";
import klockiaLogga from "../../Img/klockiaAd.png";
import snsLogga from "../../Img/snsAd.png";
import urochpennLogga from "../../Img/urochpennAd.png";
import arketLogga from "../../Img/arketAd.png";
import hallbergguldLogga from "../../Img/hallbergguldAd.png";
import diturLogga from "../../Img/diturAd.png";
import timarcoLogga from "../../Img/timarcoAd.png";
import elgigantenlogo from "../../Img/elgigantenlogo.png"

import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Grid,
  CircularProgress,
  Box,
  CardContent,
  CardMedia,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import OfferCards from "../../components/OfferCards";
// import Ad from "../../components/Ad";
import komplettLogga from "../../Img/komplettAd.png";
import sharkLogga from "../../Img/sharkAd.png";
import { SearchContext } from "../SearchContext"; // Importera SearchContext
import { Helmet } from "react-helmet-async";
import Card from "@mui/material/Card";
import axios from 'axios';


import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css"; // Importera Swiper CSS
import "swiper/css/navigation"; // Importera specifika Swiper CSS
import "swiper/css/pagination"; // Importera specifika Swiper CSS
import { Autoplay,} from "swiper"; 
import { TbWorldWww } from "react-icons/tb";

const storeRows = [
  {
    imgUrl: "https://www.komplett.se?ref=superdeals.nu",
    name: "Komplett",
    row: [
      {
        id: 1,
        price: "1 949 kr",
        discount: "-19%",
        title: "ASUS 27 gamingskärm TUF VG27AQ3A",
        imgPro: "https://www.komplett.se/img/p/1200/1303926.jpg",
        url: "https://www.komplett.se/product/1300143/datorutrustning/lagring/haarddisk/ssd-m2/kingston-kc3000-2tb?ref=superdeals.nu",
      },
      {
        id: 2,
        price: "12 999 kr",
        discount: "-15%",
        title: "Komplett-PC Advanced Gaming a126 RGB",
        imgPro: "https://www.komplett.se/img/p/1015/1249493.jpg",
        url: "https://www.komplett.se/product/1249493/gaming/gamingdator/gamingdator-stationar/komplett-pc-advanced-gaming-a126-rgb?ref=superdeals.nu",
      },
      {
        id: 3,
        price: "6 990 kr",
        discount: "-21%",
        title: "Samsung 55 Q64D QLED 4K TV TQ55Q64D",
        imgPro: "https://www.komplett.se/img/p/1200/1305527.jpg",
        url: "https://www.komplett.se/product/1305527/tv-ljud-bild/tv/samsung-55-q64d-qled-4k-tv-tq55q64d?ref=superdeals.nu",
      },
      {
        id: 28,
        price: "4 490 kr",
        discount: "-30%",
        title: "ASUS 34 curved gamingskärm TUF VG34VQL3A",
        imgPro: "https://www.komplett.se/img/p/1200/1303929.jpg",
        url: "https://www.komplett.se/product/1303929/gaming/spelutrustning/gamingskarmar/asus-34-curved-gamingskarm-tuf-vg34vql3a?_gl=1*6vsyo0*_up*MQ..&gclid=CjwKCAjw65-zBhBkEiwAjrqRMEQdzushaR0e4leTQ7_7zoEbuCZObNo49Sn63kyuFFzppcKuQMgeFBoCFN4QAvD_BwE&gclsrc=aw.ds?ref=superdeals.nu",
      },
      {
        id: 29,
        price: "849 kr",
        discount: "-25%",
        title: "Svive Oberon brown switch<br> gamingtangentbord (svart)",
        imgPro: 'https://www.komplett.se/img/p/1200/1205958.jpg',
        url: "https://www.komplett.se/product/1205958/gaming/spelutrustning/gamingtangentbord/svive-oberon-brown-switch-gamingtangentbord-svart?_gl=1*72lb4*_up*MQ..&gclid=CjwKCAjw65-zBhBkEiwAjrqRMEQdzushaR0e4leTQ7_7zoEbuCZObNo49Sn63kyuFFzppcKuQMgeFBoCFN4QAvD_BwE&gclsrc=aw.dss?ref=superdeals.nu",
      },
      {
        id: 30,
        price: "4 290 kr",
        discount: "-29%",
        title: "Arozzi Vernazza Supersoft<br> Gamingstol (ljusgrå)",
        imgPro: 'https://www.komplett.se/img/p/1200/1221824.jpg',
        url: "https://www.komplett.se/product/1221824/gaming/spelutrustning/gamingstolar/arozzi-vernazza-supersoft-gamingstol-ljusgraa?_gl=1*1miv42c*_up*MQ..&gclid=CjwKCAjw65-zBhBkEiwAjrqRMEQdzushaR0e4leTQ7_7zoEbuCZObNo49Sn63kyuFFzppcKuQMgeFBoCFN4QAvD_BwE&gclsrc=aw.ds?ref=superdeals.nu",
      },
      {
        id: 1,
        price: "1 949 kr",
        discount: "-19%",
        title: "Kingston KC3000 2TB",
        imgPro: "	https://www.komplett.se/img/p/1080/1300143.jpg",
        url: "https://www.komplett.se/product/1300143/datorutrustning/lagring/haarddisk/ssd-m2/kingston-kc3000-2tb?ref=superdeals.nu",
      },
      {
        id: 1,
        price: "1 949 kr",
        discount: "-19%",
        title: "Kingston KC3000 2TB",
        imgPro: "	https://www.komplett.se/img/p/1080/1300143.jpg",
        url: "https://www.komplett.se/product/1300143/datorutrustning/lagring/haarddisk/ssd-m2/kingston-kc3000-2tb?ref=superdeals.nu",
      },
    ],
    image: komplettLogga,
  },
  {
    imgUrl: "https://www.netonnet.se/?ref=superdeals.nu",
    name: "Netonnet",
    row: [
      {
        id: 1,
        price: "13 490 kr",
        discount: "-19%",
        title: "MacBook Air. Superladdad med M2.",
        imgPro: "https://www.netonnet.se/GetFile/ProductImagePrimary/dator-surfplatta/laptop/laptop-12-14-tum/apple-macbook-air-13-space-greym2-8c-cpu-10c-gpu16gb-ram256gb-ssdswedish-keyboard30w-power-adapter(1034162)_664715_1_Normal_Large.webp",
        url: "https://www.netonnet.se/art/dator-surfplatta/laptop/laptop-12-14-tum/apple-macbook-air-13-space-greym2-8c-cpu-10c-gpu16gb-ram256gb-ssdswedish-keyboard30w-power-adapter/1034162.8906/?ref=superdeals.nu",
      },
      {
        id: 2,
        price: "11 990 kr",
        discount: "-15%",
        title: "144 Hz 15,6 gaminglaptop med Ryzen 7 & RTX 4060",
        imgPro: "https://www.netonnet.se/GetFile/ProductImagePrimary/gaming/gamingdator/barbar-gamingdator/lenovo-loq-15(1034466)_667376_15_Normal_Large.webp",
        url: "https://www.netonnet.se/art/gaming/gamingdator/barbar-gamingdator/lenovo-loq-15/1034466.12916/?ref=superdeals.nu",
      },
      {
        id: 3,
        price: "8 989 kr",
        discount: "-21%",
        title: "iPhone 14. Full av fantastiska funktioner",
        imgPro: "https://www.netonnet.se/GetFile/ProductImagePrimary/mobil-smartwatch/mobiltelefoner/iphone/apple-iphone-14-128gb-midnight(1026724)_527786_1_Normal_Large.webp",
        url: "https://www.netonnet.se/art/mobil-smartwatch/mobiltelefoner/iphone/apple-iphone-14-128gb-midnight/1026724.9044/?ref=superdeals.nu",
      },
      {
        id: 28,
        price: "1 790 kr",
        discount: "-30%",
        title: "Brusreducerande hörlurar med ULT POWER SOUND",
        imgPro: "https://www.netonnet.se/GetFile/ProductImagePrimary/ljud/horlurar/over-ear/sony-whult900nh-ce7(1033834)_648820_29_Normal_Large.webp",
        url: "https://www.netonnet.se/art/ljud/horlurar/over-ear/sony-whult900nh-ce7/1033834.9293/?ref=superdeals.nu",
      },
      {
        id: 29,
        price: "2 789 kr",
        discount: "-25%",
        title: "True Wireless-hörlurar med enastående brusreducering",
        imgPro: 'https://www.netonnet.se/GetFile/ProductImagePrimary/ljud/horlurar/in-ear/sony-wf-1000xm5-black(1030200)_592295_2_Normal_Large.webp',
        url: "https://www.netonnet.se/art/ljud/horlurar/in-ear/sony-wf-1000xm5-black/1030200.9292/?ref=superdeals.nu",
      },
      {
        id: 30,
        price: "3 490 kr",
        discount: "-29%",
        title: "Mångsidig. Kapabel. Oemotståndlig.",
        imgPro: 'https://www.netonnet.se/GetFile/ProductImagePrimary/mobil-smartwatch/smartwatch/apple-watch/apple-watch-se-gps-40mm-starlight-aluminium-case-with-starlight-sport-band-sm(1031410)_603529_1_Normal_Large.webp',
        url: "https://www.netonnet.se/art/mobil-smartwatch/smartwatch/apple-watch/apple-watch-se-gps-40mm-starlight-aluminium-case-with-starlight-sport-band-sm/1031410.13980/?ref=superdeals.nu",
      },
      {
        id: 1,
        price: "4 790 kr",
        discount: "-19%",
        title: "Kraftfull köksmaskin med våg, timer & flera tillbehör",
        imgPro: "https://www.netonnet.se/GetFile/ProductImagePrimary/hem-fritid/koksmaskiner/koksassistent/bosch-mum5xc66(1029716)_582674_19_Normal_Large.webp",
        url: "https://www.netonnet.se/art/hem-fritid/koksmaskiner/koksassistent/bosch-mum5xc66/1029716.9411/?ref=superdeals.nu",
      },
      {
        id: 1,
        price: "3 990 kr",
        discount: "-19%",
        title: "Galaxy Watch6 Classic − Elegans möter smarta funktioner",
        imgPro: "https://www.netonnet.se/GetFile/ProductImagePrimary/mobil-smartwatch/smartwatch/samsung-smartwatch/samsung-galaxy-watch6-classic-47mm-bt-black(1030352)_592022_1_Normal_Large.webp",
        url: "https://www.netonnet.se/art/mobil-smartwatch/smartwatch/samsung-smartwatch/samsung-galaxy-watch6-classic-47mm-bt-black/1030352.15386/?ref=superdeals.nu",
      },
    ],
    image: netonnetLogga,
  },
  {
    imgUrl: "https://www.power.se?ref=superdeals.nu",
    name: "Power",
    row: [
      {
        id: 1,
        price: "6 999 kr",
        discount: "-19%",
        title: "Klipsch Cinema 700 soundbar",
        imgPro: "https://media.power-cdn.net/images/h-f99d29e0a07b8070dd48a6ddffaf0fb0/products/1176425/1176425_1_600x600_t_g.webp",
        url: "https://www.power.se/tv-och-ljud/soundbars/klipsch-cinema-700-soundbar/p-1176425/?ref=superdeals.nu",
      },
      {
        id: 2,
        price: "9 949 kr",
        discount: "-15%",
        title: "Samsung 65 4K QLED TV TQ65Q60DAUXXC",
        imgPro: "https://media.power-cdn.net/images/h-c6451aa9bb3294bc64a16af30bfc2931/products/3143009/3143009_8_600x600_t_g.webp",
        url: "https://www.power.se/tv-och-ljud/tv/samsung-65-4k-qled-tv-tq65q60dauxxc/p-3143009/?ref=superdeals.nu",
      },
      {
        id: 3,
        price: "4 499 kr",
        discount: "-21%",
        title: "ASUS Vivobook Go 14 E1404GA-NK215W 14 bärbar",
        imgPro: "https://media.power-cdn.net/images/h-06572f82c85ad2bb559e5415ac054b20/products/3006469/3006469_5_600x600_t_g.webp",
        url: "https://www.power.se/datorer-och-surfplattor/datorer/barbar-dator/asus-vivobook-go-14-e1404ga-nk215w-14-barbar-dator-svart/p-3006469/?ref=superdeals.nu",
      },
      {
        id: 28,
        price: "2 799 kr",
        discount: "-30%",
        title: "Lenovo IdeaPad 1 14IGL7 (4/128 GB) 14 bärbar dator, grå",
        imgPro: "https://media.power-cdn.net/images/h-01d55f599394920ae88aff28ad8ef92b/products/3168401/3168401_10_600x600_t_g.webp",
        url: "https://www.power.se/datorer-och-surfplattor/datorer/barbar-dator/lenovo-ideapad-1-14igl7-4128-gb-14-barbar-dator-graa/p-3168401/?ref=superdeals.nu",
      },
      {
        id: 29,
        price: "4 799 kr",
        discount: "-25%",
        title: "Electrolux 600 Series COB403X inbyggnadsugn",
        imgPro: 'https://media.power-cdn.net/images/h-2c94b19dcb91c1c186e08398faf7b415/products/2305658/2305658_10_600x600_t_g.webp',
        url: "https://www.power.se/vitvaror/matlagning/inbyggnadsugnar/electrolux-600-series-cob403x-inbyggnadsugn/p-2305658/?ref=superdeals.nu",
      },
      {
        id: 30,
        price: "5 999 kr",
        discount: "-29%",
        title: "Gorenje Essential Line NRKE62W kyl/frys",
        imgPro: 'https://media.power-cdn.net/images/h-89b2c766b678f9e378b5390f1daee73a/products/1069729/1069729_1_600x600_t_g.webp',
        url: "https://www.power.se/vitvaror/kyl-och-frys/kyl-frys-kombiskaap/gorenje-essential-line-nrke62w-kylfrys/p-1069729/?ref=superdeals.nu",
      },
      {
        id: 1,
        price: "7 490 kr",
        discount: "-19%",
        title: "Apple Watch Ultra GPS + Cellular, 49 mm",
        imgPro: "https://media.power-cdn.net/images/h-91c6866df13e977251cde488d17e775c/products/2305499/2305499_1_600x600_t_g.webp",
        url: "https://www.power.se/mobil-och-foto/smartwatch-och-wearables/smartwatch/apple-watch-ultra-gps-cellular-49-mm-titanboett-med-blaattgraatt-terrangloop-sm/p-2305499/?ref=superdeals.nu",
      },
      {
        id: 1,
        price: "13 789 kr",
        discount: "-19%",
        title: "Apple iPhone 15 Pro Max 256 GB, blått titan",
        imgPro: "https://media.power-cdn.net/images/h-16bd48dbb861ddd0c703521f45cd5160/products/2668281/2668281_10_600x600_t_g.webp",
        url: "https://www.power.se/mobil-och-foto/mobiltelefoner/apple-iphone-15-pro-max-256-gb-blaatt-titan/p-2668281/?ref=superdeals.nu",
      },
    ],
    image: powerLogga,
  },
  {
    imgUrl: "https://www.elgiganten.se/?ref=superdeals.nu",
    name: "Elgiganten",
    row: [
      {
        id: 1,
        price: "9 990 kr",
        discount: "-19%",
        title: "Bird V-Frame elcykel VA00066",
        imgPro: "https://www.elgiganten.se/image/dv_web_D1800010021398926/600387/bird-v-frame-elcykel-va00066--pdp_main-640.jpg",
        url: "https://www.elgiganten.se/product/sport-fritid/elfordon/elcykel/bird-v-frame-elcykel-va00066/600387?ref=superdeals.nu",
      },
      {
        id: 2,
        price: "8 990 kr",
        discount: "-15%",
        title: "Weber Spirit EPX 325s gasolgrill 46713584",
        imgPro: "https://www.elgiganten.se/image/dv_web_D1800010021824852/712660/weber-spirit-epx-325s-gasolgrill-46713584--pdp_main-640.jpg",
        url: "https://www.elgiganten.se/product/hem-hushall-tradgard/grill/gasolgrill/weber-spirit-epx-325s-gasolgrill-46713584/712660?ref=superdeals.nu",
      },
      {
        id: 3,
        price: "11 111 kr",
        discount: "-21%",
        title: "TCL 65 QLED810 4K QLED Smart TV (2024)",
        imgPro: "https://www.elgiganten.se/image/dv_web_D1800010021840152/769389/tcl-65-qled810-4k-qled-smart-tv-2024--pdp_main-640.jpg",
        url: "https://www.elgiganten.se/product/tv-ljud-smart-hem/tv-tillbehor/tv/tcl-65-qled810-4k-qled-smart-tv-2024/769389?ref=superdeals.nu",
      },
      {
        id: 28,
        price: "9 990 kr",
        discount: "-30%",
        title: "LG 65 QNED86 4K QNED Smart TV (2023)",
        imgPro: "https://www.elgiganten.se/image/dv_web_D1800010021789829/602314/lg-65-qned86-4k-qned-smart-tv-2023--pdp_main-640.jpg",
        url: "https://www.elgiganten.se/product/tv-ljud-smart-hem/tv-tillbehor/tv/lg-65-qned86-4k-qned-smart-tv-2023/602314?ref=superdeals.nu",
      },
      {
        id: 29,
        price: "2 990 kr",
        discount: "-25%",
        title: "Samsung Odyssey G55C LS27CG552EUXEN 27” välvd VA bildskärm för gaming",
        imgPro: 'https://www.elgiganten.se/image/dv_web_D1800010021817243/746288/samsung-odyssey-g55c-ls27cg552euxen-27-valvd-va-bildskarm-for-gaming--pdp_main-640.jpg',
        url: "https://www.elgiganten.se/product/datorer-kontor/skarmar-tillbehor/datorskarm/samsung-odyssey-g55c-ls27cg552euxen-27-valvd-va-bildskarm-for-gaming/746288?ref=superdeals.nu",
      },
      {
        id: 30,
        price: "9 990 kr",
        discount: "-29%",
        title: "Lenovo Yoga Slim 7 Ultra 5-125H/16/512 14 bärbar dator",
        imgPro: 'https://www.elgiganten.se/image/dv_web_D180001280718782/767139/lenovo-yoga-slim-7-ultra-5-125h16512-14-barbar-dator--pdp_main-640.jpg',
        url: "https://www.elgiganten.se/product/datorer-kontor/datorer/laptop/lenovo-yoga-slim-7-ultra-5-125h16512-14-barbar-dator/767139?ref=superdeals.nu",
      },
      {
        id: 1,
        price: "6 990 kr",
        discount: "-19%",
        title: "Samsung Galaxy S23 FE 5G smartphone 8/128GB (grafit)",
        imgPro: "https://www.elgiganten.se/image/dv_web_D1800010021749200/719933/samsung-galaxy-s23-fe-5g-smartphone-8128gb-grafit--pdp_main-640.jpg",
        url: "https://www.elgiganten.se/product/mobiler-tablets-smartklockor/mobiltelefon/samsung-galaxy-s23-fe-5g-smartphone-8128gb-grafit/719933?ref=superdeals.nu",
      },
      {
        id: 1,
        price: "8 988 kr",
        discount: "-19%",
        title: "iPhone 14 – 5G smartphone 128GB Midnight",
        imgPro: "https://www.elgiganten.se/image/dv_web_D1800010021129110/522692/iphone-14-5g-smartphone-128gb-midnight--pdp_main-640.jpg",
        url: "https://www.elgiganten.se/product/mobiler-tablets-smartklockor/mobiltelefon/iphone-14-5g-smartphone-128gb-midnight/522692?ref=superdeals.nu",
      },
    ],
    image: elgigantenlogo,
  },
  {
    imgUrl: "https://www.inet.se/?ref= super deals www.superdeals.nu",
    name: "Inet",
    row: [
      {
        id: 25,
        price: "1 949 kr",
        discount: "-19%",
        title: "Kingston KC3000 2TB",
        imgPro: "	https://www.komplett.se/img/p/1080/1300143.jpg",
        url: "https://www.komplett.se/product/1300143/datorutrustning/lagring/haarddisk/ssd-m2/kingston-kc3000-2tb?ref=superdeals.nu",
      },
      {
        id: 26,
        price: "12 999 kr",
        discount: "-15%",
        title: "Komplett-PC Advanced <br> Gaming a126 RGB",
        imgPro: "https://www.komplett.se/img/p/1015/1249493.jpg",
        url: "https://www.komplett.se/product/1249493/gaming/gamingdator/gamingdator-stationar/komplett-pc-advanced-gaming-a126-rgb",
      },
      {
        id: 27,
        price: "6 990 kr",
        discount: "-21%",
        title: "Samsung 55 Q64D QLED <br> 4K TV TQ55Q64D",
        imgPro: "https://www.komplett.se/img/p/1200/1305527.jpg",
        url: "https://www.komplett.se/product/1305527/tv-ljud-bild/tv/samsung-55-q64d-qled-4k-tv-tq55q64d",
      },
      {
        id: 28,
        price: "4 490 kr",
        discount: "-30%",
        title: "ASUS 34 curved gamingskärm <br>TUF VG34VQL3A",
        imgPro: "https://www.komplett.se/img/p/1200/1303929.jpg",
        url: "https://www.komplett.se/product/1303929/gaming/spelutrustning/gamingskarmar/asus-34-curved-gamingskarm-tuf-vg34vql3a?_gl=1*6vsyo0*_up*MQ..&gclid=CjwKCAjw65-zBhBkEiwAjrqRMEQdzushaR0e4leTQ7_7zoEbuCZObNo49Sn63kyuFFzppcKuQMgeFBoCFN4QAvD_BwE&gclsrc=aw.ds",
      },
      {
        id: 29,
        price: "849 kr",
        discount: "-25%",
        title: "Svive Oberon brown switch<br> gamingtangentbord (svart)",
        imgPro: 'https://www.komplett.se/img/p/1200/1205958.jpg',
        url: "https://www.komplett.se/product/1205958/gaming/spelutrustning/gamingtangentbord/svive-oberon-brown-switch-gamingtangentbord-svart?_gl=1*72lb4*_up*MQ..&gclid=CjwKCAjw65-zBhBkEiwAjrqRMEQdzushaR0e4leTQ7_7zoEbuCZObNo49Sn63kyuFFzppcKuQMgeFBoCFN4QAvD_BwE&gclsrc=aw.dss",
      },
      {
        id: 30,
        price: "4 290 kr",
        discount: "-29%",
        title: "Arozzi Vernazza Supersoft<br> Gamingstol (ljusgrå)",
        imgPro: 'https://www.komplett.se/img/p/1200/1221824.jpg',
        url: "https://www.komplett.se/product/1221824/gaming/spelutrustning/gamingstolar/arozzi-vernazza-supersoft-gamingstol-ljusgraa?_gl=1*1miv42c*_up*MQ..&gclid=CjwKCAjw65-zBhBkEiwAjrqRMEQdzushaR0e4leTQ7_7zoEbuCZObNo49Sn63kyuFFzppcKuQMgeFBoCFN4QAvD_BwE&gclsrc=aw.ds",
      },
    ],
    image: inetLogga,
  },
  {
    name: "Ginatricot",
    row: [
      {
        id: 7,
        price: "249.95 kr",
        discount: "-30%",
        title: "double slit denim skirt",
        imgPro: 'https://ginatricotpc.imgix.net/pim/product-images/221195621/22119562105.jpg?auto=format,compress',
        url: "https://www.ginatricot.com/se/klader/kjolar/jeanskjolar/denim-skirt-221195621",
      },
      {
        id: 8,
        price: "90 kr",
        discount: "-50%",
        title: "puff sleeve top",
        imgPro: 'https://ginatricotpc.imgix.net/pim/product-images/238509000/23850900005.jpg?auto=format,compress',
        url: "https://www.ginatricot.com/se/klader/blusarskjortor/blusar-med-puffarm/puff-sleeve-top-238509000",
      },
      {
        id: 9,
        price: "84 kr",
        discount: "-50%",
        title: "tie front top",
        imgPro: 'https://ginatricotpc.imgix.net/pim/product-images/238983185/23898318505.jpg?auto=format,compress',
        url: "https://www.ginatricot.com/se/klader/blusarskjortor/blusar-med-puffarm/tie-front-top-238983185",
      },
      {
        id: 10,
        price: "249.95 kr",
        discount: "-30%",
        title: "flouncy long denim skirt",
        imgPro:'https://ginatricotpc.imgix.net/pim/product-images/220235050/22023505005.jpg?auto=format,compress',
        url: "https://www.ginatricot.com/se/klader/kjolar/jeanskjolar/denim-skirt-220235050",
      },
      {
        id: 11,
        price: "89.95 kr",
        discount: "-30%",
        title: "clean hoops earrings",
        imgPro:'https://ginatricotpc.imgix.net/pim/product-images/217907110/21790711006.jpg?auto=format,compress',
        url: "https://www.ginatricot.com/se/accessoarer/smycken/orhangen/earrings-217907110",
      },
      {
        id: 12,
        price: "84 kr",
        discount: "-50%",
        title: "tie front top",
        imgPro: 'https://ginatricotpc.imgix.net/pim/product-images/238986890/23898689005.jpg?auto=format,compress',
        url: "https://www.ginatricot.com/se/klader/toppar/knyttoppar/tie-front-top-238986890",
      },
    ],
    image: ginaLogga,
  },
  {
    name: "Webhallen",
    row: [
      {
        id: 25,
        price: "2 269 kr",
        discount: "-19%",
        title: "Garmin Venu Sq 2 - Svart<br>Skiffergrå",
        imgPro:'//cdn.webhallen.com/images/product/351754?trim',
        url: "https://www.webhallen.com/se/product/364477-Garmin-Venu-Sq-2-SvartSkiffergra-Fyndvara-Klass-1",
      },
      {
        id: 26,
        price: "1 890 kr",
        discount: "-15%",
        title: "JBL Charge 5 - Svart",
        imgPro: '//cdn.webhallen.com/images/product/324997?trim',
        url: "https://www.webhallen.com/se/product/337809-JBL-Charge-5-Svart-Fyndvara-Klass-1",
      },
      {
        id: 27,
        price: "12 149 kr",
        discount: "-21%",
        title: "Apple iPhone 14 Pro 128GB - <br>Space Black",
        imgPro: '//cdn.webhallen.com/images/product/352536?trim',
        url: "https://www.webhallen.com/se/product/365271-Apple-iPhone-14-Pro-128GB-Space-Black-Fyndvara-Klass-3",
      },
      {
        id: 28,
        price: "14 240 kr",
        discount: "-30%",
        title: "ASUS FA507NV / 16 / IPS /<br> Ryzen 5 7535HS / ",
        imgPro: "//cdn.webhallen.com/images/product/356378?trim",
        url: "https://www.webhallen.com/se/product/361365-ASUS-FA507NV-16-IPS-Ryzen-5-7535HS-16GB-512GB-RTX-4060-Win-11-Fyndvara-Klass-1",
      },
      {
        id: 29,
        price: "21 840 kr",
        discount: "-25%",
        title: "Apple CTO Macbook Air 13 i7<br> 1.2GHz / Space Grey",
        imgPro: '//cdn.webhallen.com/images/product/318120?trim',
        url: "https://www.webhallen.com/se/product/372813-Apple-CTO-Macbook-Air-13-i7-1-2GHz-16GB-512GB-SSD-Space-Grey-Fyndvara-Klass-1",
      },
      {
        id: 30,
        price: "20 689 kr",
        discount: "-29%",
        title: "Sony 2020 75 KD75XH9096 - <br>LED / 4K UHD Smart TV",
        imgPro:'//cdn.webhallen.com/images/product/317113?trim',
        url: "https://www.webhallen.com/se/product/324370-Sony-2020-75-KD75XH9096-LED-4K-UHD-120Hz-HDR-Smart-TV-Fyndvara-Klass-1",
      },
      {
        id: 30,
        price: "20 689 kr",
        discount: "-29%",
        title: "Sony 2020 75 KD75XH9096 - <br>LED / 4K UHD Smart TV",
        imgPro:'//cdn.webhallen.com/images/product/317113?trim',
        url: "https://www.webhallen.com/se/product/324370-Sony-2020-75-KD75XH9096-LED-4K-UHD-120Hz-HDR-Smart-TV-Fyndvara-Klass-1",
      },
      {
        id: 30,
        price: "20 689 kr",
        discount: "-29%",
        title: "Sony 2020 75 KD75XH9096 - <br>LED / 4K UHD Smart TV",
        imgPro:'//cdn.webhallen.com/images/product/317113?trim',
        url: "https://www.webhallen.com/se/product/324370-Sony-2020-75-KD75XH9096-LED-4K-UHD-120Hz-HDR-Smart-TV-Fyndvara-Klass-1",
      },
    ],
    image: webhallenLogga,
  },
  {
    name: "Sharkgaming",
    row: [
      {
        id: 19,
        price: "8 199 kr",
        discount: "-12%",
        title: "Max Bite Predator Speldator",
         imgPro: "https://sharkgaming.se/media/catalog/product/m/a/maelstrom2.0air_1.png",
        url: "https://addrevenue.io/t?c=3456855&a=984583&m=SE&u=https%3A%2F%2Fsharkgaming.se%2Fmax-bite-predator-v2",
      
      },
      {
        id: 20,
        price: "10 899 kr",
        discount: "-7%",
        title: "Max Bite Brutality Speldator",
        imgPro: "https://sharkgaming.se/media/catalog/product/m/a/maxbiteamdasus-min.png",
        url: "https://addrevenue.io/t?c=3456855&a=984583&m=SE&u=https%3A%2F%2Fsharkgaming.se%2Fmax-bite-brutality",
      },
      {
        id: 21,
        price: "12 999 kr",
        discount: "-10%",
        title: "Max Bite Ultimator Speldator",
        imgPro: "https://sharkgaming.se/media/catalog/product/m/a/maxbiteintelrtx.png",
        url: "https://addrevenue.io/t?c=3456855&a=984583&m=SE&u=https%3A%2F%2Fsharkgaming.se%2Fmax-bite-ultimator",
      },
      {
        id: 22,
        price: "7 699 kr",
        discount: "-15%",
        title: "Max Bite Extreme Speldator",
        imgPro: "https://sharkgaming.se/media/catalog/product/m/a/maxbitearcticasus1650_1.png",
        url: "https://addrevenue.io/t?c=3456855&a=984583&m=SE&u=https%3A%2F%2Fsharkgaming.se%2Fmax-bite-extreme-v2",
      },
      {
        id: 23,
        price: "12 299 kr",
        discount: "-12%",
        title: "Shark Bloodlust Speldator",
        imgPro: "https://sharkgaming.se/media/catalog/product/t/s/tsunami_pro_1_5.png",
        url: "https://addrevenue.io/t?c=3456855&a=984583&m=SE&u=https%3A%2F%2Fsharkgaming.se%2Fshark-bloodlust",
      },
      {
        id: 24,
        price: " 18 499 kr",
        discount: "-20%",
        title: "White Shark Massacre Speldator",
        imgPro: "https://sharkgaming.se/media/catalog/product/3/0/3000dcorsair.png",
        url: "https://addrevenue.io/t?c=3456855&a=984583&m=SE&u=https%3A%2F%2Fsharkgaming.se%2Fmighty-deep-blue-gaming-pc",
      },
    ],
    image: sharkLogga,
  },
  {
    name: "Elon",
    row: [
      {
        id: 25,
        price: "5 990 kr",
        discount: "-19%",
        title: "Bosch WAJ280A2SN",
        imgPro: 'https://www.elon.se/media/catalog/product/cache/691c7eca49da472be3ce24c0b9b28e0b/1/1/118537_1_85cd8bd0083b0ee98ff9482c1f25be84.jpeg',
        url: "https://www.elon.se/kampanjer/sommar-rea/bosch-waj280a2sn",
      },
      {
        id: 26,
        price: "9 490 kr",
        discount: "-15%",
        title: "Samsung Galaxy S24 128GB<br> Onyx Black",
        imgPro: 'https://www.elon.se/media/catalog/product/cache/691c7eca49da472be3ce24c0b9b28e0b/1/2/123944_1_73f1c7da2b32d10aa1d883793882ab80.jpeg',
        url: "https://www.elon.se/kampanjer/sommar-rea/samsung-galaxy-s24-128gb-onyx-black-123944",
      },
      {
        id: 27,
        price: "1 090 kr",
        discount: "-21%",
        title: "RUSSELL HOBBS SatiFry <br>26510-56",
        imgPro: 'https://www.elon.se/media/catalog/product/cache/691c7eca49da472be3ce24c0b9b28e0b/1/2/124259_1_79572dfd1743a3acdc25e55ec9f07abc.jpeg',
        url: "https://www.elon.se/kampanjer/sommar-rea/russell-hobbs-26510-56",
      },
      {
        id: 28,
        price: "7 490 kr",
        discount: "-30%",
        title: "Samsung VS20C9547TB/WA",
        imgPro: 'https://www.elon.se/media/catalog/product/cache/691c7eca49da472be3ce24c0b9b28e0b/1/2/123740_1_d8cda8296d0f51f7ecbdb1e1c4c794e8.jpeg',
        url: "https://www.elon.se/kampanjer/sommar-rea/samsung-vs20c9547tb-wa",
      },
      {
        id: 29,
        price: "7 990 kr",
        discount: "-25%",
        title: "Grundig GWPE89E6168WW",
        imgPro: 'https://www.elon.se/media/catalog/product/cache/691c7eca49da472be3ce24c0b9b28e0b/1/1/116594_1_64305ad6c4ac67fa81fa330714642fbf.jpeg',
        url: "https://www.elon.se/kampanjer/sommar-rea/grundig-gwpe89e6168ww",
      },
      {
        id: 30,
        price: "10 990 kr",
        discount: "-29%",
        title: "Electrolux EUT5NF26X-V",
        imgPro: 'https://www.elon.se/media/catalog/product/cache/691c7eca49da472be3ce24c0b9b28e0b/1/1/117051_1_1e53a1a605b691c397da76bbb0246f5e.jpeg',
        url: "https://www.elon.se/kampanjer/sommar-rea/electrolux-eut5nf26x-v",
      },
    ],
    image: elonLogga,
  },
  {
    name: "Computersalg",
    row: [
      {
        id: 19,
        price: "3.031 kr",
        discount: "-8%",
        title: "LG UltraGear 27GP850P-B -<br> Gaming Series - LED-skärm",
        imgPro: 'https://csdam.net/digitalcontent/0/5016/50168170.jpg',
        url: "https://www.computersalg.se/i/9740043/lg-ultragear-27gp850p-b-gaming-series-led-sk%c3%a4rm-spel-27-2560-x-1440-qhd-165-hz-nano-ips-400-cd-m-1000-1-displayhdr-400-1-ms",
      },
      {
        id: 20,
        price: " 1.119 kr",
        discount: "-12%",
        title: "Logitech® | MX Keys S",
        imgPro: 'https://csdam.net/digitalcontent/0/5163/51635489.jpg',
        url: "https://www.computersalg.se/i/10308608/logitech-mx-keys-s-tastatur-bagbelyst-bluetooth-2-4-ghz-nordisk-layout-grafit",
      },
      {
        id: 21,
        price: "1.132 kr",
        discount: "-10%",
        title: "Logitech MX Master 3S ",
        imgPro: 'https://csdam.net/digitalcontent/0/3512/35128435.jpg',
        url: "https://www.computersalg.se/i/8337611/logitech-mx-master-3s-mus-laser-7-knappar-tr%c3%a5dl%c3%b6s-bluetooth-2-4-ghz-logitech-bolt-mottagare-grafit",
      },
      {
        id: 22,
        price: "816 kr",
        discount: "-15%",
        title: "Logitech HD Pro Webcam",
        imgPro: 'https://csdam.net/digitalcontent/0/1746/17469508.jpg',
        url: "https://www.computersalg.se/i/5035505/logitech-hd-pro-webcam-c920s-webbkamera-f%c3%a4rg-1920-x-1080-ljud-usb",
      },
      {
        id: 23,
        price: "4.288 kr",
        discount: "-12%",
        title: "Apple 10.2-inch iPad",
        imgPro: 'https://csdam.net/digitalcontent/0/3158/31581058.jpg',
        url: "https://www.computersalg.se/i/7653837/apple-10-2-inch-ipad-wi-fi-9-e-generation-surfplatta-64-gb-10-2-ips-2160-x-1620-rymdgr%c3%a5",
      },
      {
        id: 24,
        price: "768 kr",
        discount: "-20%",
        title: "Sony | DualSense™",
        imgPro: 'https://csdam.net/data/jpg/0/f70ed31a/f70ed31a-45cd-463e-bb88-e1a6e857c966.jpg',
        url: "https://www.computersalg.se/i/7477115/sony-dualsense-gamepad-tr%c3%a5dl%c3%b6s-bluetooth-f%c3%b6r-sony-playstation-5-vit",
      },
    ],
    image: computersalgLogga,
  },
  {
    name: "Lyko",
    row: [
      {
        id: 37,
        price: "599 kr",
        discount: "50%",
        title: "K18",
        imgPro: 'https://lyko.com/globalassets/product-images/k18-leave-in-molecular-repair-mask-50-ml-2877-100-0050_1.jpg?ref=5A2E227C76&w=2880&h=2880&mode=max&quality=75&format=webp',
        url: "https://lyko.com/sv/k18/k18-leave-in-molecular-repair-mask-50-ml",
      },
      {
        id: 38,
        price: "198,55 kr",
        discount: "50%",
        title: "Cosrx",
        imgPro: 'https://lyko.com/globalassets/product-images/cosrx-advanced-snail-92-all-in-one-cream-100-ml-2162-151-0100_1.jpg?ref=56295F8C1C&w=960&h=960&mode=max&quality=75&format=webp',
        url: "https://lyko.com/sv/cosrx/cosrx-advanced-snail-92-all-in-one-cream-100-ml-49c51abc",
      },
      {
        id: 39,
        price: "153,90 kr",
        discount: "50%",
        title: "Klairs Supple Preparation",
        imgPro: 'https://lyko.com/globalassets/product-images/klairs-supple-preparation-unscented-toner-180-ml-2688-101-0180_1.jpg?ref=C6234E2A3C&w=2880&h=2880&mode=max&quality=75&format=webp',
        url: "https://www.kicks.se/kicks-beauty/hudvard/ansiktsvard/punktbehandlare/kicks-beauty-derma-roller",
      },
      {
        id: 40,
        price: "89,77 kr",
        discount: "50%",
        title: "Smuuti Skin",
        imgPro: 'https://lyko.com/globalassets/product-images/smuuti-skin-watermelon-dew-serum-30-ml-3510-102-0030_1.png?ref=68BBD224B5&w=2880&h=2880&mode=max&quality=75&format=webp',
        url: "https://lyko.com/sv/smuuti-skin/smuuti-skin-watermelon-dew-serum-30-ml",
      },
      {
        id: 41,
        price: "399,20 kr",
        discount: "50%",
        title: "Xlash",
        imgPro: 'https://lyko.com/globalassets/product-images/xlash-ogonfransnaring-3ml-1332-302-0003_12.jpg?ref=2B9313A609&w=2880&h=2880&mode=max&quality=75&format=webp',
        url: "https://lyko.com/sv/xlash/xlash-ogonfransnaring-3ml-16f6e238",
      },
      {
        id: 42,
        price: "449,25 kr",
        discount: "50%",
        title: "Rituals The Ritual of Sakura",
        imgPro: 'https://lyko.com/globalassets/product-images/rituals-the-ritual-of-sakura-large-gift-set-1808-a87-0001_1.jpg?ref=ADD2C6497D&w=960&h=960&mode=max&quality=75&format=webp',
        url: "https://lyko.com/sv/rituals/rituals-the-ritual-of-sakura-large-gift-set",
      },
    ],
    image: lykoLogga,
  },
  {
    name: "Albrektsguld",
    row: [
      {
        id: 67,
        price: "16 999 kr",
        discount: "-18%",
        title: "Ring med labbodlade <br>diamanter",
        imgPro: 'https://media.viskan.com/v2/albrektsprod/normal/20152016_front.jpg',
        url: "https://www.albrektsguld.se/article/ring_med_labbodlade_diamanter_20152016",
      },
      {
        id: 68,
        price: "349 kr",
        discount: "-10%",
        title: "Klocka med dekorativ länk",
        imgPro: 'https://media.viskan.com/v2/albrektsprod/normal/20152014_front.jpg',
        url: "https://www.albrektsguld.se/article/klocka_med_dekorativ_lank",
      },
      {
        id: 69,
        price: "7 999 kr",
        discount: "-15%",
        title: "Ring med labbodlade <br>diamanter",
        imgPro: 'https://media.viskan.com/v2/albrektsprod/normal/20151891_front.jpg',
        url: "https://www.albrektsguld.se/article/ring_med_labbodlade_diamanter_20151891",
      },
      {
        id: 70,
        price: "199 kr",
        discount: "-10%",
        title: "Halsband i stål",
        imgPro: 'https://media.viskan.com/v2/albrektsprod/large/20152503_front.jpg',
        url: "https://www.albrektsguld.se/article/halsband_i_stal_20152503",
      },
      {
        id: 71,
        price: "15 399 kr",
        discount: "-8%",
        title: "Ring i 18K guld",
        imgPro: 'https://media.viskan.com/v2/albrektsprod/normal/20117510_front.jpg',
        url: "https://www.albrektsguld.se/article/ring_i_18k_guld_20117510",
      },
      {
        id: 72,
        price: "449 kr",
        discount: "-12%",
        title: "Herrklocka",
        imgPro: 'https://media.viskan.com/v2/albrektsprod/normal/20023416_front.jpg',
        url: "https://www.albrektsguld.se/article/herrklocka_20023416",
      },
    ],
    image: alberktsguldLogga,
  },
  {
    name: "Lindex",
    row: [
      {
        id: 49,
        price: "479:50 kr",
        discount: "-30%",
        title: "Skjortklänning i linne",
        imgPro: 'https://i8.amplience.net/i/Lindex/3000390_80_PS_F/svart-skjortklanning-i-linne?fmt=auto&$qltDefault$&$cache$&$crop$&$productDetailImage$&vw=1940',
        url: "https://www.lindex.com/se/p/3000390-80-svart-skjortklanning-i-linne",
      },
      {
        id: 50,
        price: "249:50 kr",
        discount: "-50%",
        title: "Maxiklänning",
        imgPro: 'https://i8.amplience.net/i/Lindex/3001053_9614_PS_F?fmt=auto&$qltDefault$&$cache$&$crop$&$productDetailZoomV2$&vw=1940',
        url: "https://www.lindex.com/se/p/3001053-9614-bla-maxiklanning",
      },
      {
        id: 51,
        price: "299:50 kr",
        discount: "-50%",
        title: "Maxiklänning med virkad look",
        imgPro: 'https://i8.amplience.net/i/Lindex/3002450_1480_PS_F/vit-maxiklanning-med-virkad-look?fmt=auto&$qltDefault$&$cache$&$crop$&$productDetailImage$&vw=1940',
        url: "https://www.lindex.com/se/p/3002450-1480-vit-maxiklanning-med-virkad-look",
      },
      {
        id: 52,
        price: "174:50 kr",
        discount: "-30%",
        title: "Pyjamasset",
        imgPro: 'https://i8.amplience.net/i/Lindex/3000900_8838_PS_F/bla-pyjamasset?fmt=auto&$qltDefault$&$cache$&$crop$&$productDetailImage$&vw=1940',
        url: "https://www.lindex.com/se/p/3000900-8838-bla-pyjamasset",
      },
      {
        id: 53,
        price: "174:50 kr",
        discount: "-30%",
        title: "Pyjamasset",
        imgPro: 'https://i8.amplience.net/i/Lindex/3000900_1230_PS_F/beige-pyjamasset?fmt=auto&$qltDefault$&$cache$&$crop$&$productDetailImage$&vw=1940',
        url: "https://www.lindex.com/se/p/3000900-1230-beige-pyjamasset",
      },
      {
        id: 54,
        price: "64:50 kr",
        discount: "-50%",
        title: "T-shirt",
        imgPro:'https://i8.amplience.net/i/Lindex/3002135_1672_PS_F/brun-t-shirt?fmt=auto&$qltDefault$&$cache$&$crop$&$productDetailImage$&vw=1940',
        url: "https://www.lindex.com/se/p/3002135-1672-brun-t-shirt",
      },
    ],
    image: lindexLogga,
  },
  {
    name: "Weekday",
    row: [
      {
        id: 55,
        price: "270 kr",
        discount: "-30%",
        title: "LEDIG KORTÄRMAD SKJORTA <br>I LINNEBLANDNING",
        imgPro:'https://public.assets.hmgroup.com/assets/003/40/2a/402a92adc5fcd96c998461aeda202a11cfca1db5_xl-1.jpg',
        url: "https://www.weekday.com/sv-se/p/men/shirts/linen/relaxed-linen-blend-short-sleeve-shirt-ecru-1226880001/",
      },
      {
        id: 56,
        price: "360 kr",
        discount: "-50%",
        title: "BAGGY FALLSKÄRMSBYXA<br> MATS",
        imgPro: 'https://public.assets.hmgroup.com/assets/003/58/88/5888bd4b5e47ce8c26f5617388a7b7dcc02d12c2_lg-1.jpg',
        url: "https://www.weekday.com/sv-se/p/men/trousers/cargo/parachute-loose-cargo-trousers-dark-grey-008/",
      },
      {
        id: 57,
        price: "210 kr",
        discount: "-50%",
        title: "OVERSIZED RESORT SHORT <br>SLEEVE SHIRT",
        imgPro: 'https://public.assets.hmgroup.com/assets/003/00/c2/00c2dfbf6b1836b4303ebcc27085fe40b037197a_lg-1.jpg',
        url: "https://www.weekday.com/sv-se/p/men/shirts/short-sleeve/oversized-resort-short-sleeve-shirt-black-001/",
      },
      {
        id: 58,
        price: "250 kr",
        discount: "-30%",
        title: "MARWIN VÄST MED <br>DRAGKEDJA",
        imgPro: 'https://public.assets.hmgroup.com/assets/003/e9/00/e900ad7b9220b6a8a06b8dd97472e87c36b54ec7_lg-1.jpg',
        url: "https://www.weekday.com/sv-se/p/men/last-chance/knitwear/marwin-zipped-vest-washed-black-1215511002/",
      },
      {
        id: 59,
        price: "300 kr",
        discount: "-30%",
        title: "BOXY CROCHET SHORT <br>SLEEVE SHIRT",
        imgPro: 'https://public.assets.hmgroup.com/assets/003/4b/9b/4b9b69a6043ecc7ea50628cb0eaac5a41b3fe774_lg-1.jpg',
        url: "https://www.weekday.com/sv-se/p/men/shirts/short-sleeve/boxy-crochet-short-sleeve-shirt-black-1233855002/",
      },
      {
        id: 60,
        price: "290  kr",
        discount: "-50%",
        title: "OVERSIZED LINEN SHIRT",
        imgPro: 'https://public.assets.hmgroup.com/assets/003/04/09/04097dd9c768b86ad7fd52f12df8f8eeea735043_lg-1.jpg',
        url: "https://www.weekday.com/sv-se/p/men/shirts/linen/oversized-linen-shirt-white-002/",
      },
    ],
    image: weekdayLooga,
  },
  {
    name: "Bjornborg",
    row: [
      {
        id: 61,
        price: "489  kr",
        discount: "-30%",
        title: "Cotton Stretch Boxer 5-pack",
        imgPro:'https://www.bjornborg.com/media/images/2308_f0b220af1c-10003016-mp004-1-original.jpg?auto=format&q=70&w=1000',
        url: "https://www.bjornborg.com/se/cotton-stretch-boxer-5-pack-10003016-mp004/",
      },
      {
        id: 62,
        price: "674  kr",
        discount: "-50%",
        title: "Cotton Stretch Boxer 7-pack",
        imgPro: 'https://www.bjornborg.com/media/images/2309_3a993bfdf1-10003017-mp003-1-original.jpg?auto=format&q=70&w=1000',
        url: "https://www.bjornborg.com/se/cotton-stretch-boxer-7-pack-10003017-mp003/",
      },
      {
        id: 63,
        price: "489  kr",
        discount: "-50%",
        title: "Cotton Stretch Boxer 5-pack",
        imgPro: 'https://www.bjornborg.com/media/images/2308_3d83726cda-10003016-mp005-1-original.jpg?auto=format&q=70&w=1000',
        url: "https://www.bjornborg.com/se/cotton-stretch-boxer-5-pack-10003016-mp005/",
      },
      {
        id: 64,
        price: "779 kr",
        discount: "-30%",
        title: "Men's Leather Sneaker Sl200",
        imgPro: 'https://www.bjornborg.com/media/images/2409_99217ecfed-2412643509-2400-1-original.jpg?auto=format&q=70&w=1000',
        url: "https://www.bjornborg.com/se/sl200-nu-lea-m-cog/",
      },
      {
        id: 65,
        price: "244 kr",
        discount: "-30%",
        title: "Men's Sandal Knox",
        imgPro: 'https://www.bjornborg.com/media/images/539_dc8e64cb9b-bs192204-01-2-original.jpg?auto=format&q=70&w=1000',
        url: "https://www.bjornborg.com/se/mens-sandal-knox-2112581201-0910/",
      },
      {
        id: 66,
        price: "719 kr",
        discount: "-50%",
        title: "Men's Sneakers T2020",
        imgPro: 'https://www.bjornborg.com/media/images/2289_71586d73b6-2412665501-1973-1-original.jpg?auto=format&q=70&w=1000',
        url: "https://www.bjornborg.com/se/mens-sneakers-t2020-spt-2412665501-1973/",
      },
    ],
    image: bjornborgLogga,
  },
  {
    name: "Guldfynd",
    row: [
      {
        id: 67,
        price: "3 249 kr",
        discount: "-18%",
        title: "RING I 18K GULD",
        imgPro: 'https://media.viskan.com/v2/idunaprod/normal/20146954_front.jpg',
        url: "https://www.guldfynd.se/article/ring_i_18k_guld_20146954",
      },
      {
        id: 68,
        price: "3 499 kr",
        discount: "-10%",
        title: "DIAMANTRING I 18K GULD",
        imgPro: 'https://media.viskan.com/v2/idunaprod/large/20130832_front.jpg',
        url: "https://www.guldfynd.se/article/diamantring_i_18k_guld_20130832",
      },
      {
        id: 69,
        price: "3 349 kr",
        discount: "-15%",
        title: "HALSBAND I 18K GULD",
        imgPro: 'https://media.viskan.com/v2/idunaprod/normal/20146154_front.jpg',
        url: "https://www.guldfynd.se/article/halsband_i_18k_guld_20146154",
      },
      {
        id: 70,
        price: "3 249 kr",
        discount: "-10%",
        title: "DIAMANTRING I 18K<br> ROSÉGULD",
        imgPro: 'https://media.viskan.com/v2/idunaprod/normal/20123500_front.jpg',
        url: "https://www.guldfynd.se/article/diamantring_i_18k_roseguld_20123500",
      },
      {
        id: 71,
        price: "2 899 kr",
        discount: "-8%",
        title: "ÖRHÄNGEN I 18K GULD",
        imgPro: 'https://media.viskan.com/v2/idunaprod/normal/20146156_front.jpg',
        url: "https://www.guldfynd.se/article/orhangen_i_18k_guld_20146156",
      },
      {
        id: 72,
        price: "74 kr",
        discount: "-12%",
        title: "RING THE FAHRIYA I ÄKTA <br>SILVER",
        imgPro: 'https://media.viskan.com/v2/idunaprod/normal/20138814_front.jpg',
        url: "https://www.albrektsguld.se/article/herrklocka_20023416",
      },
    ],
    image: guldfyndLogga,
  },
  {
    name: "Bubbleroom",
    row: [
      {
        id: 73,
        price: "629 kr",
        discount: "-30%",
        title: "Low Platform Slip-in",
        imgPro: 'https://images.bubbleroom.se/data/product/500x725/bubbleroom-bernice-low-platform-slip-in.jpg',
        url: "https://www.bubbleroom.se/p/bubbleroom/skor/low-platform-slip-in-mole",
      },
      {
        id: 74,
        price: "479 kr",
        discount: "-50%",
        title: "Midi Sandal",
        imgPro: 'https://images.bubbleroom.se/data/product/500x725/bubbleroom-sandy-sandal_6.jpg',
        url: "https://www.bubbleroom.se/p/bubbleroom/skor/midi-sandal-white",
      },
      {
        id: 75,
        price: "1 979 kr",
        discount: "-50%",
        title: "The Small Tote",
        imgPro: 'https://images.bubbleroom.se/data/product/500x725/marc-jacobs-the-small-tote-001-black_1.jpg',
        url: "https://www.bubbleroom.se/p/marc-jacobs-the/accessoarer/vaskor/the-mini-tote-001-black",
      },
      {
        id: 76,
        price: "3 689 kr",
        discount: "-30%",
        title: "The Snapshot",
        imgPro: 'https://images.bubbleroom.se/data/product/500x725/marc-jacobs-the-snapshot-cottonmulti_4.jpg',
        url: "https://www.bubbleroom.se/p/marc-jacobs-the/accessoarer/vaskor/axelremsvaskor/the-snapshot-cotton-multi",
      },
      {
        id: 77,
        price: "1 109 kr",
        discount: "-30%",
        title: "Gaby",
        imgPro: 'https://images.bubbleroom.se/data/product/500x725/corlin-eyewear-gaby-black_3.jpg',
        url: "https://www.bubbleroom.se/p/corlin-eyewear/accessoarer/solglasogon-7/gaby-black",
      },
      {
        id: 78,
        price: "2 789 kr",
        discount: "-50%",
        title: "The Longshot Chain Wallet",
        imgPro: 'https://images.bubbleroom.se/data/product/500x725/marc-jacobs-the-longshot-chain-wallet-001-black_2.jpg',
        url: "https://www.bubbleroom.se/p/marc-jacobs-the/accessoarer/vaskor/festvaskor/the-longshot-chain-wallet-001-black",
      },
    ],
    image: bubbleroomLogga,
  },
  {
    name: "MQ",
    row: [
      {
        id: 79,
        price: "199 kr",
        discount: "-30%",
        title: "Claire knitted top",
        imgPro: 'https://www.mq.se/globalassets/dam/toppar--t-shirts/linne/claire-knitted-top/6811289_pink_product_list.jpg?preset=product-medium',
        url: "https://www.mq.se/stockh-lm-studio-claire-knitted-top-pink/",
      },
      {
        id: 80,
        price: "699 kr",
        discount: "-50%",
        title: "Gabrielle dress",
        imgPro: 'https://www.mq.se/globalassets/dam/klanningar/midiklanning/gabrielle-dress/6011262_offwhite_product_list.jpg?preset=product-medium',
        url: "https://www.mq.se/stockh-lm-gabrielle-dress-offwhite/",
      },
      {
        id: 81,
        price: "489 kr",
        discount: "-50%",
        title: "Lusia dress",
        imgPro: 'https://www.mq.se/globalassets/dam/klanningar/maxiklanning/lusia-dress/6011269_pink-mini-flw_product_list.jpg?preset=product-medium',
        url: "https://www.mq.se/dobber-lusia-dress-pink-mini-flw/",
      },
      {
        id: 82,
        price: "279 kr",
        discount: "-30%",
        title: "Liss knitted top",
        imgPro: 'https://www.mq.se/globalassets/dam/toppar--t-shirts/linne/liss-knitted-top/6831253_snow-white_product_list.jpg?preset=product-medium',
        url: "https://www.mq.se/stockh-lm-liss-knitted-top-snow-white/",
      },
      {
        id: 83,
        price: "349 kr",
        discount: "-30%",
        title: "Natalia trousers",
        imgPro: 'https://www.mq.se/globalassets/dam/byxor/vida-byxor/natalia-trousers/5821225_white_product_list.jpg?preset=product-medium',
        url: "https://www.mq.se/dobber-natalia-trousers-white/",
      },
      {
        id: 84,
        price: "349 kr",
        discount: "-50%",
        title: "Fia leather bag",
        imgPro: 'https://www.mq.se/globalassets/dam/accessoarer/axelremvaskor/fia-leather-bag/8101230_nature-black_product_list.jpg?preset=product-medium',
        url: "https://www.mq.se/stockh-lm-fia-leather-bag-nature-black/",
      },
    ],
    image: mqLogga,
  },
  {
    name: "NK",
    row: [
      {
        id: 85,
        price: "597 kr",
        discount: "-30%",
        title: "Sammy terry polo sky blue",
        imgPro: 'https://www.nk.se/globalassets/61910139_230036_1.jpg?ref=74D26A2EAC&w=1280&mode=pad&h=1600&format=jpg&quality=85',
        url: "https://www.nk.se/paul--friends/sammy-terry-polo-sky-blue-v00097060",
      },
      {
        id: 86,
        price: "1 399,60 kr",
        discount: "-50%",
        title: "Badbyxor greca svart",
        imgPro: 'https://www.nk.se/globalassets/61654175_60476633-a80g-_1.jpg?ref=08EB5C2A74&w=1280&mode=pad&h=1600&format=jpg&quality=85',
        url: "https://www.nk.se/versace/badbyxor-greca-svart-v00082408",
      },
      {
        id: 87,
        price: "4 199,40 kr",
        discount: "-50%",
        title: "Skaljacka light soft shell-r <br>e.dye svart",
        imgPro: 'https://www.nk.se/globalassets/61654175_60562087-v0029-_1.jpg?ref=FC57A8070A&w=1280&mode=pad&h=1600&format=jpg&quality=85',
        url: "https://www.nk.se/stone-island/skaljacka-light-soft-shell-r-e.dye-svart-v00088781",
      },
      {
        id: 88,
        price: "300 kr",
        discount: "-30%",
        title: "T-shirt matilda oversize tee <br>off white",
        imgPro: 'https://www.nk.se/globalassets/61590456_221299_1.jpg?ref=84DDAF533C&w=1280&mode=pad&h=1600&format=jpg&quality=85',
        url: "https://www.nk.se/mm-x-nksk/t-shirt-matilda-oversize-tee-off-white-v00018037",
      },
      {
        id: 89,
        price: "649,50 kr",
        discount: "-30%",
        title: "Keps fashion logo icon woodsmoke",
        imgPro: 'https://www.nk.se/globalassets/61654175_60538503-1189-_1ny.jpg?ref=A989AA22E0&w=1280&mode=pad&h=1600&format=jpg&quality=85',
        url: "https://www.nk.se/moose-knuckles/keps-fashion-logo-icon-woodsmoke-v00081341",
      },
      {
        id: 90,
        price: "1 437 kr",
        discount: "-50%",
        title: "Rider sweatshirt marine",
        imgPro: 'https://www.nk.se/globalassets/61910139_227272_b.jpg?ref=C127ACBF82&w=1280&mode=pad&h=1600&format=jpg&quality=85',
        url: "https://www.nk.se/a.p.c/rider-sweatshirt-marine-v00057048",
      },
    ],
    image: nkLogga,
  },
  {
    name: "Jollyroom",
    row: [
      {
        id: 103,
        price: "439 kr",
        discount: "40%",
        title: "Barbie Hus & Docka",
        imgPro: 'https://www.jollyroom.se/storage/C6A4DD64C34B2A3AEC5F20BBBA2E95987DA2EA3F1F3B7BBB520E1E59AAF45EA1/19b48494ec4c43a08c82ecdeecbdfc5b/400-303-0-jpg.Jpeg/media/face5f667ac74e54a3fb629639df918e/HCD48-3672_1.jpeg',
        url: "https://www.jollyroom.se/leksaker/dockor-gosedjur/dockhus/barbie-hus-docka",
      },
      {
        id: 104,
        price: "499 kr",
        discount: "40%",
        title: "JLY Skrivbord med Stol, Vit",
        imgPro: 'https://www.jollyroom.se/storage/94E49F7EC34F5545223AC92A40DF0CB6CDBA67FC51BF4C436AA7D2F2CE48EB23/5ddb9c52b8cb45c38da16557696002b0/400-339-0-jpg.Jpeg/media/fc9073e8b823484bbadf5c0f3a14deec/PH13F021-3625_1.jpeg',
        url: "https://www.jollyroom.se/barnrummet/barnmobler/bord/skrivbord/jly-skrivbord-med-stol-vit",
      },
      {
        id: 105,
        price: "2 295 kr",
        discount: "40%",
        title: "Beemoo Easy Fly Lux 4 Sulky,<br> Breezy Sufflett, Stone Grey",
        imgPro: 'https://www.jollyroom.se/storage/D61D7D5F7019AF169536A0A8576BE037372B1701D3AC7C1A55752A9539B369A2/825115811f2b4e8f9166208573c08374/400-350-0-jpg.Jpeg/media/87dac0fddebe4e839831975071e96e97/912770.jpeg',
        url: "https://www.jollyroom.se/barnvagnar/sulkys/sulkys/beemoo-easy-fly-lux-4-breezy-stongrey",
      },
      {
        id: 106,
        price: "949 kr",
        discount: "40%",
        title: "Ergobaby Adapt Soft Flex<br> Mesh Bärsele, Onyx Black",
        imgPro: 'https://www.jollyroom.se/storage/2E21BB4C6BAB90912BE8CA260F86DBBDD3D08E62154BB7A4E239F95BA45215DF/106ba90ea2254f2f9cf3a071997b3f51/259-450-0-jpg.Jpeg/media/9a9f98335af443fda91ccb466416f8ae/ADAPTSOFTFLEX-BCASFMONYX-1228_1.jpeg',
        url: "https://www.jollyroom.se/babyprodukter/barselar-barsjalar/barselar/ergobaby-adapt-soft-flex-mesh-barsele-onyx-black",
      },
      {
        id: 107,
        price: "3 999 kr",
        discount: "40%",
        title: "Pinepeak Studsmatta inkl.<br> Skyddsnät Rektangulär",
        imgPro: 'https://www.jollyroom.se/storage/ma/9736117c1a3d40c28dc3b028df5ed210/ad357508e01b4284851d344567cca38b/400-264-0-jpg.Jpeg/CE41F7196A018C8FFDC8BA1F8D93FFEAD7D09BB6/T801-10-3164_1.jpeg',
        url: "https://www.jollyroom.se/fritid-hobby/utelek/studsmattor/pinepeak-studsmatta-inkl-skyddsnat-rektangular",
      },
      {
        id: 108,
        price: "259 kr",
        discount: "40%",
        title: "OKBaby Roady Resepotta, Grå",
        imgPro: 'https://www.jollyroom.se/storage/75132ECC927352B8D85856B0D795839308A373E928EE8DEEAC4B01C84D2CC7CE/c5612a2dfa174d2891353aca38f10c32/400-400-0-jpg.Jpeg/media/4239471c321c477082736d4d9df240cd/3905-2300-1113_1.jpeg',
        url: "https://www.jollyroom.se/babyprodukter/badrummet/pottor/okbaby-roady-resepotta-gra",
      },
    ],
    image: jollyroomLogga,
  },
  {
    name: "Babyv",
    row: [
      {
        id: 103,
        price: "4 495 kr",
        discount: "40%",
        title: "Silver Cross Pioneer duovagn,<br> silver/linen",
        imgPro: 'https://02.cdn37.se/VE9/images/2.214317/silver-cross-pioneer-duovagn-silverlinen.jpeg',
        url: "https://www.babyv.se/sv/articles/2.232.15581/silver-cross-pioneer-duovagn-silverlinen",
      },
      {
        id: 104,
        price: "7 290 kr",
        discount: "40%",
        title: "Silver Cross Pioneer duovagn <br>+ simplicity babyskydd + bas",
        imgPro: 'https://02.cdn37.se/VE9/images/2.293931/silver-cross-pioneer-duovagn-simplicity-babyskydd-bas.jpeg',
        url: "https://www.babyv.se/sv/articles/2.232.20974/silver-cross-pioneer-duovagn-simplicity-babyskydd-bas",
      },
      {
        id: 105,
        price: "2 995 kr",
        discount: "40%",
        title: "Maxi-Cosi Lila sittvagn, nomad<br> black",
        imgPro: 'https://02.cdn37.se/VE9/images/2.217011/maxi-cosi-lila-sittvagn-nomad-black.jpeg',
        url: "https://www.babyv.se/sv/articles/2.232.15712/maxi-cosi-lila-sittvagn-nomad-black",
      },
      {
        id: 106,
        price: "2 795 kr",
        discount: "40%",
        title: "Silver Cross Simplicity +<br> isofixbas",
        imgPro: 'https://02.cdn37.se/VE9/images/2.293379/silver-cross-simplicity-isofixbas.jpeg',
        url: "https://www.babyv.se/sv/articles/2.232.20926/silver-cross-simplicity-isofixbas",
      },
      {
        id: 107,
        price: "5 995 kr",
        discount: "40%",
        title: "Silver Cross Pioneer duovagn<br> Expedition Special Edt",
        imgPro: 'https://02.cdn37.se/VE9/images/2.189687/silver-cross-pioneer-duovagn-expedition-special-edt.jpeg',
        url: "https://www.babyv.se/sv/articles/2.232.13563/silver-cross-pioneer-duovagn-expedition-special-edt",
      },
      {
        id: 108,
        price: "2 995 kr",
        discount: "40%",
        title: "Maxi-Cosi Lila sittvagn, nomad<br> grey",
        imgPro: 'https://02.cdn37.se/VE9/images/2.216981/maxi-cosi-lila-sittvagn-nomad-grey.jpeg',
        url: "https://www.babyv.se/sv/articles/2.232.15711/maxi-cosi-lila-sittvagn-nomad-grey",
      },
    ],
    image: babyvLogga,
  },
  {
    name: "Babyland",
    row: [
      {
        id: 103,
        price: "449 kr",
        discount: "40%",
        title: "Mountain Buggy duet <br>Solskydd Enkel",
        imgPro: 'https://media.babyland.se/product-images/L/81318-0.jpg',
        url: "https://www.babyland.se/mountain-buggy-duet-solskydd-enkel",
      },
      {
        id: 104,
        price: "399 kr",
        discount: "40%",
        title: "NERF Elite 2.0 Flip Blaster<br> 16 pipor",
        imgPro: 'https://media.babyland.se/product-images/L/nerf-elite-2-0-flip-16-0.jpg',
        url: "https://www.babyland.se/nerf-elite-2-0-flip-blaster-16-pipor",
      },
      {
        id: 105,
        price: "699 kr",
        discount: "40%",
        title: "Uppblåsbar Sprinklerpool",
        imgPro: 'https://media.babyland.se/product-images/L/uppblasbar-sprinklerpool-krabba-2.jpg',
        url: "https://www.babyland.se/uppblasbar-sprinklerpool-krabba",
      },
      {
        id: 106,
        price: "299 kr",
        discount: "40%",
        title: "Joolz Day/Geo Parasoll",
        imgPro: 'https://media.babyland.se/product-images/L/joolz-daygeo-parasol-premium-pink-0.jpg',
        url: "https://www.babyland.se/joolz-daygeo-parasoll-premium-pink",
      },
      {
        id: 107,
        price: "299 kr",
        discount: "40%",
        title: "Smoby Green Strandset",
        imgPro: 'https://media.babyland.se/product-images/L/smoby-green-strandset-1.jpg',
        url: "https://www.babyland.se/smoby-green-strandset",
      },
      {
        id: 108,
        price: "1 799 kr",
        discount: "40%",
        title: "Sebra Babynest 3-i-1 ",
        imgPro: 'https://media.babyland.se/product-images/L/sebra-babynest-3-i-1-sophora-leaves-9.jpg',
        url: "https://www.babyland.se/sebra-babynest-3-i-1-sophora-leaves",
      },
    ],
    image: babylandLogga,
  },
  {
    name: "Cervera",
    row: [
      {
        id: 115,
        price: "310 kr",
        discount: "",
        title: "Celebration Vinglas 45 cl<br> 6-pack",
        imgPro: 'https://cerveranew.cdn.storm.io/351c6b98-767a-4046-a4b4-b89e9aef8f96.jpeg?mode=Pad&quality=75&format=webp&width=828&height=828',
        url: "https://www.cervera.se/produkt/celebration-vinglas-45-cl-6-pack",
      },
      {
        id: 116,
        price: "689 kr",
        discount: "",
        title: "Mon Amie Skål 60 cl 4-pack<br> Vit/blå",
        imgPro: 'https://cerveranew.cdn.storm.io/3aee3990-103a-4d2f-ad90-e5c3f11a7afd.jpeg?mode=Pad&quality=75&format=webp&width=828&height=828',
        url: "https://www.cervera.se/produkt/rorstrand-mon-amie-mon-amie-skal-60cl-4-pack",
      },
      {
        id: 117,
        price: "310 kr",
        discount: "",
        title: "Celebration Champagneglas<br> 19 cl 6-pack",
        imgPro: 'https://cerveranew.cdn.storm.io/da007b90-3ed7-4b28-86ae-35a1983a5ebb.jpeg?mode=Pad&quality=75&format=webp&width=828&height=828',
        url: "https://www.cervera.se/produkt/celebration-champagneglas-19-cl-6-pack",
      },
      {
        id: 118,
        price: "499 kr",
        discount: "",
        title: "Gin och Tonicglas 4-pack <br>64 cl",
        imgPro: 'https://cerveranew.cdn.storm.io/ba265909-261d-42b0-872d-059024cea096.jpeg?mode=Pad&quality=75&format=webp&width=828&height=828',
        url: "https://www.cervera.se/produkt/gintonicglas-64cl-4p",
      },
      {
        id: 119,
        price: "559 kr",
        discount: "",
        title: "Unlimited Stekpanna 28 cm <br>Svart",
        imgPro: 'https://cerveranew.cdn.storm.io/d9481a0c-6e79-4c52-8740-6f5fc1d50550.jpeg?mode=Pad&quality=75&format=webp&width=828&height=828',
        url: "https://www.cervera.se/produkt/unlimited-stekpanna-28cm",
      },
      {
        id: 120,
        price: "395 kr",
        discount: "",
        title: "Unlimited Pannkakspanna <br>25 cm Svart",
        imgPro: 'https://cerveranew.cdn.storm.io/adada94a-9bed-45ed-90b6-5d74e8d00b85.jpeg?mode=Pad&quality=75&format=webp&width=828&height=828',
        url: "https://www.cervera.se/produkt/unlimited-pannkakspanna-25cm",
      },
    ],
    image: cerveraLogga,
  },
    {
      name: "Hemtex",
      row: [
        { id: 121, price:'179,97 kr', discount:'', title: "Kuddfodral natur - 60x60 cm",  imgPro: 'https://www.hemtex.se/globalassets/productimages/208644060070.jpg?ref=577C5CA062&w=590&bgcolor=F7F7F7&borderwidth=59&mode=pad&h=787', url: "https://www.hemtex.se/kuddar-pladar/prydnadskuddar/kuddfodral/atelier-mix-stripe-kuddfodral-natur?v=208644060070&_gl=1*lqo2dn*_up*MQ..&gclid=CjwKCAjwzIK1BhAuEiwAHQmU3rwdu205HGseko0XLjKf5b42A8ydyuao27WTkKGQB_pvgvTbioi1-BoCRrYQAvD_BwE" },
        { id: 122, price:'749,97 kr', discount:'', title: "Puff beige - 55x55x22 cm",  imgPro: 'https://www.hemtex.se/globalassets/productimages/209354055210.jpg?ref=2C278DB234&w=590&bgcolor=F7F7F7&borderwidth=59&mode=pad&h=787', url: "https://www.hemtex.se/mobler/sittpuffar--pallar/meghan-puff-beige?v=209354055210&_gl=1*9crwuf*_up*MQ..&gclid=CjwKCAjwzIK1BhAuEiwAHQmU3rwdu205HGseko0XLjKf5b42A8ydyuao27WTkKGQB_pvgvTbioi1-BoCRrYQAvD_BwE" },
        { id: 123, price:'89,97 kr', discount:'', title: "Taklampa svart - ø31 cm", imgPro: 'https://www.hemtex.se/globalassets/productimages/20212711244.jpg?ref=F739577370&w=590&bgcolor=F7F7F7&borderwidth=59&mode=pad&h=787', url: "https://www.hemtex.se/lampor--belysning/taklampor/rond-taklampa-svart?v=20212711244&_gl=1*ac7322*_up*MQ..&gclid=CjwKCAjwzIK1BhAuEiwAHQmU3rwdu205HGseko0XLjKf5b42A8ydyuao27WTkKGQB_pvgvTbioi1-BoCRrYQAvD_BwE" },
        { id: 124, price:'89,97 kr', discount:'', title: "Lykta grå - 15x15x15 cm",imgPro: 'https://www.hemtex.se/globalassets/productimages/205363015920.jpg?ref=F9BCA28B7A&w=590&bgcolor=F7F7F7&borderwidth=59&mode=pad&h=787', url: "https://www.hemtex.se/inredning-dekorationer/lyktor/sirius-lykta-gra?v=205363015920&_gl=1*1k6ql8k*_up*MQ..&gclid=CjwKCAjwzIK1BhAuEiwAHQmU3rwdu205HGseko0XLjKf5b42A8ydyuao27WTkKGQB_pvgvTbioi1-BoCRrYQAvD_BwE" },
        { id: 125, price:'269,97 kr', discount:'', title: "Kruka multi/beige - 26,5x25,<br>8x21 cm", imgPro: 'https://www.hemtex.se/globalassets/productimages/210845021323.jpg?ref=C5F9D59092&w=590&bgcolor=F7F7F7&borderwidth=59&mode=pad&h=787', url: "https://www.hemtex.se/inredning-dekorationer/vaser/anni-kruka-multibeige?v=210845021323&_gl=1*b75rwd*_up*MQ..&gclid=CjwKCAjwzIK1BhAuEiwAHQmU3rwdu205HGseko0XLjKf5b42A8ydyuao27WTkKGQB_pvgvTbioi1-BoCRrYQAvD_BwE" },
        { id: 126, price:'359,97 kr', discount:'', title: "Spegel ljus natur - 45x60 cm", imgPro: 'https://www.hemtex.se/globalassets/productimages/211100060230.jpg?ref=2147A8AA47&w=590&bgcolor=F7F7F7&borderwidth=59&mode=pad&h=787', url: "https://www.hemtex.se/inredning-dekorationer/speglar/amir-spegel-ljus-natur?v=211100060230&_gl=1*1jdydz7*_up*MQ..&gclid=CjwKCAjwzIK1BhAuEiwAHQmU3rwdu205HGseko0XLjKf5b42A8ydyuao27WTkKGQB_pvgvTbioi1-BoCRrYQAvD_BwE" },
      ],
      image: hemtexLogga,
    },
  //   {
  //     name: "Buttericks",
  //     row: [
  //       { id: 145, price:'', discount:'', title: "Offer 4", description: "Description of Offer 4", imgPro: tv, url: "" },
  //       { id: 146, price:'', discount:'', title: "Offer 5", description: "Description of Offer 5", imgPro: tv, url: "" },
  //       { id: 147, price:'', discount:'', title: "Offer 6", description: "Description of Offer 6", imgPro: tv, url: "" },
  //       { id: 148, price:'', discount:'', title: "Offer 7", description: "Description of Offer 7", imgPro: tv, url: "" },
  //       { id: 149, price:'', discount:'', title: "Offer 8", description: "Description of Offer 8", imgPro: tv, url: "" },
  //       { id: 150, price:'', discount:'', title: "Offer 9", description: "Description of Offer 9", imgPro: tv, url: "" },
  //     ],
  //     image: buttericksLogga,
  //   },
  //   {
  //     name: "Flyingtiger",
  //     row: [
  //       { id: 151, price:'', discount:'', title: "Offer 4", description: "Description of Offer 4", imgPro: tv, url: "" },
  //       { id: 152, price:'', discount:'', title: "Offer 5", description: "Description of Offer 5", imgPro: tv, url: "" },
  //       { id: 153, price:'', discount:'', title: "Offer 6", description: "Description of Offer 6", imgPro: tv, url: "" },
  //       { id: 154, price:'', discount:'', title: "Offer 7", description: "Description of Offer 7", imgPro: tv, url: "" },
  //       { id: 155, price:'', discount:'', title: "Offer 8", description: "Description of Offer 8", imgPro: tv, url: "" },
  //       { id: 156, price:'', discount:'', title: "Offer 9", description: "Description of Offer 9", imgPro: tv, url: "" },
  //     ],
  //     image: flyingtigerLogga,
  //   },
  //   {
  //     name: "Proshop",
  //     row: [
  //       { id: 157, price:'3.211:-', discount:'-8%', title: "LG UltraGear 27GP850P-B -<br> Gaming Series", description: "LG UltraGear, supremely powerful gear,<br> boosts your chances of victory.", imgPro: comp1, url: "https://www.computersalg.se/i/9740043/lg-ultragear-27gp850p-b-gaming-series-led-sk%c3%a4rm-spel-27-2560-x-1440-qhd-165-hz-nano-ips-400-cd-m-1000-1-displayhdr-400-1-ms" },
  //       { id: 158, price:'1.097:-', discount:'-12%', title: "Logitech® | MX Keys S", description: "Högpresterande tangentbord, konstruerade<br> för bekvämt, snabbt och med smart<br> belysning och programmerbara tangenter.", imgPro: comp2, url: "https://www.computersalg.se/i/10308608/logitech-mx-keys-s-tastatur-bagbelyst-bluetooth-2-4-ghz-nordisk-layout-grafit" },
  //       { id: 159, price:'1.130:-', discount:'-10%', title: "Logitech MX Master 3S ", description: "Här är Logitech MX Master 3S - en ikonisk<br> mus i en omarbetad version för ultimat<br> känslighet, prestanda och flöde.", imgPro: comp3, url: "https://www.computersalg.se/i/8337611/logitech-mx-master-3s-mus-laser-7-knappar-tr%c3%a5dl%c3%b6s-bluetooth-2-4-ghz-logitech-bolt-mottagare-grafit" },
  //       { id: 160, price:'798:-', discount:'-15%', title: "Logitech HD Pro Webcam C920S", description: "C920s Pro HD-webbkamera ger otroligt skarpa,<br> tydliga bilder och detaljerade bilder<br> i naturliga färger.", imgPro: comp4, url: "https://www.computersalg.se/i/5035505/logitech-hd-pro-webcam-c920s-webbkamera-f%c3%a4rg-1920-x-1080-ljud-usb" },
  //       { id: 161, price:'4.197:-', discount:'-12%', title: "Apple 10.2-inch iPad Wi-Fi - 9:e generation", description: "Kraftfull. Lätt att använda. Allsidig.<br> Nya iPad är utvecklad för allt du gillar<br> att göra.", imgPro: comp5, url: "https://www.computersalg.se/i/7653837/apple-10-2-inch-ipad-wi-fi-9-e-generation-surfplatta-64-gb-10-2-ips-2160-x-1620-rymdgr%c3%a5" },
  //       { id: 162, price:' 727:-', discount:'-20%', title: "Sony | DualSense™ - Gamepad ", description: "Upptäck en djupare, mycket uppslukande <br> spelupplevelse som väcker action<br> till liv i dina händer.", imgPro: comp6, url: "https://www.computersalg.se/i/7477115/sony-dualsense-gamepad-tr%c3%a5dl%c3%b6s-bluetooth-f%c3%b6r-sony-playstation-5-vit" },
  //     ],
  //     image: proshopLogga,
  //   },
  //   {
  //     name: "Bangerhead",
  //     row: [
  //       { id: 193, price:'169:-', discount:'50%', title: "Lumene", description: "Nordic-C Glow Renew Night Cream 50 ml", imgPro: kicks1, url: "https://www.kicks.se/lumene/hudvard/ansiktsvard/ansiktskram/nattkram/lumene-nordic-c-glow-renew-night-cream-50-ml" },
  //       { id: 194, price:'74:-', discount:'50%', title: "Isadora", description: "Contour Stick'n Brush 36 Warm Mocha", imgPro: kicks2, url: "https://www.kicks.se/isadora/makeup/bas/highlighter/isadora-contour-stickn-brush-36-warm-mocha" },
  //       { id: 195, price:'139:-', discount:'50%', title: "KICKS Beauty", description: "Derma Roller", imgPro: kicks3, url: "https://www.kicks.se/kicks-beauty/hudvard/ansiktsvard/punktbehandlare/kicks-beauty-derma-roller" },
  //       { id: 196, price:'74:-', discount:'50%', title: "Isadora", description: "Nature Enhanced <br> Cream Blush 32 Soft Pink", imgPro: kicks4, url: "https://www.kicks.se/isadora/makeup/bas/rouge/isadora-nature-enhanced-cream-blush-32-soft-pink" },
  //       { id: 197, price:'575:-', discount:'50%', title: "Armani", description: "Designer Cream Foundation 4", imgPro: kicks5, url: "https://www.kicks.se/armani/makeup/bas/foundation/armani-designer-cream-foundation-4" },
  //       { id: 198, price:'347:-', discount:'50%', title: "PÜR", description: "Best Sellers Kit Golden Medium", imgPro: kicks6, url: "https://www.kicks.se/pur/makeup/presenttips-presentaskar/pur-best-sellers-kit-golden-medium" },
  //     ],
  //     image: bangerheadLogga,
  //   },
  //   {
  //     name: "Cocopanda",
  //     row: [
  //       { id: 199, price:'169:-', discount:'50%', title: "Lumene", description: "Nordic-C Glow Renew Night Cream 50 ml", imgPro: kicks1, url: "https://www.kicks.se/lumene/hudvard/ansiktsvard/ansiktskram/nattkram/lumene-nordic-c-glow-renew-night-cream-50-ml" },
  //       { id: 200, price:'74:-', discount:'50%', title: "Isadora", description: "Contour Stick'n Brush 36 Warm Mocha", imgPro: kicks2, url: "https://www.kicks.se/isadora/makeup/bas/highlighter/isadora-contour-stickn-brush-36-warm-mocha" },
  //       { id: 201, price:'139:-', discount:'50%', title: "KICKS Beauty", description: "Derma Roller", imgPro: kicks3, url: "https://www.kicks.se/kicks-beauty/hudvard/ansiktsvard/punktbehandlare/kicks-beauty-derma-roller" },
  //       { id: 202, price:'74:-', discount:'50%', title: "Isadora", description: "Nature Enhanced <br> Cream Blush 32 Soft Pink", imgPro: kicks4, url: "https://www.kicks.se/isadora/makeup/bas/rouge/isadora-nature-enhanced-cream-blush-32-soft-pink" },
  //       { id: 203, price:'575:-', discount:'50%', title: "Armani", description: "Designer Cream Foundation 4", imgPro: kicks5, url: "https://www.kicks.se/armani/makeup/bas/foundation/armani-designer-cream-foundation-4" },
  //       { id: 204, price:'347:-', discount:'50%', title: "PÜR", description: "Best Sellers Kit Golden Medium", imgPro: kicks6, url: "https://www.kicks.se/pur/makeup/presenttips-presentaskar/pur-best-sellers-kit-golden-medium" },
  //     ],
  //     image: cocopandaLogga,
  //   },
  //   {
  //     name: "Beautycos",
  //     row: [
  //       { id: 205, price:'169:-', discount:'50%', title: "Lumene", description: "Nordic-C Glow Renew Night Cream 50 ml", imgPro: kicks1, url: "https://www.kicks.se/lumene/hudvard/ansiktsvard/ansiktskram/nattkram/lumene-nordic-c-glow-renew-night-cream-50-ml" },
  //       { id: 206, price:'74:-', discount:'50%', title: "Isadora", description: "Contour Stick'n Brush 36 Warm Mocha", imgPro: kicks2, url: "https://www.kicks.se/isadora/makeup/bas/highlighter/isadora-contour-stickn-brush-36-warm-mocha" },
  //       { id: 207, price:'139:-', discount:'50%', title: "KICKS Beauty", description: "Derma Roller", imgPro: kicks3, url: "https://www.kicks.se/kicks-beauty/hudvard/ansiktsvard/punktbehandlare/kicks-beauty-derma-roller" },
  //       { id: 208, price:'74:-', discount:'50%', title: "Isadora", description: "Nature Enhanced <br> Cream Blush 32 Soft Pink", imgPro: kicks4, url: "https://www.kicks.se/isadora/makeup/bas/rouge/isadora-nature-enhanced-cream-blush-32-soft-pink" },
  //       { id: 209, price:'575:-', discount:'50%', title: "Armani", description: "Designer Cream Foundation 4", imgPro: kicks5, url: "https://www.kicks.se/armani/makeup/bas/foundation/armani-designer-cream-foundation-4" },
  //       { id: 210, price:'347:-', discount:'50%', title: "PÜR", description: "Best Sellers Kit Golden Medium", imgPro: kicks6, url: "https://www.kicks.se/pur/makeup/presenttips-presentaskar/pur-best-sellers-kit-golden-medium" },
  //     ],
  //     image: beautycosLogga,
  //   },
  //   {
  //     name: "Bodystore",
  //     row: [
  //       { id: 211, price:'399:-', discount:'-20%', title: "Balanced Minerals", description: "Balanced Minerals är ett mineraltillskott av<br> högsta kvalitet baserat på whole food och raw food.", imgPro: great1, url: "https://www.greatlife.se/balanced-minerals-mineralkomplex" },
  //       { id: 212, price:'449:-', discount:'-10%', title: "Blood Builder – järntillskott", description: "Blood Builder är ett kosttillskott baserat<br> på whole food och raw food som bidrar<br> till normal bildning av röda blodkroppar.", imgPro: great2, url: "https://www.greatlife.se/blood-builder?count=90_tabletter" },
  //       { id: 213, price:'699:-', discount:'-12%', title: "Blood Builder – järntillskott", description: "Blood Builder är ett kosttillskott baserat<br> på whole food och raw food som bidrar<br> till normal bildning av röda blodkroppar.", imgPro: great3, url: "https://www.greatlife.se/blood-builder?count=180_tabletter" },
  //       { id: 214, price:'219:-', discount:'-10%', title: "Copper Bisglycinate – Koppar", description: "Copper Bisglycinate Koppar från Thorne är ett<br> koppartillskott med hög biotillgänglighet.", imgPro: great4, url: "https://www.greatlife.se/copper-bisglycinate" },
  //       { id: 215, price:'279:-', discount:'-20%', title: "DiCalcium Malate – Kalcium", description: "DiCalcium Malate kalcium från Thorne bidrar till<br> att bibehålla normal benstomme.", imgPro: great5, url: "https://www.greatlife.se/dicalcium-malate" },
  //       { id: 216, price:'379:-', discount:'15%', title: "Elderberry Immune Support<br> Gummies", description: "Supergoda gummies som innehåller <br>ekologiska fläderbär, ekologiska vilda blåbär. ", imgPro: great6, url: "https://www.greatlife.se/gummy-elderberry-immune-support-fladerbar" },
  //     ],
  //     image: bodystoreLogga,
  //   },
  //   {
  //     name: "ComforthScandinavia",
  //     row: [
  //       { id: 217, price:'169:-', discount:'50%', title: "Lumene", description: "Nordic-C Glow Renew Night Cream 50 ml", imgPro: kicks1, url: "https://www.kicks.se/lumene/hudvard/ansiktsvard/ansiktskram/nattkram/lumene-nordic-c-glow-renew-night-cream-50-ml" },
  //       { id: 218, price:'74:-', discount:'50%', title: "Isadora", description: "Contour Stick'n Brush 36 Warm Mocha", imgPro: kicks2, url: "https://www.kicks.se/isadora/makeup/bas/highlighter/isadora-contour-stickn-brush-36-warm-mocha" },
  //       { id: 219, price:'139:-', discount:'50%', title: "KICKS Beauty", description: "Derma Roller", imgPro: kicks3, url: "https://www.kicks.se/kicks-beauty/hudvard/ansiktsvard/punktbehandlare/kicks-beauty-derma-roller" },
  //       { id: 220, price:'74:-', discount:'50%', title: "Isadora", description: "Nature Enhanced <br> Cream Blush 32 Soft Pink", imgPro: kicks4, url: "https://www.kicks.se/isadora/makeup/bas/rouge/isadora-nature-enhanced-cream-blush-32-soft-pink" },
  //       { id: 221, price:'575:-', discount:'50%', title: "Armani", description: "Designer Cream Foundation 4", imgPro: kicks5, url: "https://www.kicks.se/armani/makeup/bas/foundation/armani-designer-cream-foundation-4" },
  //       { id: 222, price:'347:-', discount:'50%', title: "PÜR", description: "Best Sellers Kit Golden Medium", imgPro: kicks6, url: "https://www.kicks.se/pur/makeup/presenttips-presentaskar/pur-best-sellers-kit-golden-medium" },
  //     ],
  //     image: comforthscandinaviaLogga,
  //   },
  //   {
  //     name: "Glowid",
  //     row: [
  //       { id: 235, price:'169:-', discount:'50%', title: "Lumene", description: "Nordic-C Glow Renew Night Cream 50 ml", imgPro: kicks1, url: "https://www.kicks.se/lumene/hudvard/ansiktsvard/ansiktskram/nattkram/lumene-nordic-c-glow-renew-night-cream-50-ml" },
  //       { id: 236, price:'74:-', discount:'50%', title: "Isadora", description: "Contour Stick'n Brush 36 Warm Mocha", imgPro: kicks2, url: "https://www.kicks.se/isadora/makeup/bas/highlighter/isadora-contour-stickn-brush-36-warm-mocha" },
  //       { id: 237, price:'139:-', discount:'50%', title: "KICKS Beauty", description: "Derma Roller", imgPro: kicks3, url: "https://www.kicks.se/kicks-beauty/hudvard/ansiktsvard/punktbehandlare/kicks-beauty-derma-roller" },
  //       { id: 238, price:'74:-', discount:'50%', title: "Isadora", description: "Nature Enhanced <br> Cream Blush 32 Soft Pink", imgPro: kicks4, url: "https://www.kicks.se/isadora/makeup/bas/rouge/isadora-nature-enhanced-cream-blush-32-soft-pink" },
  //       { id: 239, price:'575:-', discount:'50%', title: "Armani", description: "Designer Cream Foundation 4", imgPro: kicks5, url: "https://www.kicks.se/armani/makeup/bas/foundation/armani-designer-cream-foundation-4" },
  //       { id: 240, price:'347:-', discount:'50%', title: "PÜR", description: "Best Sellers Kit Golden Medium", imgPro: kicks6, url: "https://www.kicks.se/pur/makeup/presenttips-presentaskar/pur-best-sellers-kit-golden-medium" },
  //     ],
  //     image: glowidLogga,
  //   },
  // {
  //   name: "Kicks",
  //   row: [
  //     { id: 259, price:'169:-', discount:'50%', title: "Lumene", description: "Nordic-C Glow Renew Night Cream 50 ml", imgPro: kicks1, url: "https://www.kicks.se/lumene/hudvard/ansiktsvard/ansiktskram/nattkram/lumene-nordic-c-glow-renew-night-cream-50-ml" },
  //     { id: 260, price:'74:-', discount:'50%', title: "Isadora", description: "Contour Stick'n Brush 36 Warm Mocha", imgPro: kicks2, url: "https://www.kicks.se/isadora/makeup/bas/highlighter/isadora-contour-stickn-brush-36-warm-mocha" },
  //     { id: 261, price:'139:-', discount:'50%', title: "KICKS Beauty", description: "Derma Roller", imgPro: kicks3, url: "https://www.kicks.se/kicks-beauty/hudvard/ansiktsvard/punktbehandlare/kicks-beauty-derma-roller" },
  //     { id: 262, price:'74:-', discount:'50%', title: "Isadora", description: "Nature Enhanced <br> Cream Blush 32 Soft Pink", imgPro: kicks4, url: "https://www.kicks.se/isadora/makeup/bas/rouge/isadora-nature-enhanced-cream-blush-32-soft-pink" },
  //     { id: 263, price:'575:-', discount:'50%', title: "Armani", description: "Designer Cream Foundation 4", imgPro: kicks5, url: "https://www.kicks.se/armani/makeup/bas/foundation/armani-designer-cream-foundation-4" },
  //     { id: 264, price:'347:-', discount:'50%', title: "PÜR", description: "Best Sellers Kit Golden Medium", imgPro: kicks6, url: "https://www.kicks.se/pur/makeup/presenttips-presentaskar/pur-best-sellers-kit-golden-medium" },
  //   ],
  //   image: kicksLogga,
  // },
  // {
  //   name: "LhCosmetics",
  //   row: [
  //     { id: 265, price:'169:-', discount:'50%', title: "Lumene", description: "Nordic-C Glow Renew Night Cream 50 ml", imgPro: kicks1, url: "https://www.kicks.se/lumene/hudvard/ansiktsvard/ansiktskram/nattkram/lumene-nordic-c-glow-renew-night-cream-50-ml" },
  //     { id: 266, price:'74:-', discount:'50%', title: "Isadora", description: "Contour Stick'n Brush 36 Warm Mocha", imgPro: kicks2, url: "https://www.kicks.se/isadora/makeup/bas/highlighter/isadora-contour-stickn-brush-36-warm-mocha" },
  //     { id: 267, price:'139:-', discount:'50%', title: "KICKS Beauty", description: "Derma Roller", imgPro: kicks3, url: "https://www.kicks.se/kicks-beauty/hudvard/ansiktsvard/punktbehandlare/kicks-beauty-derma-roller" },
  //     { id: 268, price:'74:-', discount:'50%', title: "Isadora", description: "Nature Enhanced <br> Cream Blush 32 Soft Pink", imgPro: kicks4, url: "https://www.kicks.se/isadora/makeup/bas/rouge/isadora-nature-enhanced-cream-blush-32-soft-pink" },
  //     { id: 269, price:'575:-', discount:'50%', title: "Armani", description: "Designer Cream Foundation 4", imgPro: kicks5, url: "https://www.kicks.se/armani/makeup/bas/foundation/armani-designer-cream-foundation-4" },
  //     { id: 270, price:'347:-', discount:'50%', title: "PÜR", description: "Best Sellers Kit Golden Medium", imgPro: kicks6, url: "https://www.kicks.se/pur/makeup/presenttips-presentaskar/pur-best-sellers-kit-golden-medium" },
  //   ],
  //   image: lhcosmeticsLogga,
  // },
  // {
  //   name: "Life",
  //   row: [
  //     { id: 271, price:'399:-', discount:'-20%', title: "Balanced Minerals", description: "Balanced Minerals är ett mineraltillskott av<br> högsta kvalitet baserat på whole food och raw food.", imgPro: great1, url: "https://www.greatlife.se/balanced-minerals-mineralkomplex" },
  //     { id: 272, price:'449:-', discount:'-10%', title: "Blood Builder – järntillskott", description: "Blood Builder är ett kosttillskott baserat<br> på whole food och raw food som bidrar<br> till normal bildning av röda blodkroppar.", imgPro: great2, url: "https://www.greatlife.se/blood-builder?count=90_tabletter" },
  //     { id: 273, price:'699:-', discount:'-12%', title: "Blood Builder – järntillskott", description: "Blood Builder är ett kosttillskott baserat<br> på whole food och raw food som bidrar<br> till normal bildning av röda blodkroppar.", imgPro: great3, url: "https://www.greatlife.se/blood-builder?count=180_tabletter" },
  //     { id: 274, price:'219:-', discount:'-10%', title: "Copper Bisglycinate – Koppar", description: "Copper Bisglycinate Koppar från Thorne är ett<br> koppartillskott med hög biotillgänglighet.", imgPro: great4, url: "https://www.greatlife.se/copper-bisglycinate" },
  //     { id: 275, price:'279:-', discount:'-20%', title: "DiCalcium Malate – Kalcium", description: "DiCalcium Malate kalcium från Thorne bidrar till<br> att bibehålla normal benstomme.", imgPro: great5, url: "https://www.greatlife.se/dicalcium-malate" },
  //     { id: 276, price:'379:-', discount:'15%', title: "Elderberry Immune Support<br> Gummies", description: "Supergoda gummies som innehåller <br>ekologiska fläderbär, ekologiska vilda blåbär. ", imgPro: great6, url: "https://www.greatlife.se/gummy-elderberry-immune-support-fladerbar" },
  //   ],
  //   image: lifeLogga,
  // },
  // {
  //   name: "Maxulin",
  //   row: [
  //     { id: 277, price:'', discount:'', title: "Offer 4", description: "Description of Offer 4", imgPro: tv, url: "" },
  //     { id: 278, price:'', discount:'', title: "Offer 5", description: "Description of Offer 5", imgPro: tv, url: "" },
  //     { id: 279, price:'', discount:'', title: "Offer 6", description: "Description of Offer 6", imgPro: tv, url: "" },
  //     { id: 280, price:'', discount:'', title: "Offer 7", description: "Description of Offer 7", imgPro: tv, url: "" },
  //     { id: 281, price:'', discount:'', title: "Offer 8", description: "Description of Offer 8", imgPro: tv, url: "" },
  //     { id: 282, price:'', discount:'', title: "Offer 9", description: "Description of Offer 9", imgPro: tv, url: "" },
  //   ],
  //   image: maxulinLogga,
  // },
  // {
  //   name: "Osloskin",
  //   row: [
  //     { id: 295, price:'169:-', discount:'50%', title: "Lumene", description: "Nordic-C Glow Renew Night Cream 50 ml", imgPro: kicks1, url: "https://www.kicks.se/lumene/hudvard/ansiktsvard/ansiktskram/nattkram/lumene-nordic-c-glow-renew-night-cream-50-ml" },
  //     { id: 296, price:'74:-', discount:'50%', title: "Isadora", description: "Contour Stick'n Brush 36 Warm Mocha", imgPro: kicks2, url: "https://www.kicks.se/isadora/makeup/bas/highlighter/isadora-contour-stickn-brush-36-warm-mocha" },
  //     { id: 297, price:'139:-', discount:'50%', title: "KICKS Beauty", description: "Derma Roller", imgPro: kicks3, url: "https://www.kicks.se/kicks-beauty/hudvard/ansiktsvard/punktbehandlare/kicks-beauty-derma-roller" },
  //     { id: 298, price:'74:-', discount:'50%', title: "Isadora", description: "Nature Enhanced <br> Cream Blush 32 Soft Pink", imgPro: kicks4, url: "https://www.kicks.se/isadora/makeup/bas/rouge/isadora-nature-enhanced-cream-blush-32-soft-pink" },
  //     { id: 299, price:'575:-', discount:'50%', title: "Armani", description: "Designer Cream Foundation 4", imgPro: kicks5, url: "https://www.kicks.se/armani/makeup/bas/foundation/armani-designer-cream-foundation-4" },
  //     { id: 300, price:'347:-', discount:'50%', title: "PÜR", description: "Best Sellers Kit Golden Medium", imgPro: kicks6, url: "https://www.kicks.se/pur/makeup/presenttips-presentaskar/pur-best-sellers-kit-golden-medium" },
  //   ],
  //   image: osloskin,
  // },
  // {
  //   name: "Rapunzelofsweden",
  //   row: [
  //     { id: 301, price:'169:-', discount:'50%', title: "Lumene", description: "Nordic-C Glow Renew Night Cream 50 ml", imgPro: kicks1, url: "https://www.kicks.se/lumene/hudvard/ansiktsvard/ansiktskram/nattkram/lumene-nordic-c-glow-renew-night-cream-50-ml" },
  //     { id: 302, price:'74:-', discount:'50%', title: "Isadora", description: "Contour Stick'n Brush 36 Warm Mocha", imgPro: kicks2, url: "https://www.kicks.se/isadora/makeup/bas/highlighter/isadora-contour-stickn-brush-36-warm-mocha" },
  //     { id: 303, price:'139:-', discount:'50%', title: "KICKS Beauty", description: "Derma Roller", imgPro: kicks3, url: "https://www.kicks.se/kicks-beauty/hudvard/ansiktsvard/punktbehandlare/kicks-beauty-derma-roller" },
  //     { id: 304, price:'74:-', discount:'50%', title: "Isadora", description: "Nature Enhanced <br> Cream Blush 32 Soft Pink", imgPro: kicks4, url: "https://www.kicks.se/isadora/makeup/bas/rouge/isadora-nature-enhanced-cream-blush-32-soft-pink" },
  //     { id: 305, price:'575:-', discount:'50%', title: "Armani", description: "Designer Cream Foundation 4", imgPro: kicks5, url: "https://www.kicks.se/armani/makeup/bas/foundation/armani-designer-cream-foundation-4" },
  //     { id: 306, price:'347:-', discount:'50%', title: "PÜR", description: "Best Sellers Kit Golden Medium", imgPro: kicks6, url: "https://www.kicks.se/pur/makeup/presenttips-presentaskar/pur-best-sellers-kit-golden-medium" },
  //   ],
  //   image: rapunzelofswedenLogga,
  // }, //Koden fungerar felfritt hit med.

  // {
  //   name: "Skincity",
  //   row: [
  //     { id: 307, price:'169:-', discount:'50%', title: "Lumene", description: "Nordic-C Glow Renew Night Cream 50 ml", imgPro: kicks1, url: "https://www.kicks.se/lumene/hudvard/ansiktsvard/ansiktskram/nattkram/lumene-nordic-c-glow-renew-night-cream-50-ml" },
  //     { id: 308, price:'74:-', discount:'50%', title: "Isadora", description: "Contour Stick'n Brush 36 Warm Mocha", imgPro: kicks2, url: "https://www.kicks.se/isadora/makeup/bas/highlighter/isadora-contour-stickn-brush-36-warm-mocha" },
  //     { id: 309, price:'139:-', discount:'50%', title: "KICKS Beauty", description: "Derma Roller", imgPro: kicks3, url: "https://www.kicks.se/kicks-beauty/hudvard/ansiktsvard/punktbehandlare/kicks-beauty-derma-roller" },
  //     { id: 310, price:'74:-', discount:'50%', title: "Isadora", description: "Nature Enhanced <br> Cream Blush 32 Soft Pink", imgPro: kicks4, url: "https://www.kicks.se/isadora/makeup/bas/rouge/isadora-nature-enhanced-cream-blush-32-soft-pink" },
  //     { id: 311, price:'575:-', discount:'50%', title: "Armani", description: "Designer Cream Foundation 4", imgPro: kicks5, url: "https://www.kicks.se/armani/makeup/bas/foundation/armani-designer-cream-foundation-4" },
  //     { id: 312, price:'347:-', discount:'50%', title: "PÜR", description: "Best Sellers Kit Golden Medium", imgPro: kicks6, url: "https://www.kicks.se/pur/makeup/presenttips-presentaskar/pur-best-sellers-kit-golden-medium" },
  //   ],
  //   image: skincityLogga,
  // },
  // {
  //   name: "Staybeautiful",
  //   row: [
  //     { id: 313, price:'169:-', discount:'50%', title: "Lumene", description: "Nordic-C Glow Renew Night Cream 50 ml", imgPro: kicks1, url: "https://www.kicks.se/lumene/hudvard/ansiktsvard/ansiktskram/nattkram/lumene-nordic-c-glow-renew-night-cream-50-ml" },
  //     { id: 314, price:'74:-', discount:'50%', title: "Isadora", description: "Contour Stick'n Brush 36 Warm Mocha", imgPro: kicks2, url: "https://www.kicks.se/isadora/makeup/bas/highlighter/isadora-contour-stickn-brush-36-warm-mocha" },
  //     { id: 315, price:'139:-', discount:'50%', title: "KICKS Beauty", description: "Derma Roller", imgPro: kicks3, url: "https://www.kicks.se/kicks-beauty/hudvard/ansiktsvard/punktbehandlare/kicks-beauty-derma-roller" },
  //     { id: 316, price:'74:-', discount:'50%', title: "Isadora", description: "Nature Enhanced <br> Cream Blush 32 Soft Pink", imgPro: kicks4, url: "https://www.kicks.se/isadora/makeup/bas/rouge/isadora-nature-enhanced-cream-blush-32-soft-pink" },
  //     { id: 317, price:'575:-', discount:'50%', title: "Armani", description: "Designer Cream Foundation 4", imgPro: kicks5, url: "https://www.kicks.se/armani/makeup/bas/foundation/armani-designer-cream-foundation-4" },
  //     { id: 318, price:'347:-', discount:'50%', title: "PÜR", description: "Best Sellers Kit Golden Medium", imgPro: kicks6, url: "https://www.kicks.se/pur/makeup/presenttips-presentaskar/pur-best-sellers-kit-golden-medium" },
  //   ],
  //   image: staybeautifulLogga,
  // },
  // {
  //   name: "Synoptik",
  //   row: [
  //     { id: 319, price:'', discount:'', title: "Offer 4", description: "Description of Offer 4", imgPro: tv, url: "" },
  //     { id: 320, price:'', discount:'', title: "Offer 5", description: "Description of Offer 5", imgPro: tv, url: "" },
  //     { id: 321, price:'', discount:'', title: "Offer 6", description: "Description of Offer 6", imgPro: tv, url: "" },
  //     { id: 322, price:'', discount:'', title: "Offer 7", description: "Description of Offer 7", imgPro: tv, url: "" },
  //     { id: 323, price:'', discount:'', title: "Offer 8", description: "Description of Offer 8", imgPro: tv, url: "" },
  //     { id: 324, price:'', discount:'', title: "Offer 9", description: "Description of Offer 9", imgPro: tv, url: "" },
  //   ],
  //   image: synoptikLogga,
  // },
  // {
  //   name: "Xlash",
  //   row: [
  //     { id: 343, price:'169:-', discount:'50%', title: "Lumene", description: "Nordic-C Glow Renew Night Cream 50 ml", imgPro: kicks1, url: "https://www.kicks.se/lumene/hudvard/ansiktsvard/ansiktskram/nattkram/lumene-nordic-c-glow-renew-night-cream-50-ml" },
  //     { id: 344, price:'74:-', discount:'50%', title: "Isadora", description: "Contour Stick'n Brush 36 Warm Mocha", imgPro: kicks2, url: "https://www.kicks.se/isadora/makeup/bas/highlighter/isadora-contour-stickn-brush-36-warm-mocha" },
  //     { id: 345, price:'139:-', discount:'50%', title: "KICKS Beauty", description: "Derma Roller", imgPro: kicks3, url: "https://www.kicks.se/kicks-beauty/hudvard/ansiktsvard/punktbehandlare/kicks-beauty-derma-roller" },
  //     { id: 346, price:'74:-', discount:'50%', title: "Isadora", description: "Nature Enhanced <br> Cream Blush 32 Soft Pink", imgPro: kicks4, url: "https://www.kicks.se/isadora/makeup/bas/rouge/isadora-nature-enhanced-cream-blush-32-soft-pink" },
  //     { id: 347, price:'575:-', discount:'50%', title: "Armani", description: "Designer Cream Foundation 4", imgPro: kicks5, url: "https://www.kicks.se/armani/makeup/bas/foundation/armani-designer-cream-foundation-4" },
  //     { id: 348, price:'347:-', discount:'50%', title: "PÜR", description: "Best Sellers Kit Golden Medium", imgPro: kicks6, url: "https://www.kicks.se/pur/makeup/presenttips-presentaskar/pur-best-sellers-kit-golden-medium" },
  //   ],
  //   image: xlashLogga,
  // },
  // {
  //   name: "You",
  //   row: [
  //     { id: 349, price:'169:-', discount:'50%', title: "Lumene", description: "Nordic-C Glow Renew Night Cream 50 ml", imgPro: kicks1, url: "https://www.kicks.se/lumene/hudvard/ansiktsvard/ansiktskram/nattkram/lumene-nordic-c-glow-renew-night-cream-50-ml" },
  //     { id: 350, price:'74:-', discount:'50%', title: "Isadora", description: "Contour Stick'n Brush 36 Warm Mocha", imgPro: kicks2, url: "https://www.kicks.se/isadora/makeup/bas/highlighter/isadora-contour-stickn-brush-36-warm-mocha" },
  //     { id: 351, price:'139:-', discount:'50%', title: "KICKS Beauty", description: "Derma Roller", imgPro: kicks3, url: "https://www.kicks.se/kicks-beauty/hudvard/ansiktsvard/punktbehandlare/kicks-beauty-derma-roller" },
  //     { id: 352, price:'74:-', discount:'50%', title: "Isadora", description: "Nature Enhanced <br> Cream Blush 32 Soft Pink", imgPro: kicks4, url: "https://www.kicks.se/isadora/makeup/bas/rouge/isadora-nature-enhanced-cream-blush-32-soft-pink" },
  //     { id: 353, price:'575:-', discount:'50%', title: "Armani", description: "Designer Cream Foundation 4", imgPro: kicks5, url: "https://www.kicks.se/armani/makeup/bas/foundation/armani-designer-cream-foundation-4" },
  //     { id: 354, price:'347:-', discount:'50%', title: "PÜR", description: "Best Sellers Kit Golden Medium", imgPro: kicks6, url: "https://www.kicks.se/pur/makeup/presenttips-presentaskar/pur-best-sellers-kit-golden-medium" },
  //   ],
  //   image: youLogga,
  // },
 // {
  //   name: "Mekonomen",
  //   row: [
  //     { id: 397, price:'610:-', discount:'-20%', title: "DYNAMO STREET-H MH01", description: "Sommardäck", imgPro: däck1, url: "https://www.dackskiftarna.se/dack/sommardack/dynamo-street-h-mh01-6922250412690-2021" },
  //     { id: 398, price:'525:-', discount:'-10%', title: "NOKIAN HAKKA GREEN 2", description: "Sommardäck", imgPro: däck2, url: "https://www.dackskiftarna.se/dack/sommardack/nokian-hakka-green-2-6419440173573-2018" },
  //     { id: 399, price:'870:-', discount:'-15%', title: "APOLLO APTERRA H/T 2 XL", description: "Sommardäck", imgPro: däck3, url: "https://www.dackskiftarna.se/dack/apollo-apterra-h-t-2-xl-8904156099531-2017" },
  //     { id: 400, price:'366:-', discount:'-20%', title: "BRIDGESTONE TURANZA ER300", description: "Sommardäck", imgPro: däck4, url: "https://www.dackskiftarna.se/dack/sommardack/bridgestone-turanza-er300-3286340777513-2017" },
  //     { id: 401, price:'475:-', discount:'-50%', title: "KUMHO ECOWING ES31", description: "Sommardäck", imgPro: däck5, url: "https://www.dackskiftarna.se/dack/sommardack/kumho-ecowing-es31-8808956238193-2021" },
  //     { id: 402, price:'2 333:-', discount:'-20%', title: "BRIDGESTONE POTENZA SPORT XL", description: "Sommardäck", imgPro: däck6, url: "https://www.dackskiftarna.se/dack/sommardack/bridgestone-potenza-sport-xl-3286342155814-2021" },
  //   ],
  //   image: mekonomenLogga,
  // },
  // {
  //   name: "Beijer",
  //   row: [
  //     { id: 433, price:'224:-', discount:'-28%', title: "TRÄSKYDD SIOO:X ALTAN<br> PREMIUM", description: "Premium Träskydd Altan i kombination med<br> Premium Ytskydd Altan", imgPro: bygg1, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/oljor-traskydd/traskydd/traskydd-sioo-x-altan-premium-333505742226" },
  //     { id: 434, price:'392:-', discount:'-21%', title: "FASADFÄRG ONE SUPER TECH", description: "One Super Tech från Nordsjö är en <br>självrengörande akrylattäckfärg avsedd för<br> målning på träfasader.", imgPro: bygg2, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/utomhusfarg/fasadfarg/fasadfarg-one-super-tech" },
  //     { id: 435, price:'135:-', discount:'-10%', title: "SKARVSLADD H05VV-F 3G1.5 VIT", description: "Skarvsladd (ej hängbar frp)", imgPro: bygg3, url: "https://www.beijerbygg.se/privat/sv/produkter/elinstallation/skarvsladd-kabelvindor/skarvsladd/skarvsladd-h05vv-f-3g1-5-vit" },
  //     { id: 436, price:'2 640:-', discount:'-12%', title: "NO TINOVA VX+ 2 IN 1 BW 10 L", description: "Träfasadfärg utomhus, vattenburen grund-<br> och täckfärg BW", imgPro: bygg4, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/utomhusfarg/fasadfarg/no-tinova-vx-2-in-1-bw-10-l" },
  //     { id: 437, price:'241:-', discount:'-18%', title: "GRUNDOLJA TINOVA WOOD BASE <br>1L OILBPR NORDSJÖ UTOMHUS", description: "Tinova Wood Base Oil BPR", imgPro: bygg5, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/oljor-traskydd/traskydd/grundolja-tinova-wood-base-1l-oilbpr-nordsjo-utomhus-900235060" },
  //     { id: 438, price:'571:-', discount:'-18%', title: "GRUNDOLJA TINOVA WOOD BASE<br> 2,5 OILBPR NORDSJÖ UTOMHUS<br> 2,5L", description: "Tinova Wood Base Oil BPR", imgPro: bygg6, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/oljor-traskydd/traskydd/grundolja-tinova-wood-base-2-5-oilbpr-nordsjo-utomhus-2-5l-900235061" },
  //   ],
  //   image: beijerLogga,
  // },
  // {
  //   name: "Proffsmagasinet",
  //   row: [
  //     { id: 445, price:'224:-', discount:'-28%', title: "TRÄSKYDD SIOO:X ALTAN<br> PREMIUM", description: "Premium Träskydd Altan i kombination med<br> Premium Ytskydd Altan", imgPro: bygg1, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/oljor-traskydd/traskydd/traskydd-sioo-x-altan-premium-333505742226" },
  //     { id: 446, price:'392:-', discount:'-21%', title: "FASADFÄRG ONE SUPER TECH", description: "One Super Tech från Nordsjö är en <br>självrengörande akrylattäckfärg avsedd för<br> målning på träfasader.", imgPro: bygg2, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/utomhusfarg/fasadfarg/fasadfarg-one-super-tech" },
  //     { id: 447, price:'135:-', discount:'-10%', title: "SKARVSLADD H05VV-F 3G1.5 VIT", description: "Skarvsladd (ej hängbar frp)", imgPro: bygg3, url: "https://www.beijerbygg.se/privat/sv/produkter/elinstallation/skarvsladd-kabelvindor/skarvsladd/skarvsladd-h05vv-f-3g1-5-vit" },
  //     { id: 448, price:'2 640:-', discount:'-12%', title: "NO TINOVA VX+ 2 IN 1 BW 10 L", description: "Träfasadfärg utomhus, vattenburen grund-<br> och täckfärg BW", imgPro: bygg4, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/utomhusfarg/fasadfarg/no-tinova-vx-2-in-1-bw-10-l" },
  //     { id: 449, price:'241:-', discount:'-18%', title: "GRUNDOLJA TINOVA WOOD BASE <br>1L OILBPR NORDSJÖ UTOMHUS", description: "Tinova Wood Base Oil BPR", imgPro: bygg5, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/oljor-traskydd/traskydd/grundolja-tinova-wood-base-1l-oilbpr-nordsjo-utomhus-900235060" },
  //     { id: 450, price:'571:-', discount:'-18%', title: "GRUNDOLJA TINOVA WOOD BASE<br> 2,5 OILBPR NORDSJÖ UTOMHUS<br> 2,5L", description: "Tinova Wood Base Oil BPR", imgPro: bygg6, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/oljor-traskydd/traskydd/grundolja-tinova-wood-base-2-5-oilbpr-nordsjo-utomhus-2-5l-900235061" },
  //   ],
  //   image: proffsmagasinetLogga,
  // },
  // {
  //   name: "Dustin",
  //   row: [
  //     { id: 475, price:'3.211:-', discount:'-8%', title: "LG UltraGear 27GP850P-B -<br> Gaming Series", description: "LG UltraGear, supremely powerful gear,<br> boosts your chances of victory.", imgPro: comp1, url: "https://www.computersalg.se/i/9740043/lg-ultragear-27gp850p-b-gaming-series-led-sk%c3%a4rm-spel-27-2560-x-1440-qhd-165-hz-nano-ips-400-cd-m-1000-1-displayhdr-400-1-ms" },
  //     { id: 476, price:'1.097:-', discount:'-12%', title: "Logitech® | MX Keys S", description: "Högpresterande tangentbord, konstruerade<br> för bekvämt, snabbt och med smart<br> belysning och programmerbara tangenter.", imgPro: comp2, url: "https://www.computersalg.se/i/10308608/logitech-mx-keys-s-tastatur-bagbelyst-bluetooth-2-4-ghz-nordisk-layout-grafit" },
  //     { id: 477, price:'1.130:-', discount:'-10%', title: "Logitech MX Master 3S ", description: "Här är Logitech MX Master 3S - en ikonisk<br> mus i en omarbetad version för ultimat<br> känslighet, prestanda och flöde.", imgPro: comp3, url: "https://www.computersalg.se/i/8337611/logitech-mx-master-3s-mus-laser-7-knappar-tr%c3%a5dl%c3%b6s-bluetooth-2-4-ghz-logitech-bolt-mottagare-grafit" },
  //     { id: 478, price:'798:-', discount:'-15%', title: "Logitech HD Pro Webcam C920S", description: "C920s Pro HD-webbkamera ger otroligt skarpa,<br> tydliga bilder och detaljerade bilder<br> i naturliga färger.", imgPro: comp4, url: "https://www.computersalg.se/i/5035505/logitech-hd-pro-webcam-c920s-webbkamera-f%c3%a4rg-1920-x-1080-ljud-usb" },
  //     { id: 479, price:'4.197:-', discount:'-12%', title: "Apple 10.2-inch iPad Wi-Fi - 9:e generation", description: "Kraftfull. Lätt att använda. Allsidig.<br> Nya iPad är utvecklad för allt du gillar<br> att göra.", imgPro: comp5, url: "https://www.computersalg.se/i/7653837/apple-10-2-inch-ipad-wi-fi-9-e-generation-surfplatta-64-gb-10-2-ips-2160-x-1620-rymdgr%c3%a5" },
  //     { id: 480, price:' 727:-', discount:'-20%', title: "Sony | DualSense™ - Gamepad ", description: "Upptäck en djupare, mycket uppslukande <br> spelupplevelse som väcker action<br> till liv i dina händer.", imgPro: comp6, url: "https://www.computersalg.se/i/7477115/sony-dualsense-gamepad-tr%c3%a5dl%c3%b6s-bluetooth-f%c3%b6r-sony-playstation-5-vit" },
  //   ],
  //   image: dustinLogga,
  // },
  // {
  //   name: "Kjell&Co",
  //   row: [
  //     { id: 481, price:'224:-', discount:'-28%', title: "TRÄSKYDD SIOO:X ALTAN<br> PREMIUM", description: "Premium Träskydd Altan i kombination med<br> Premium Ytskydd Altan", imgPro: bygg1, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/oljor-traskydd/traskydd/traskydd-sioo-x-altan-premium-333505742226" },
  //     { id: 482, price:'392:-', discount:'-21%', title: "FASADFÄRG ONE SUPER TECH", description: "One Super Tech från Nordsjö är en <br>självrengörande akrylattäckfärg avsedd för<br> målning på träfasader.", imgPro: bygg2, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/utomhusfarg/fasadfarg/fasadfarg-one-super-tech" },
  //     { id: 483, price:'135:-', discount:'-10%', title: "SKARVSLADD H05VV-F 3G1.5 VIT", description: "Skarvsladd (ej hängbar frp)", imgPro: bygg3, url: "https://www.beijerbygg.se/privat/sv/produkter/elinstallation/skarvsladd-kabelvindor/skarvsladd/skarvsladd-h05vv-f-3g1-5-vit" },
  //     { id: 484, price:'2 640:-', discount:'-12%', title: "NO TINOVA VX+ 2 IN 1 BW 10 L", description: "Träfasadfärg utomhus, vattenburen grund-<br> och täckfärg BW", imgPro: bygg4, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/utomhusfarg/fasadfarg/no-tinova-vx-2-in-1-bw-10-l" },
  //     { id: 485, price:'241:-', discount:'-18%', title: "GRUNDOLJA TINOVA WOOD BASE <br>1L OILBPR NORDSJÖ UTOMHUS", description: "Tinova Wood Base Oil BPR", imgPro: bygg5, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/oljor-traskydd/traskydd/grundolja-tinova-wood-base-1l-oilbpr-nordsjo-utomhus-900235060" },
  //     { id: 486, price:'571:-', discount:'-18%', title: "GRUNDOLJA TINOVA WOOD BASE<br> 2,5 OILBPR NORDSJÖ UTOMHUS<br> 2,5L", description: "Tinova Wood Base Oil BPR", imgPro: bygg6, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/oljor-traskydd/traskydd/grundolja-tinova-wood-base-2-5-oilbpr-nordsjo-utomhus-2-5l-900235061" },
  //   ],
  //   image: kjellLogga,
  // },
  // {
  //   name: "Inkclub",
  //   row: [
  //     { id: 487, price:'224:-', discount:'-28%', title: "TRÄSKYDD SIOO:X ALTAN<br> PREMIUM", description: "Premium Träskydd Altan i kombination med<br> Premium Ytskydd Altan", imgPro: bygg1, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/oljor-traskydd/traskydd/traskydd-sioo-x-altan-premium-333505742226" },
  //     { id: 488, price:'392:-', discount:'-21%', title: "FASADFÄRG ONE SUPER TECH", description: "One Super Tech från Nordsjö är en <br>självrengörande akrylattäckfärg avsedd för<br> målning på träfasader.", imgPro: bygg2, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/utomhusfarg/fasadfarg/fasadfarg-one-super-tech" },
  //     { id: 489, price:'135:-', discount:'-10%', title: "SKARVSLADD H05VV-F 3G1.5 VIT", description: "Skarvsladd (ej hängbar frp)", imgPro: bygg3, url: "https://www.beijerbygg.se/privat/sv/produkter/elinstallation/skarvsladd-kabelvindor/skarvsladd/skarvsladd-h05vv-f-3g1-5-vit" },
  //     { id: 490, price:'2 640:-', discount:'-12%', title: "NO TINOVA VX+ 2 IN 1 BW 10 L", description: "Träfasadfärg utomhus, vattenburen grund-<br> och täckfärg BW", imgPro: bygg4, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/utomhusfarg/fasadfarg/no-tinova-vx-2-in-1-bw-10-l" },
  //     { id: 491, price:'241:-', discount:'-18%', title: "GRUNDOLJA TINOVA WOOD BASE <br>1L OILBPR NORDSJÖ UTOMHUS", description: "Tinova Wood Base Oil BPR", imgPro: bygg5, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/oljor-traskydd/traskydd/grundolja-tinova-wood-base-1l-oilbpr-nordsjo-utomhus-900235060" },
  //     { id: 492, price:'571:-', discount:'-18%', title: "GRUNDOLJA TINOVA WOOD BASE<br> 2,5 OILBPR NORDSJÖ UTOMHUS<br> 2,5L", description: "Tinova Wood Base Oil BPR", imgPro: bygg6, url: "https://www.beijerbygg.se/privat/sv/produkter/farg/oljor-traskydd/traskydd/grundolja-tinova-wood-base-2-5-oilbpr-nordsjo-utomhus-2-5l-900235061" },
  //   ],
  //   image: inkclubLogga,
  // },
  // {
  //   name: "Klockia",
  //   row: [
  //     { id: 523, price:'16 999:-', discount:'-18%', title: "Ring med labbodlade diamanter", description: "Ring i återvunnet 18K gult guld med en oval<br> IGI-certifierad labbodlad diamant 0.50 ct.", imgPro: guld1, url: "https://www.albrektsguld.se/article/ring_med_labbodlade_diamanter_20152016" },
  //     { id: 524, price:'349:-', discount:'-10%', title: "Klocka med dekorativ länk", description: "Guldfärgad klocka med dekorativ länk<br> med vita kristaller.", imgPro: guld2, url: "https://www.albrektsguld.se/article/klocka_med_dekorativ_lank" },
  //     { id: 525, price:'7 999:-', discount:'-15%', title: "Ring med labbodlade diamanter", description: "Ring i återvunnet 18K vitt guld med 18<br>  labbodlade diamanter totalt 0.14 ct TWVS.", imgPro: guld3, url: "https://www.albrektsguld.se/article/ring_med_labbodlade_diamanter_20151891" },
  //     { id: 526, price:'199:-', discount:'-10%', title: "Halsband i stål", description: "Halsband i guldpläterat stål i längden<br> 45 + 8cm.", imgPro: guld4, url: "https://www.albrektsguld.se/article/halsband_i_stal_20152503" },
  //     { id: 527, price:'10 999:-', discount:'-8%', title: "Ring i 18K guld", description: "Klackring i 18K guld", imgPro: guld5, url: "https://www.albrektsguld.se/article/ring_i_18k_guld_20117510" },
  //     { id: 528, price:'449:-', discount:'-12%', title: "Herrklocka", description: "Klocka i svartfärgad metall", imgPro: guld6, url: "https://www.albrektsguld.se/article/herrklocka_20023416" },
  //   ],
  //   image: klockiaLogga,
  // },
  // {
  //   name: "Urochpenn",
  //   row: [
  //     { id: 535, price:'16 999:-', discount:'-18%', title: "Ring med labbodlade diamanter", description: "Ring i återvunnet 18K gult guld med en oval<br> IGI-certifierad labbodlad diamant 0.50 ct.", imgPro: guld1, url: "https://www.albrektsguld.se/article/ring_med_labbodlade_diamanter_20152016" },
  //     { id: 536, price:'349:-', discount:'-10%', title: "Klocka med dekorativ länk", description: "Guldfärgad klocka med dekorativ länk<br> med vita kristaller.", imgPro: guld2, url: "https://www.albrektsguld.se/article/klocka_med_dekorativ_lank" },
  //     { id: 537, price:'7 999:-', discount:'-15%', title: "Ring med labbodlade diamanter", description: "Ring i återvunnet 18K vitt guld med 18<br>  labbodlade diamanter totalt 0.14 ct TWVS.", imgPro: guld3, url: "https://www.albrektsguld.se/article/ring_med_labbodlade_diamanter_20151891" },
  //     { id: 538, price:'199:-', discount:'-10%', title: "Halsband i stål", description: "Halsband i guldpläterat stål i längden<br> 45 + 8cm.", imgPro: guld4, url: "https://www.albrektsguld.se/article/halsband_i_stal_20152503" },
  //     { id: 539, price:'10 999:-', discount:'-8%', title: "Ring i 18K guld", description: "Klackring i 18K guld", imgPro: guld5, url: "https://www.albrektsguld.se/article/ring_i_18k_guld_20117510" },
  //     { id: 540, price:'449:-', discount:'-12%', title: "Herrklocka", description: "Klocka i svartfärgad metall", imgPro: guld6, url: "https://www.albrektsguld.se/article/herrklocka_20023416" },
  //   ],
  //   image: urochpennLogga,
  // },
  // {
  //   name: "Hallbergguld",
  //   row: [
  //     { id: 547, price:'16 999:-', discount:'-18%', title: "Ring med labbodlade diamanter", description: "Ring i återvunnet 18K gult guld med en oval<br> IGI-certifierad labbodlad diamant 0.50 ct.", imgPro: guld1, url: "https://www.albrektsguld.se/article/ring_med_labbodlade_diamanter_20152016" },
  //     { id: 548, price:'349:-', discount:'-10%', title: "Klocka med dekorativ länk", description: "Guldfärgad klocka med dekorativ länk<br> med vita kristaller.", imgPro: guld2, url: "https://www.albrektsguld.se/article/klocka_med_dekorativ_lank" },
  //     { id: 549, price:'7 999:-', discount:'-15%', title: "Ring med labbodlade diamanter", description: "Ring i återvunnet 18K vitt guld med 18<br>  labbodlade diamanter totalt 0.14 ct TWVS.", imgPro: guld3, url: "https://www.albrektsguld.se/article/ring_med_labbodlade_diamanter_20151891" },
  //     { id: 550, price:'199:-', discount:'-10%', title: "Halsband i stål", description: "Halsband i guldpläterat stål i längden<br> 45 + 8cm.", imgPro: guld4, url: "https://www.albrektsguld.se/article/halsband_i_stal_20152503" },
  //     { id: 551, price:'10 999:-', discount:'-8%', title: "Ring i 18K guld", description: "Klackring i 18K guld", imgPro: guld5, url: "https://www.albrektsguld.se/article/ring_i_18k_guld_20117510" },
  //     { id: 552, price:'449:-', discount:'-12%', title: "Herrklocka", description: "Klocka i svartfärgad metall", imgPro: guld6, url: "https://www.albrektsguld.se/article/herrklocka_20023416" },
  //   ],
  //   image: hallbergguldLogga,
  // },
 
];


function shuffle(array) {
  let currentIndex = array.length,
    temporaryValue,
    randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

function HomePage({ type }) {
  const { searchText } = useContext(SearchContext); // Använd SearchContext
  const [shuffledRowsWithImages, setShuffledRowsWithImages] = useState([]);

  useEffect(() => {
    setShuffledRowsWithImages(shuffle([...storeRows]));
  }, []);

  console.log(storeRows.length);

  // const filteredRows = shuffledRowsWithImages.filter((item) =>
  //   item?.name.toLowerCase().includes(searchText.toLowerCase()) || item?.row?.title?.toLowerCase().includes(searchText.toLowerCase())
  // );

  const filteredRows = shuffledRowsWithImages.filter((item) => {
    const searchLower = searchText.toLowerCase();

    const nameMatch = item?.name?.toLowerCase().includes(searchLower);

    const titleMatch = item?.row?.some(
      (subItem) => subItem?.title?.toLowerCase().includes(searchLower)
    );

    return nameMatch || titleMatch;
  });



  const adStyle =
    type === "large"
      ? {
          width: "100%",
          height: "100px",

          marginBottom: "16px",
        }
      : {
          height: "100px",
          margin: "20px 0",
          display: "flex",
          justifyContent: "center",
        };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const firstStoreInfo = filteredRows.filter((store) => store).length = 0;




// const TRADEDOUBLER_API_URL = '/api/products';

//   const [products, setProducts] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchProducts = async () => {
//       try {
//         const response = await axios.get(TRADEDOUBLER_API_URL);
//         setProducts(response.data.products); // Justera detta beroende på den exakta strukturen av API-responsen
//         setLoading(false);
//       } catch (error) {
//         setError(error);
//         setLoading(false);
//       }
//     };

//     fetchProducts();
//   }, []);

//   if (loading) return <Box display="flex" justifyContent="center" mt={5}><CircularProgress /></Box>;
//   if (error) {
//     console.error('Error loading products:', error);
//     return (
//       <Box display="flex" justifyContent="center" mt={5}>
//         <Typography color="error">Error loading products: {error.message}</Typography>
//       </Box>
//     );
//   }




  return (
    <>

<Helmet>
  <title>SuperDeals.nu Fantastiska erbjudanden och deals</title>
  <meta
    name="description"
    content="Upptäck fantastiska erbjudanden på SuperDeals.nu. Vi samlar de bästa erbjudandena från alla butiker så att du kan spara pengar på kläder, elektronik, skönhet, fordon och mer."
  />
  <meta
    name="keywords"
    content="superdeals, deals, erbjudanden, rabatter, kläder, elektronik, skönhet, fordon, bästa erbjudandena, billiga priser, shoppa online, rea, superdeals.nu"
  />
  <link rel="canonical" href="https://www.superdeals.nu" />
</Helmet>





{/* <Container>
      <Typography variant="h2" component="h1" gutterBottom>
        Products
      </Typography>
      <Grid container spacing={4}>
        {products.map(product => (
          <Grid item key={product.id} xs={12} sm={6} md={4}>
            <Card>
              <CardMedia
                component="img"
                height="200"
                image={product.productImage.url}
                // alt={product.brand}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {product.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {product.description}
                </Typography>
                <Typography variant="h6" component="div" mt={2}>
                  Price: {product.price}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container> */}





      {/* <Ad type="large" /> */}
      <Box sx={{display: "flex", justifyContent: "center", mt: isMobile? 15 : 0}}>
      <Card 	

        sx={{ minWidth: isMobile? "150px" : '320px', height: isMobile? "120px" : '320px', display: "inline-block", background: "grey", textAlign: "center", mx: isMobile? "10px" : 1 }}
      >
        
          {/* <CardMedia component="img" image={"https://addrevenue.io/t?c=3456855&a=985625&b=6925&t=Impression"} sx={{ height: isMobile? 100 : 200, width: isMobile? 100 : 300, objectFit: 'fill'}}/> */}
          <a href="https://addrevenue.io/t?c=3456855&a=984934&b=3399&u=https://www.servicefinder.se/hantverkare" rel='noreferrer noopener' target="_blank">
          <img src="https://addrevenue.io/t?c=3456855&a=984934&b=3399&t=Impression"style={{height: isMobile? "120px" : "320px", width: isMobile? "150px" : "320px", objectFit: 'fill'}} /></a>

           
        
      </Card>
      <Card
        sx={{ minWidth: isMobile? "150px" : '320px', height: isMobile? "120px" : '320px', display: "inline-block", background: "grey", textAlign: "center", mx: isMobile? "0px" : 1 }}
      >

<a href="https://addrevenue.io/t?c=3456855&a=984578&b=3629&u=https://justincase.se/" rel='noreferrer noopener' target="_blank">
<img src="https://addrevenue.io/t?c=3456855&a=984578&b=3629&t=Impression" style={{height: isMobile? "120px" : "320px", width: isMobile? "150px" : "320px", objectFit: 'fill'}}/></a>


      </Card>
      <Card
        sx={{ minWidth: isMobile? "0px" : '320px', height: isMobile? "0px" : '320px', display: "inline-block", background: "grey", textAlign: "center", mx: isMobile? "0px" : 1 }}
      >
<a href="https://clk.tradedoubler.com/click?p=355347&a=3362802&g=25578362" rel='noreferrer noopener' target="_blank">
<img src="https://hst.tradedoubler.com/file/355347/22.jpg" style={{display: isMobile? "none" : "inline-block", height: "320px", width: "320px", objectFit: 'fill'}}/></a>

      </Card>
      <Card
        sx={{ minWidth: isMobile? "0px" : '320px', height: isMobile? "0px" : '320px', display: "inline-block", background: "grey", textAlign: "center", mx: isMobile? "10px" : 1 }}
      >


<a href="https://addrevenue.io/t?c=3456855&a=985173&b=4612&u=https://www.toborrow.com/privatlan/samla-lan/?utm_source=Addrevenue&utm_medium=affiliates&utm_campaign=privatlan&utm_term=landningssida" rel='noreferrer noopener' target="_blank">
<img src="https://addrevenue.io/t?c=3456855&a=985173&b=4612&t=Impression" style={{display: isMobile? "none" : "inline-block", height: "320px", width: "320px", objectFit: 'fill'}}/></a>

      </Card>
      </Box>



      
      {filteredRows?.map((item, index) => (
        <Box 
        key={index} my={10}
        >
          <Box
          sx={{ margin: isMobile ? 0 : 5}}
          >
          <Box key={index} sx={{ display: "flex", justifyContent: "center", }}>
            {/* <CardMedia
              image={item.image}
              component="img"
              sx={{ 
                display: "flex",
                height: isMobile ? "100%" : "30",
                width: isMobile ? "100%" : "30%",
              }}
            /> */}
            <a href={item.imgUrl} target="blank"> <img src={item.image} alt="logo" style={{width: "300px", height: "100px" }} /></a>


            
          </Box>
          <OfferCards
            stores={item.row}
            name={item}
            storeId={`store${index + 1}`}
          />
          </Box>

            {index === firstStoreInfo && (
            <Swiper    
              spaceBetween={10}
              slidesPerView={1}
              autoplay={{ delay: 3000, disableOnInteraction: false }}
              modules={[Autoplay]}
              style={{ maxWidth: '728px', maxHeight: "90px", display: isMobile? "none" : "flex", overflow: 'hidden', border: '1px solid lightgrey', justifyContent: "center"}}
            >
              <SwiperSlide>
              
              
              <a href="https://addrevenue.io/t?c=3456855&a=985253&b=5837&u=https://www.airtours.se/sista-minuten" rel='noreferrer noopener' target="_blank"><img src="https://addrevenue.io/t?c=3456855&a=985253&b=5837&t=Impression" width="728px" height="90px" /></a>
              </SwiperSlide>

              <SwiperSlide
              
              >
<a href="https://addrevenue.io/t?c=3456855&a=984934&b=3400&u=https://www.servicefinder.se/hantverkare" rel='noreferrer noopener' target="_blank"><img src="https://addrevenue.io/t?c=3456855&a=984934&b=3400&t=Impression" width="728ox" height="90px" border="0"/></a>              </SwiperSlide>
              <SwiperSlide>
              <a href="https://addrevenue.io/t?c=3456855&a=984997&b=2316" rel='noreferrer noopener' target="_blank"><img src="https://addrevenue.io/t?c=3456855&a=984997&b=2316&t=Impression" width="728px" height="90px" border="0"/></a>              </SwiperSlide>
              <SwiperSlide>
              <a href="https://addrevenue.io/t?c=3456855&a=985253&b=5594&u=https://www.airtours.se/charterresor/erbjudanden/kampanjrabatt" rel='noreferrer noopener' target="_blank"><img src="https://addrevenue.io/t?c=3456855&a=985253&b=5594&t=Impression" width="728px" height="90px" /></a>
              </SwiperSlide>
            </Swiper>
          )}
        </Box>
      ))}
    </>
  );
}

export default HomePage;
