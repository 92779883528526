import komplettLogga from "../Img/komplettAd.png";
import webhallenLogga from "../Img/webhallenAd.png";
import sharkLogga from "../Img/sharkAd.png";
import elonLogga from "../Img//elonAd.png";
import computersalgLogga from "../Img/computersalgAd.png";
import proshopLogga from "../Img/proshopAd.png";
import dustinLogga from "../Img/dustinAd.png";
import hifiklubbenLogga from "../Img/hifiklubbenAd.png";
import nordicinkLogga from "../Img/nordicinkAd.png";


import React, { useState, useEffect, useContext } from "react";
import { Box, CardMedia } from "@mui/material";
import OfferCards from "../components/OfferCards";
// import Ad from "../components/Ad";
import { SearchContext } from "../components/SearchContext";
import komp1 from "../Img/komplett1.webp"
import komp2 from "../Img/komp2.avif"
import komp3 from "../Img/komplett3.webp"
import komp4 from "../Img/komplett4.avif"
import komp5 from "../Img/komplett5.avif"
import komp6 from "../Img/komplett6.webp"
import comp1 from "../Img/compsa1.webp"
import comp2 from "../Img/comp2.webp"
import comp3 from "../Img/comp3.webp"
import comp4 from "../Img/comp4.webp"
import comp5 from "../Img/comp5.webp"
import comp6 from "../Img/comp6.webp"
import { Helmet } from "react-helmet-async";

const storeRows = [
  {
    name: "Komplett",
    row: [
      { id: 1, price:'27 990:-', discount:'-19%', title: "Samsung 98 DU9005K LED TV<br> TU98DU9005K", description: "UHD, 4K, Supersize Picture Enhancher,<br> Motion Xcelerator,4K 120Hz Gaming TV", imgPro: komp1, url:"https://www.komplett.se/product/1305505/tv-ljud-bild/tv/samsung-98-du9005k-led-tv-tu98du9005k?_gl=1*5bs5jt*_up*MQ..&gclid=CjwKCAjwyJqzBhBaEiwAWDRJVLvfrCTKEccXI7qcS7r2GM_AEeBx00KD7UvI62Dqjl0BXWm5gEc2CBoCT-cQAvD_BwE&gclsrc=aw.ds" },
      { id: 2, price:'19 990:-', discount:'-15%', title: "Samsung 75 Q70D QLED 4K TV<br> TQ75Q70D", description: "QLED, 4K, HDR, AirSlim Design,<br> 4K AI Upscaling, Quantum Dot", imgPro: komp2, url: "https://www.komplett.se/product/1305525/tv-ljud-bild/tv/samsung-75-q70d-qled-4k-tv-tq75q70d?_gl=1*gmlmpc*_up*MQ..&gclid=CjwKCAjw65-zBhBkEiwAjrqRMEQdzushaR0e4leTQ7_7zoEbuCZObNo49Sn63kyuFFzppcKuQMgeFBoCFN4QAvD_BwE&gclsrc=aw.ds"},
      { id: 3, price:'14 999:-', discount:'-21%', title: "Samsung 75 DU8005 4K Crystal UHD Smart TV<br> TU75DU8005K", description: "UHD, 4K, Dual LED, HDR,AirSlim Design,<br>  4K Upscaling, Motion Xcelerator", imgPro: komp3, url: "https://www.komplett.se/product/1305528/tv-ljud-bild/tv/samsung-75-du8005-4k-crystal-uhd-smart-tv-tu75du8005k?_gl=1*1sk27u*_up*MQ..&gclid=CjwKCAjw65-zBhBkEiwAjrqRMEQdzushaR0e4leTQ7_7zoEbuCZObNo49Sn63kyuFFzppcKuQMgeFBoCFN4QAvD_BwE&gclsrc=aw.ds" },
      { id: 4, price:'13 999:-', discount:'-30%', title: "Samsung 65 Q70D QLED 4K TV<br> TQ65Q70D", description: "QLED, 4K, HDR, AirSlim Design, 4K AI <br>Upscaling, Quantum Dot", imgPro: komp4, url: "https://www.komplett.se/product/1305491/tv-ljud-bild/tv/samsung-65-q70d-qled-4k-tv-tq65q70d?_gl=1*1sk27u*_up*MQ..&gclid=CjwKCAjw65-zBhBkEiwAjrqRMEQdzushaR0e4leTQ7_7zoEbuCZObNo49Sn63kyuFFzppcKuQMgeFBoCFN4QAvD_BwE&gclsrc=aw.ds" },
      { id: 5, price:'11 990:-', discount:'-25%', title: "Samsung 55 Q70D QLED 4K TV<br> TQ55Q70D", description: "QLED, 4K, HDR, AirSlim Design, 4K AI <br>Upscaling, Quantum Dot", imgPro: komp5, url: "https://www.komplett.se/product/1305509/tv-ljud-bild/tv/samsung-55-q70d-qled-4k-tv-tq55q70d?_gl=1*p3ojr*_up*MQ..&gclid=CjwKCAjw65-zBhBkEiwAjrqRMEQdzushaR0e4leTQ7_7zoEbuCZObNo49Sn63kyuFFzppcKuQMgeFBoCFN4QAvD_BwE&gclsrc=aw.ds" },
      { id: 6, price:'9 999:-', discount:'-29%', title: "Samsung 65 DU8005 4K Crystal UHD Smart TV<br> TU65DU8005K", description: "UHD, 4K, Dual LED, HDR, AirSlim Design,<br> 4K Upscaling, Motion Xcelerator", imgPro: komp6, url: "https://www.komplett.se/product/1305524/tv-ljud-bild/tv/samsung-65-du8005-4k-crystal-uhd-smart-tv-tu65du8005k?_gl=1*1ttvcqh*_up*MQ..&gclid=CjwKCAjw65-zBhBkEiwAjrqRMEQdzushaR0e4leTQ7_7zoEbuCZObNo49Sn63kyuFFzppcKuQMgeFBoCFN4QAvD_BwE&gclsrc=aw.ds" },
    ],
    image: komplettLogga,
  },

  {
    name: "Webbhallen",
    row: [
      { id: 31, price:'3.211:-', discount:'-8%', title: "LG UltraGear 27GP850P-B -<br> Gaming Series", description: "LG UltraGear, supremely powerful gear,<br> boosts your chances of victory.", imgPro: comp1, url: "https://www.computersalg.se/i/9740043/lg-ultragear-27gp850p-b-gaming-series-led-sk%c3%a4rm-spel-27-2560-x-1440-qhd-165-hz-nano-ips-400-cd-m-1000-1-displayhdr-400-1-ms" },
      { id: 32, price:'1.097:-', discount:'-12%', title: "Logitech® | MX Keys S", description: "Högpresterande tangentbord, konstruerade<br> för bekvämt, snabbt och med smart<br> belysning och programmerbara tangenter.", imgPro: comp2, url: "https://www.computersalg.se/i/10308608/logitech-mx-keys-s-tastatur-bagbelyst-bluetooth-2-4-ghz-nordisk-layout-grafit" },
      { id: 33, price:'1.130:-', discount:'-10%', title: "Logitech MX Master 3S ", description: "Här är Logitech MX Master 3S - en ikonisk<br> mus i en omarbetad version för ultimat<br> känslighet, prestanda och flöde.", imgPro: comp3, url: "https://www.computersalg.se/i/8337611/logitech-mx-master-3s-mus-laser-7-knappar-tr%c3%a5dl%c3%b6s-bluetooth-2-4-ghz-logitech-bolt-mottagare-grafit" },
      { id: 34, price:'798:-', discount:'-15%', title: "Logitech HD Pro Webcam C920S", description: "C920s Pro HD-webbkamera ger otroligt skarpa,<br> tydliga bilder och detaljerade bilder<br> i naturliga färger.", imgPro: comp4, url: "https://www.computersalg.se/i/5035505/logitech-hd-pro-webcam-c920s-webbkamera-f%c3%a4rg-1920-x-1080-ljud-usb" },
      { id: 35, price:'4.197:-', discount:'-12%', title: "Apple 10.2-inch iPad Wi-Fi - 9:e generation", description: "Kraftfull. Lätt att använda. Allsidig.<br> Nya iPad är utvecklad för allt du gillar<br> att göra.", imgPro: comp5, url: "https://www.computersalg.se/i/7653837/apple-10-2-inch-ipad-wi-fi-9-e-generation-surfplatta-64-gb-10-2-ips-2160-x-1620-rymdgr%c3%a5" },
      { id: 36, price:' 727:-', discount:'-20%', title: "Sony | DualSense™ - Gamepad ", description: "Upptäck en djupare, mycket uppslukande <br> spelupplevelse som väcker action<br> till liv i dina händer.", imgPro: comp6, url: "https://www.computersalg.se/i/7477115/sony-dualsense-gamepad-tr%c3%a5dl%c3%b6s-bluetooth-f%c3%b6r-sony-playstation-5-vit" },
    ],
    image: webhallenLogga,
  },
  {
    name: "Shark",
    row: [
      { id: 31, price:'3.211:-', discount:'-8%', title: "LG UltraGear 27GP850P-B -<br> Gaming Series", description: "LG UltraGear, supremely powerful gear,<br> boosts your chances of victory.", imgPro: comp1, url: "https://www.computersalg.se/i/9740043/lg-ultragear-27gp850p-b-gaming-series-led-sk%c3%a4rm-spel-27-2560-x-1440-qhd-165-hz-nano-ips-400-cd-m-1000-1-displayhdr-400-1-ms" },
      { id: 32, price:'1.097:-', discount:'-12%', title: "Logitech® | MX Keys S", description: "Högpresterande tangentbord, konstruerade<br> för bekvämt, snabbt och med smart<br> belysning och programmerbara tangenter.", imgPro: comp2, url: "https://www.computersalg.se/i/10308608/logitech-mx-keys-s-tastatur-bagbelyst-bluetooth-2-4-ghz-nordisk-layout-grafit" },
      { id: 33, price:'1.130:-', discount:'-10%', title: "Logitech MX Master 3S ", description: "Här är Logitech MX Master 3S - en ikonisk<br> mus i en omarbetad version för ultimat<br> känslighet, prestanda och flöde.", imgPro: comp3, url: "https://www.computersalg.se/i/8337611/logitech-mx-master-3s-mus-laser-7-knappar-tr%c3%a5dl%c3%b6s-bluetooth-2-4-ghz-logitech-bolt-mottagare-grafit" },
      { id: 34, price:'798:-', discount:'-15%', title: "Logitech HD Pro Webcam C920S", description: "C920s Pro HD-webbkamera ger otroligt skarpa,<br> tydliga bilder och detaljerade bilder<br> i naturliga färger.", imgPro: comp4, url: "https://www.computersalg.se/i/5035505/logitech-hd-pro-webcam-c920s-webbkamera-f%c3%a4rg-1920-x-1080-ljud-usb" },
      { id: 35, price:'4.197:-', discount:'-12%', title: "Apple 10.2-inch iPad Wi-Fi - 9:e generation", description: "Kraftfull. Lätt att använda. Allsidig.<br> Nya iPad är utvecklad för allt du gillar<br> att göra.", imgPro: comp5, url: "https://www.computersalg.se/i/7653837/apple-10-2-inch-ipad-wi-fi-9-e-generation-surfplatta-64-gb-10-2-ips-2160-x-1620-rymdgr%c3%a5" },
      { id: 36, price:' 727:-', discount:'-20%', title: "Sony | DualSense™ - Gamepad ", description: "Upptäck en djupare, mycket uppslukande <br> spelupplevelse som väcker action<br> till liv i dina händer.", imgPro: comp6, url: "https://www.computersalg.se/i/7477115/sony-dualsense-gamepad-tr%c3%a5dl%c3%b6s-bluetooth-f%c3%b6r-sony-playstation-5-vit" },
    ],
    image: sharkLogga,
  },
  {
    name: "Elon",
    row: [
      { id: 1, price:'27 990:-', discount:'-19%', title: "Samsung 98 DU9005K LED TV<br> TU98DU9005K", description: "UHD, 4K, Supersize Picture Enhancher,<br> Motion Xcelerator,4K 120Hz Gaming TV", imgPro: komp1, url:"https://www.komplett.se/product/1305505/tv-ljud-bild/tv/samsung-98-du9005k-led-tv-tu98du9005k?_gl=1*5bs5jt*_up*MQ..&gclid=CjwKCAjwyJqzBhBaEiwAWDRJVLvfrCTKEccXI7qcS7r2GM_AEeBx00KD7UvI62Dqjl0BXWm5gEc2CBoCT-cQAvD_BwE&gclsrc=aw.ds" },
      { id: 2, price:'19 990:-', discount:'-15%', title: "Samsung 75 Q70D QLED 4K TV<br> TQ75Q70D", description: "QLED, 4K, HDR, AirSlim Design,<br> 4K AI Upscaling, Quantum Dot", imgPro: komp2, url: "https://www.komplett.se/product/1305525/tv-ljud-bild/tv/samsung-75-q70d-qled-4k-tv-tq75q70d?_gl=1*gmlmpc*_up*MQ..&gclid=CjwKCAjw65-zBhBkEiwAjrqRMEQdzushaR0e4leTQ7_7zoEbuCZObNo49Sn63kyuFFzppcKuQMgeFBoCFN4QAvD_BwE&gclsrc=aw.ds"},
      { id: 3, price:'14 999:-', discount:'-21%', title: "Samsung 75 DU8005 4K Crystal UHD Smart TV<br> TU75DU8005K", description: "UHD, 4K, Dual LED, HDR,AirSlim Design,<br>  4K Upscaling, Motion Xcelerator", imgPro: komp3, url: "https://www.komplett.se/product/1305528/tv-ljud-bild/tv/samsung-75-du8005-4k-crystal-uhd-smart-tv-tu75du8005k?_gl=1*1sk27u*_up*MQ..&gclid=CjwKCAjw65-zBhBkEiwAjrqRMEQdzushaR0e4leTQ7_7zoEbuCZObNo49Sn63kyuFFzppcKuQMgeFBoCFN4QAvD_BwE&gclsrc=aw.ds" },
      { id: 4, price:'13 999:-', discount:'-30%', title: "Samsung 65 Q70D QLED 4K TV<br> TQ65Q70D", description: "QLED, 4K, HDR, AirSlim Design, 4K AI <br>Upscaling, Quantum Dot", imgPro: komp4, url: "https://www.komplett.se/product/1305491/tv-ljud-bild/tv/samsung-65-q70d-qled-4k-tv-tq65q70d?_gl=1*1sk27u*_up*MQ..&gclid=CjwKCAjw65-zBhBkEiwAjrqRMEQdzushaR0e4leTQ7_7zoEbuCZObNo49Sn63kyuFFzppcKuQMgeFBoCFN4QAvD_BwE&gclsrc=aw.ds" },
      { id: 5, price:'11 990:-', discount:'-25%', title: "Samsung 55 Q70D QLED 4K TV<br> TQ55Q70D", description: "QLED, 4K, HDR, AirSlim Design, 4K AI <br>Upscaling, Quantum Dot", imgPro: komp5, url: "https://www.komplett.se/product/1305509/tv-ljud-bild/tv/samsung-55-q70d-qled-4k-tv-tq55q70d?_gl=1*p3ojr*_up*MQ..&gclid=CjwKCAjw65-zBhBkEiwAjrqRMEQdzushaR0e4leTQ7_7zoEbuCZObNo49Sn63kyuFFzppcKuQMgeFBoCFN4QAvD_BwE&gclsrc=aw.ds" },
      { id: 6, price:'9 999:-', discount:'-29%', title: "Samsung 65 DU8005 4K Crystal UHD Smart TV<br> TU65DU8005K", description: "UHD, 4K, Dual LED, HDR, AirSlim Design,<br> 4K Upscaling, Motion Xcelerator", imgPro: komp6, url: "https://www.komplett.se/product/1305524/tv-ljud-bild/tv/samsung-65-du8005-4k-crystal-uhd-smart-tv-tu65du8005k?_gl=1*1ttvcqh*_up*MQ..&gclid=CjwKCAjw65-zBhBkEiwAjrqRMEQdzushaR0e4leTQ7_7zoEbuCZObNo49Sn63kyuFFzppcKuQMgeFBoCFN4QAvD_BwE&gclsrc=aw.ds" },
    ],
    image: elonLogga,
  },
  {
    name: "Computersalg",
    row: [
      { id: 31, price:'3.211:-', discount:'-8%', title: "LG UltraGear 27GP850P-B -<br> Gaming Series", description: "LG UltraGear, supremely powerful gear,<br> boosts your chances of victory.", imgPro: comp1, url: "https://www.computersalg.se/i/9740043/lg-ultragear-27gp850p-b-gaming-series-led-sk%c3%a4rm-spel-27-2560-x-1440-qhd-165-hz-nano-ips-400-cd-m-1000-1-displayhdr-400-1-ms" },
      { id: 32, price:'1.097:-', discount:'-12%', title: "Logitech® | MX Keys S", description: "Högpresterande tangentbord, konstruerade<br> för bekvämt, snabbt och med smart<br> belysning och programmerbara tangenter.", imgPro: comp2, url: "https://www.computersalg.se/i/10308608/logitech-mx-keys-s-tastatur-bagbelyst-bluetooth-2-4-ghz-nordisk-layout-grafit" },
      { id: 33, price:'1.130:-', discount:'-10%', title: "Logitech MX Master 3S ", description: "Här är Logitech MX Master 3S - en ikonisk<br> mus i en omarbetad version för ultimat<br> känslighet, prestanda och flöde.", imgPro: comp3, url: "https://www.computersalg.se/i/8337611/logitech-mx-master-3s-mus-laser-7-knappar-tr%c3%a5dl%c3%b6s-bluetooth-2-4-ghz-logitech-bolt-mottagare-grafit" },
      { id: 34, price:'798:-', discount:'-15%', title: "Logitech HD Pro Webcam C920S", description: "C920s Pro HD-webbkamera ger otroligt skarpa,<br> tydliga bilder och detaljerade bilder<br> i naturliga färger.", imgPro: comp4, url: "https://www.computersalg.se/i/5035505/logitech-hd-pro-webcam-c920s-webbkamera-f%c3%a4rg-1920-x-1080-ljud-usb" },
      { id: 35, price:'4.197:-', discount:'-12%', title: "Apple 10.2-inch iPad Wi-Fi - 9:e generation", description: "Kraftfull. Lätt att använda. Allsidig.<br> Nya iPad är utvecklad för allt du gillar<br> att göra.", imgPro: comp5, url: "https://www.computersalg.se/i/7653837/apple-10-2-inch-ipad-wi-fi-9-e-generation-surfplatta-64-gb-10-2-ips-2160-x-1620-rymdgr%c3%a5" },
      { id: 36, price:' 727:-', discount:'-20%', title: "Sony | DualSense™ - Gamepad ", description: "Upptäck en djupare, mycket uppslukande <br> spelupplevelse som väcker action<br> till liv i dina händer.", imgPro: comp6, url: "https://www.computersalg.se/i/7477115/sony-dualsense-gamepad-tr%c3%a5dl%c3%b6s-bluetooth-f%c3%b6r-sony-playstation-5-vit" },
    ],
    image: computersalgLogga,
  },
  {
    name: "Proshop",
    row: [
      { id: 7, price:'3.211:-', discount:'-8%', title: "LG UltraGear 27GP850P-B -<br> Gaming Series", description: "LG UltraGear, supremely powerful gear,<br> boosts your chances of victory.", imgPro: comp1, url: "https://www.computersalg.se/i/9740043/lg-ultragear-27gp850p-b-gaming-series-led-sk%c3%a4rm-spel-27-2560-x-1440-qhd-165-hz-nano-ips-400-cd-m-1000-1-displayhdr-400-1-ms" },
      { id: 8, price:'1.097:-', discount:'-12%', title: "Logitech® | MX Keys S", description: "Högpresterande tangentbord, konstruerade<br> för bekvämt, snabbt och med smart<br> belysning och programmerbara tangenter.", imgPro: comp2, url: "https://www.computersalg.se/i/10308608/logitech-mx-keys-s-tastatur-bagbelyst-bluetooth-2-4-ghz-nordisk-layout-grafit" },
      { id: 9, price:'1.130:-', discount:'-10%', title: "Logitech MX Master 3S ", description: "Här är Logitech MX Master 3S - en ikonisk<br> mus i en omarbetad version för ultimat<br> känslighet, prestanda och flöde.", imgPro: comp3, url: "https://www.computersalg.se/i/8337611/logitech-mx-master-3s-mus-laser-7-knappar-tr%c3%a5dl%c3%b6s-bluetooth-2-4-ghz-logitech-bolt-mottagare-grafit" },
      { id: 10, price:'798:-', discount:'-15%', title: "Logitech HD Pro Webcam C920S", description: "C920s Pro HD-webbkamera ger otroligt skarpa,<br> tydliga bilder och detaljerade bilder<br> i naturliga färger.", imgPro: comp4, url: "https://www.computersalg.se/i/5035505/logitech-hd-pro-webcam-c920s-webbkamera-f%c3%a4rg-1920-x-1080-ljud-usb" },
      { id: 11, price:'4.197:-', discount:'-12%', title: "Apple 10.2-inch iPad Wi-Fi - 9:e generation", description: "Kraftfull. Lätt att använda. Allsidig.<br> Nya iPad är utvecklad för allt du gillar<br> att göra.", imgPro: comp5, url: "https://www.computersalg.se/i/7653837/apple-10-2-inch-ipad-wi-fi-9-e-generation-surfplatta-64-gb-10-2-ips-2160-x-1620-rymdgr%c3%a5" },
      { id: 12, price:' 727:-', discount:'-20%', title: "Sony | DualSense™ - Gamepad ", description: "Upptäck en djupare, mycket uppslukande <br> spelupplevelse som väcker action<br> till liv i dina händer.", imgPro: comp6, url: "https://www.computersalg.se/i/7477115/sony-dualsense-gamepad-tr%c3%a5dl%c3%b6s-bluetooth-f%c3%b6r-sony-playstation-5-vit" },
    ],
    image: proshopLogga,
  },
  {
    name: "Dustin",
    row: [
      { id: 31, price:'3.211:-', discount:'-8%', title: "LG UltraGear 27GP850P-B -<br> Gaming Series", description: "LG UltraGear, supremely powerful gear,<br> boosts your chances of victory.", imgPro: comp1, url: "https://www.computersalg.se/i/9740043/lg-ultragear-27gp850p-b-gaming-series-led-sk%c3%a4rm-spel-27-2560-x-1440-qhd-165-hz-nano-ips-400-cd-m-1000-1-displayhdr-400-1-ms" },
      { id: 32, price:'1.097:-', discount:'-12%', title: "Logitech® | MX Keys S", description: "Högpresterande tangentbord, konstruerade<br> för bekvämt, snabbt och med smart<br> belysning och programmerbara tangenter.", imgPro: comp2, url: "https://www.computersalg.se/i/10308608/logitech-mx-keys-s-tastatur-bagbelyst-bluetooth-2-4-ghz-nordisk-layout-grafit" },
      { id: 33, price:'1.130:-', discount:'-10%', title: "Logitech MX Master 3S ", description: "Här är Logitech MX Master 3S - en ikonisk<br> mus i en omarbetad version för ultimat<br> känslighet, prestanda och flöde.", imgPro: comp3, url: "https://www.computersalg.se/i/8337611/logitech-mx-master-3s-mus-laser-7-knappar-tr%c3%a5dl%c3%b6s-bluetooth-2-4-ghz-logitech-bolt-mottagare-grafit" },
      { id: 34, price:'798:-', discount:'-15%', title: "Logitech HD Pro Webcam C920S", description: "C920s Pro HD-webbkamera ger otroligt skarpa,<br> tydliga bilder och detaljerade bilder<br> i naturliga färger.", imgPro: comp4, url: "https://www.computersalg.se/i/5035505/logitech-hd-pro-webcam-c920s-webbkamera-f%c3%a4rg-1920-x-1080-ljud-usb" },
      { id: 35, price:'4.197:-', discount:'-12%', title: "Apple 10.2-inch iPad Wi-Fi - 9:e generation", description: "Kraftfull. Lätt att använda. Allsidig.<br> Nya iPad är utvecklad för allt du gillar<br> att göra.", imgPro: comp5, url: "https://www.computersalg.se/i/7653837/apple-10-2-inch-ipad-wi-fi-9-e-generation-surfplatta-64-gb-10-2-ips-2160-x-1620-rymdgr%c3%a5" },
      { id: 36, price:' 727:-', discount:'-20%', title: "Sony | DualSense™ - Gamepad ", description: "Upptäck en djupare, mycket uppslukande <br> spelupplevelse som väcker action<br> till liv i dina händer.", imgPro: comp6, url: "https://www.computersalg.se/i/7477115/sony-dualsense-gamepad-tr%c3%a5dl%c3%b6s-bluetooth-f%c3%b6r-sony-playstation-5-vit" },
    ],
    image: dustinLogga,
  },
  {
    name: "Hifiklubben",
    row: [
      { id: 1, price:'27 990:-', discount:'-19%', title: "Samsung 98 DU9005K LED TV<br> TU98DU9005K", description: "UHD, 4K, Supersize Picture Enhancher,<br> Motion Xcelerator,4K 120Hz Gaming TV", imgPro: komp1, url:"https://www.komplett.se/product/1305505/tv-ljud-bild/tv/samsung-98-du9005k-led-tv-tu98du9005k?_gl=1*5bs5jt*_up*MQ..&gclid=CjwKCAjwyJqzBhBaEiwAWDRJVLvfrCTKEccXI7qcS7r2GM_AEeBx00KD7UvI62Dqjl0BXWm5gEc2CBoCT-cQAvD_BwE&gclsrc=aw.ds" },
      { id: 2, price:'19 990:-', discount:'-15%', title: "Samsung 75 Q70D QLED 4K TV<br> TQ75Q70D", description: "QLED, 4K, HDR, AirSlim Design,<br> 4K AI Upscaling, Quantum Dot", imgPro: komp2, url: "https://www.komplett.se/product/1305525/tv-ljud-bild/tv/samsung-75-q70d-qled-4k-tv-tq75q70d?_gl=1*gmlmpc*_up*MQ..&gclid=CjwKCAjw65-zBhBkEiwAjrqRMEQdzushaR0e4leTQ7_7zoEbuCZObNo49Sn63kyuFFzppcKuQMgeFBoCFN4QAvD_BwE&gclsrc=aw.ds"},
      { id: 3, price:'14 999:-', discount:'-21%', title: "Samsung 75 DU8005 4K Crystal UHD Smart TV<br> TU75DU8005K", description: "UHD, 4K, Dual LED, HDR,AirSlim Design,<br>  4K Upscaling, Motion Xcelerator", imgPro: komp3, url: "https://www.komplett.se/product/1305528/tv-ljud-bild/tv/samsung-75-du8005-4k-crystal-uhd-smart-tv-tu75du8005k?_gl=1*1sk27u*_up*MQ..&gclid=CjwKCAjw65-zBhBkEiwAjrqRMEQdzushaR0e4leTQ7_7zoEbuCZObNo49Sn63kyuFFzppcKuQMgeFBoCFN4QAvD_BwE&gclsrc=aw.ds" },
      { id: 4, price:'13 999:-', discount:'-30%', title: "Samsung 65 Q70D QLED 4K TV<br> TQ65Q70D", description: "QLED, 4K, HDR, AirSlim Design, 4K AI <br>Upscaling, Quantum Dot", imgPro: komp4, url: "https://www.komplett.se/product/1305491/tv-ljud-bild/tv/samsung-65-q70d-qled-4k-tv-tq65q70d?_gl=1*1sk27u*_up*MQ..&gclid=CjwKCAjw65-zBhBkEiwAjrqRMEQdzushaR0e4leTQ7_7zoEbuCZObNo49Sn63kyuFFzppcKuQMgeFBoCFN4QAvD_BwE&gclsrc=aw.ds" },
      { id: 5, price:'11 990:-', discount:'-25%', title: "Samsung 55 Q70D QLED 4K TV<br> TQ55Q70D", description: "QLED, 4K, HDR, AirSlim Design, 4K AI <br>Upscaling, Quantum Dot", imgPro: komp5, url: "https://www.komplett.se/product/1305509/tv-ljud-bild/tv/samsung-55-q70d-qled-4k-tv-tq55q70d?_gl=1*p3ojr*_up*MQ..&gclid=CjwKCAjw65-zBhBkEiwAjrqRMEQdzushaR0e4leTQ7_7zoEbuCZObNo49Sn63kyuFFzppcKuQMgeFBoCFN4QAvD_BwE&gclsrc=aw.ds" },
      { id: 6, price:'9 999:-', discount:'-29%', title: "Samsung 65 DU8005 4K Crystal UHD Smart TV<br> TU65DU8005K", description: "UHD, 4K, Dual LED, HDR, AirSlim Design,<br> 4K Upscaling, Motion Xcelerator", imgPro: komp6, url: "https://www.komplett.se/product/1305524/tv-ljud-bild/tv/samsung-65-du8005-4k-crystal-uhd-smart-tv-tu65du8005k?_gl=1*1ttvcqh*_up*MQ..&gclid=CjwKCAjw65-zBhBkEiwAjrqRMEQdzushaR0e4leTQ7_7zoEbuCZObNo49Sn63kyuFFzppcKuQMgeFBoCFN4QAvD_BwE&gclsrc=aw.ds" },
    ],
    image: hifiklubbenLogga,
  },
  {
    name: "Nordicink",
    row: [
      { id: 31, price:'3.211:-', discount:'-8%', title: "LG UltraGear 27GP850P-B -<br> Gaming Series", description: "LG UltraGear, supremely powerful gear,<br> boosts your chances of victory.", imgPro: comp1, url: "https://www.computersalg.se/i/9740043/lg-ultragear-27gp850p-b-gaming-series-led-sk%c3%a4rm-spel-27-2560-x-1440-qhd-165-hz-nano-ips-400-cd-m-1000-1-displayhdr-400-1-ms" },
      { id: 32, price:'1.097:-', discount:'-12%', title: "Logitech® | MX Keys S", description: "Högpresterande tangentbord, konstruerade<br> för bekvämt, snabbt och med smart<br> belysning och programmerbara tangenter.", imgPro: comp2, url: "https://www.computersalg.se/i/10308608/logitech-mx-keys-s-tastatur-bagbelyst-bluetooth-2-4-ghz-nordisk-layout-grafit" },
      { id: 33, price:'1.130:-', discount:'-10%', title: "Logitech MX Master 3S ", description: "Här är Logitech MX Master 3S - en ikonisk<br> mus i en omarbetad version för ultimat<br> känslighet, prestanda och flöde.", imgPro: comp3, url: "https://www.computersalg.se/i/8337611/logitech-mx-master-3s-mus-laser-7-knappar-tr%c3%a5dl%c3%b6s-bluetooth-2-4-ghz-logitech-bolt-mottagare-grafit" },
      { id: 34, price:'798:-', discount:'-15%', title: "Logitech HD Pro Webcam C920S", description: "C920s Pro HD-webbkamera ger otroligt skarpa,<br> tydliga bilder och detaljerade bilder<br> i naturliga färger.", imgPro: comp4, url: "https://www.computersalg.se/i/5035505/logitech-hd-pro-webcam-c920s-webbkamera-f%c3%a4rg-1920-x-1080-ljud-usb" },
      { id: 35, price:'4.197:-', discount:'-12%', title: "Apple 10.2-inch iPad Wi-Fi - 9:e generation", description: "Kraftfull. Lätt att använda. Allsidig.<br> Nya iPad är utvecklad för allt du gillar<br> att göra.", imgPro: comp5, url: "https://www.computersalg.se/i/7653837/apple-10-2-inch-ipad-wi-fi-9-e-generation-surfplatta-64-gb-10-2-ips-2160-x-1620-rymdgr%c3%a5" },
      { id: 36, price:' 727:-', discount:'-20%', title: "Sony | DualSense™ - Gamepad ", description: "Upptäck en djupare, mycket uppslukande <br> spelupplevelse som väcker action<br> till liv i dina händer.", imgPro: comp6, url: "https://www.computersalg.se/i/7477115/sony-dualsense-gamepad-tr%c3%a5dl%c3%b6s-bluetooth-f%c3%b6r-sony-playstation-5-vit" },
    ],
    image: nordicinkLogga,
  },
];

function shuffle(array) {
  return array.sort(() => Math.random() - 0.5);
}

function Electronics() {
  const [shuffledRowsWithImages, setShuffledRowsWithImages] = useState([]);
  const { searchText } = useContext(SearchContext);
  useEffect(() => {
    setShuffledRowsWithImages(shuffle([...storeRows]));
  }, []);

  const filteredRows = shuffledRowsWithImages.filter((item) =>
    item?.name?.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <>

<Helmet>
  <title>SuperDeals.nu - Fantastiska deals på elektronik</title>
  <meta
    name="description"
    content="Upptäck fantastiska erbjudanden på elektronik på SuperDeals.nu. Hitta de bästa rabatterna på smartphones, laptops, TV-apparater och mer."
  />
  <meta
    name="keywords"
    content="elektronik, deals på elektronik, superdeals elektronik, erbjudanden på smartphones, billiga laptops, rabatter på TV-apparater, smartphones, laptops, TV-apparater, hörlurar, kameror, spelkonsoler, smartwatches, elektronik rea, elektronik rabatt, SuperDeals.nu, teknikprylar, hemelektronik, hushållselektronik, elektronik online, bärbar elektronik, elektronikbutik, ljudsystem, hemunderhållning, bildskärmar, datorer"
  />
  <link rel="canonical" href="https://www.superdeals.nu/elektronik" />
  {/* <meta property="og:title" content="SuperDeals.nu - Fantastiska deals på elektronik" />
  <meta property="og:description" content="Upptäck fantastiska erbjudanden på elektronik på SuperDeals.nu. Hitta de bästa rabatterna på smartphones, laptops, TV-apparater och mer." />
  <meta property="og:url" content="https://www.superdeals.nu/elektronik" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://www.superdeals.nu/images/og-image.jpg" />
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="SuperDeals.nu - Fantastiska deals på elektronik" />
  <meta name="twitter:description" content="Upptäck fantastiska erbjudanden på elektronik på SuperDeals.nu. Hitta de bästa rabatterna på smartphones, laptops, TV-apparater och mer." />
  <meta name="twitter:image" content="https://www.superdeals.nu/images/twitter-image.jpg" /> */}
</Helmet>





      {/* <Ad type="large" /> */}
      {filteredRows?.map((item, index) => (
        <Box key={index} my={2}>
          <img src={item.image} alt="" style={{display: 'block', marginLeft: 'auto', marginRight:'auto'}}/>
          <OfferCards stores={item.row} name={item} storeId={`store${index + 1}`} />
          {/* {index !== filteredRows.length - 1 && <Ad type="small" />} */}
        </Box>
      ))}
    </>
  );
}

export default Electronics;
