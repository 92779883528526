import React from "react";
import { Container, Box, Typography } from "@mui/material";

function AboutUs() {
  return (
    <Box sx={{ backgroundColor: "background.paper", py: 5 }}>
      <Container maxWidth="md">
        <Typography variant="h4" gutterBottom>
          Vår Historia
        </Typography>
        <Typography variant="body1" paragraph>
          Välkommen till Superdeals.nu! Vi är två barndomsvänner som har gått igenom livet tillsammans, från lågstadiets korridorer till fotbollsplanens gröna fält. Vår vänskap har varit en konstant i våra liv, och nu har den lett oss till att skapa något fantastiskt tillsammans.
        </Typography>
        <Typography variant="body1" paragraph>
          Vi har alltid delat samma klassrum och spelat i samma fotbollslag från barnsben till vuxen ålder. Livet tog oss på olika vägar under några år, men ödet förde oss samman igen på det mest oväntade sätt. En spontan träff på en bensinmack avslöjade att vi båda hade följt våra passioner och utbildat oss till systemutvecklare.
        </Typography>
        <Typography variant="body1" paragraph>
          Efter den överraskande återföreningen på bensinmacken insåg vi snabbt att vi delade samma dröm och ambitioner. Vi bestämde oss där och då för att slå ihop våra kunskaper och skapa något stort. Det var början på Superdeals.nu.
        </Typography>
        <Typography variant="body1" paragraph>
          Vår vision var att skapa en plattform där användare enkelt kan jämföra produkter från olika butiker och hitta de bästa erbjudandena. Med vår gemensamma erfarenhet inom systemutveckling började vi arbeta hårt för att förverkliga denna vision. Vårt mål är att göra shoppingupplevelsen smidig, rolig och prisvärd för alla.
        </Typography>
        <Typography variant="body1" paragraph>
          Genom Superdeals.nu vill vi erbjuda en plats där användare kan spara både tid och pengar genom att enkelt hitta de bästa erbjudandena på marknaden. Vår resa har bara börjat, och vi är exalterade över vad framtiden har att erbjuda.
        </Typography>
        <Typography variant="body1" paragraph>
          Tack för att du besöker vår sida och delar denna resa med oss. Vi ser fram emot att hjälpa dig hitta fantastiska deals och göra din shoppingupplevelse bättre.
        </Typography>
      </Container>
    </Box>
  );
}

export default AboutUs;
