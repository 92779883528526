import React, { useContext, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { SearchContext } from "./SearchContext";
import { GrContact } from "react-icons/gr";
import { MdOutlineContactSupport } from "react-icons/md";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { FaComputer } from "react-icons/fa6";
import { TbPerfume } from "react-icons/tb";
import { GiClothes, GiCrystalEarrings, GiHammerNails } from "react-icons/gi";
import { MdFamilyRestroom } from "react-icons/md";
import { FaCarAlt } from "react-icons/fa";
import { ImTextColor } from "react-icons/im";
import "./Navbar.css";  // Importera en CSS-fil för stilar

const pages = [
  { icon: GrContact, info: "Kontakt", path: "/kontakt" },
  { icon: IoIosInformationCircleOutline, info: "Om oss", path: "/om-oss" },
  { icon: MdOutlineContactSupport, info: "Support", path: "/support" },
  { icon: FaComputer, name: "Elektronik", path: "/elektronik" },
  { icon: TbPerfume, name: "Skönhet & Hälsa", path: "/skonhet&halsa" },
  { icon: GiClothes, name: "Kläder", path: "/klader" },
  { icon: GiCrystalEarrings, name: "Accessoarer", path: "/accessoarer" },
  { icon: GiHammerNails, name: "Bygg & Trädgård", path: "/bygg&tradgard" },
  { icon: MdFamilyRestroom, name: "Barn & Familj", path: "/barn&familj" },
  { icon: FaCarAlt, name: "Fordon & Tillbehör", path: "/fordon&tillbehor" },
];

function Navbar() {
  const { searchText, setSearchText } = useContext(SearchContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const inputRef = useRef(null);

  const handleOpenNavMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleCloseNavMenu = () => {
    setMenuOpen(false);
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  return (
    <header className="navbar">
      <div className="navbar-container">
        <button className="menu-icon" onClick={handleOpenNavMenu}>
          &#9776;
        </button>
        <Link to="/" className="navbar-title">
          SuperDeals
        </Link>
        <nav className={`menu ${menuOpen ? "open" : ""}`}>
          {pages.map((page, i) => (
            <Link
              key={i}
              to={page.path}
              className="menu-item"
              onClick={handleCloseNavMenu}
            >
              {React.createElement(page.icon)} {page.info || page.name}
            </Link>
          ))}
        </nav>
        <div className="search-container">
          <input
            type="text"
            placeholder="Sök..."
            value={searchText}
            onChange={handleSearchChange}
            ref={inputRef}
          />
        </div>
      </div>
    </header>
  );
}

export default Navbar;
