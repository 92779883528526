import albertLogga from "../Img/albertAd.png";
import babyvLogga from "../Img/babyvAd.png";
import babylandLogga from "../Img/babylandAd.png";
import jollyroomLogga from "../Img/jollyroomAd.png";

import React, { useState, useEffect, useContext } from "react";
import { Box } from "@mui/material";
import OfferCards from "../components/OfferCards";
// import Ad from "../components/Ad";
import { SearchContext } from "../components/SearchContext";
import babyland1 from "../Img/babyland1.jpg";
import babyland2 from "../Img/babyland2.jpg";
import babyland3 from "../Img/babyland3.jpg";
import babyland4 from "../Img/babyland4.jpg";
import babyland5 from "../Img/babyland5.webp";
import babyland6 from "../Img/babyland6.jpg";
import { Helmet } from "react-helmet-async";

const storeRows = [
  // {
  //     name: "Albert",
  //     row: [
  //         { id: 97, price:'269:-', discount:'40%', title: "Mountain Buggy duet Solskydd Enkel", description: "Solskydd för Mountain Buggy Duet <br> sittdel håller solen <br> och insekterna utanför vagnen.", imgPro: babyland1, url: "https://www.babyland.se/mountain-buggy-duet-solskydd-enkel" },
  //         { id: 98, price:'239:-', discount:'40%', title: "NERF Elite 2.0 Flip Blaster 16 pipor", description: "Ge dig in i striden  <br> med Nerf Elite 2.0 Flipshots Flip-16-blastern! Blastern har 16 pipor – 8 som är framåtvända och 8 bakåtvända.", imgPro: babyland2, url: "https://www.babyland.se/nerf-elite-2-0-flip-blaster-16-pipor" },
  //         { id: 99, price:'419:-', discount:'40%', title: "Uppblåsbar Sprinklerpool (Krabba)", description: "Rolig uppblåsbar pool  <br> med ringar och sprinklersystem!", imgPro: babyland3, url: "https://www.babyland.se/uppblasbar-sprinklerpool-krabba" },
  //         { id: 100, price:'179:-', discount:'40%', title: "Joolz Day/Geo Parasoll (Pink)", description: "Parasoll från Joolz  <br> som passar till Joolz Day+ och Joolz Geo.", imgPro: babyland4, url: "https://www.babyland.se/joolz-daygeo-parasoll-premium-pink" },
  //         { id: 101, price:'179:-', discount:'40%', title: "Smoby Green Strandset", description: "Smoby green! Strandsetet innehåller  <br> kärra, hink, spade och kratta. <br> Packa kärran och rulla till stranden. ", imgPro: babyland5, url: "https://www.babyland.se/smoby-green-strandset" },
  //         { id: 102, price:'53:-', discount:'40%', title: "Swimpy UV-skydd till Babypool", description: "Uv-skydd till Swimpy  <br> babypool för att skugga barnet i poolen.", imgPro: babyland6, url: "https://www.babyland.se/mountain-buggy-duet-solskydd-enkel" },
  //     ],
  //     image: albertLogga,
  //   },
  {
    name: "Babyv",
    row: [
      {
        id: 97,
        price: "4 495 kr",
        discount: "40%",
        title: "Silver Cross Pioneer duovagn, silver/linen",
        imgPro:"https://02.cdn37.se/VE9/images/2.214317/silver-cross-pioneer-duovagn-silverlinen.jpeg",
        url: "https://www.babyv.se/sv/articles/2.232.15581/silver-cross-pioneer-duovagn-silverlinen?ref=superdeals.nu",
      },
      {
        id: 98,
        price: "7 290 kr",
        discount: "40%",
        title: "Silver Cross Pioneer duovagn + simplicity babyskydd + bas",
        imgPro: 'https://02.cdn37.se/VE9/images/2.293931/silver-cross-pioneer-duovagn-simplicity-babyskydd-bas.jpeg',
        url: "https://www.babyv.se/sv/articles/2.232.20974/silver-cross-pioneer-duovagn-simplicity-babyskydd-bas?ref=superdeals.nu",
      },
      {
        id: 99,
        price: "479 kr",
        discount: "40%",
        title: "Twistshake startpaket Tableware bundle, rosa/lila/vit",
        imgPro: 'https://02.cdn37.se/VE9/images/2.204477/twistshake-startpaket-tableware-bundle-rosalilavit.jpeg',
        url: "https://www.babyv.se/sv/articles/2.232.14823/twistshake-startpaket-tableware-bundle-rosalilavit?ref=superdeals.nu",
      },
      {
        id: 100,
        price: "549 kr",
        discount: "40%",
        title: "Skip Hop Mobi ställbart badstöd",
        imgPro: 'https://02.cdn37.se/VE9/images/2.217983/skip-hop-mobi-stallbart-badstod.jpeg',
        url: "https://www.babyv.se/sv/articles/2.232.15797/skip-hop-mobi-stallbart-badstod?ref=superdeals.nu",
      },
      {
        id: 101,
        price: "649 kr",
        discount: "40%",
        title: "Skip Hop potta Made for Me",
        imgPro: 'https://02.cdn37.se/VE9/images/2.186555/skip-hop-potta-made-for-me.jpeg',
        url: "https://www.babyv.se/sv/articles/2.232.12619/skip-hop-potta-made-for-me?ref=superdeals.nu",
      },
      {
        id: 102,
        price: "1 649 kr",
        discount: "40%",
        title: "Twistshake barnmatsberedare 6-i-1, vit",
        imgPro: 'https://02.cdn37.se/VE9/images/2.174969/twistshake-barnmatsberedare-6-i-1-vit.jpeg',
        url: "https://www.babyv.se/sv/articles/2.232.11582/twistshake-barnmatsberedare-6-i-1-vit?ref=superdeals.nu",
      },
      {
        id: 103,
        price: "299 kr",
        discount: "40%",
        title: "Twistshake tallrik & click underlägg, vit",
        imgPro: 'https://02.cdn37.se/VE9/images/2.154779/twistshake-tallrik-click-underlagg-vit.jpeg',
        url: "https://www.babyv.se/sv/articles/2.232.10353/twistshake-tallrik-click-underlagg-vit?ref=superdeals.nu",
      },
      {
        id: 104,
        price: "2 495 kr",
        discount: "40%",
        title: "Nuna Triv liggdel, caviar",
        imgPro: 'https://02.cdn37.se/VE9/images/2.237993/nuna-triv-liggdel-caviar.jpeg',
        url: "https://www.babyv.se/sv/articles/2.232.17262/nuna-triv-liggdel-caviar?ref=superdeals.nu",
      },
    ],
    image: babyvLogga,
  },
  {
    name: "Babyland",
    row: [
      {
        id: 105,
        price: "1 999 kr",
        discount: "40%",
        title: "Kids Concept Utemöbelset",
        imgPro: 'https://media.babyland.se/product-images/L/kids-concept-utemobelset-1.jpg',
        url: "https://www.babyland.se/kids-concept-utemobelset?ref=superdeals.nu",
      },
      {
        id: 106,
        price: "839 kr",
        discount: "40%",
        title: "Kids Concept Kök (Natur/Vit/Grå)",
        imgPro: 'https://media.babyland.se/product-images/L/kids-concept-kok-natur-gra-1.jpg',
        url: "https://www.babyland.se/kids-concept-kok-naturvitgra-1?ref=superdeals.nu",
      },
      {
        id: 107,
        price: "204 kr",
        discount: "40%",
        title: "Kids Concept Tält Star (Blå)",
        imgPro: 'https://media.babyland.se/product-images/L/kids-concept-t%c3%a4lt-star-bl%c3%a5-0.jpg',
        url: "https://www.babyland.se/kids-concept-talt-star-bla?ref=superdeals.nu",
      },
      {
        id: 108,
        price: "599 kr",
        discount: "40%",
        title: "Kids Concept Babygym (Edvin)",
        imgPro: 'https://media.babyland.se/product-images/L/kids-concept-babygym-edvin-1-0.jpg',
        url: "https://www.babyland.se/kids-concept-babygym-edvin-2?ref=superdeals.nu",
      },
      {
        id: 109,
        price: "599 kr",
        discount: "40%",
        title: "Kids Concept Aiden Dockhus Studio (Inkl Möbler)",
        imgPro: 'https://media.babyland.se/product-images/L/kids-concept-hus-studio-inkl-mobler-aiden-8.jpg',
        url: "https://www.babyland.se/kids-concept-aiden-dockhus-studio-inkl-mobler?ref=superdeals.nu",
      },
      {
        id: 110,
        price: "748 kr",
        discount: "40%",
        title: "Kids Concept Tipitält (Naturvit)",
        imgPro: 'https://media.babyland.se/product-images/L/kids-concept-tipitalt-naturvit-1-1.jpg',
        url: "https://www.babyland.se/kids-concept-tipitalt-naturvit-1?ref=superdeals.nu",
      },
      {
        id: 111,
        price: "316 kr",
        discount: "40%",
        title: "Kids Concept Lektunnel (Naturvit)",
        imgPro: 'https://media.babyland.se/product-images/L/kids-concept-lektunnel-naturvit-1-0.jpg',
        url: "https://www.babyland.se/kids-concept-lektunnel-naturvit-1?ref=superdeals.nu",
      },
      {
        id: 112,
        price: "799 kr",
        discount: "40%",
        title: "Kids Concept Lekmatta Fiaspel",
        imgPro: 'https://media.babyland.se/product-images/L/kids-concept-lekmatta-fiaspel-0.jpg',
        url: "https://www.babyland.se/kids-concept-lekmatta-fiaspel?ref=superdeals.nu",
      },
    ],
    image: babylandLogga,
  },
  {
    name: "Jollyroom",
    row: [
      {
        id: 91,
        price: "2 295 kr",
        discount: "40%",
        title: "Beemoo Easy Fly Lux 4 Sulky Inkl. Breezy Sufflett, Jet Black",
        imgPro: 'https://www.jollyroom.se/storage/9215381CCACF52DB4ADC2DD976D0558950BEAC2E061412C6898704C20AD69035/920010689d0c443f998f7e83e29afa71/400-374-0-jpg.Jpeg/media/05b21e175f8a4ddf81967ce62fb846f4/912767.jpeg',
        url: "https://www.jollyroom.se/barnvagnar/barnvagnspaket/sittvagnar/beemoo-easy-fly-lux-4-breezy-jetblack?ref=superdeals.nu",
      },
      {
        id: 92,
        price: "949 kr",
        discount: "40%",
        title: "Ergobaby Adapt Soft Flex Mesh Bärsele, Onyx Black",
        imgPro: 'https://www.jollyroom.se/storage/2E21BB4C6BAB90912BE8CA260F86DBBDD3D08E62154BB7A4E239F95BA45215DF/106ba90ea2254f2f9cf3a071997b3f51/259-450-0-jpg.Jpeg/media/9a9f98335af443fda91ccb466416f8ae/ADAPTSOFTFLEX-BCASFMONYX-1228_1.jpeg',
        url: "https://www.jollyroom.se/babyprodukter/barselar-barsjalar/barselar/ergobaby-adapt-soft-flex-mesh-barsele-onyx-black?ref=superdeals.nu",
      },
      {
        id: 93,
        price: "259 kr",
        discount: "40%",
        title: "OKBaby Roady Resepotta, Grå",
        imgPro: 'https://www.jollyroom.se/storage/75132ECC927352B8D85856B0D795839308A373E928EE8DEEAC4B01C84D2CC7CE/c5612a2dfa174d2891353aca38f10c32/400-400-0-jpg.Jpeg/media/4239471c321c477082736d4d9df240cd/3905-2300-1113_1.jpeg',
        url: "https://www.jollyroom.se/babyprodukter/badrummet/pottor/okbaby-roady-resepotta-gra?ref=superdeals.nu",
      },
      {
        id: 94,
        price: "3 999 kr",
        discount: "40%",
        title: "Pinepeak Studsmatta inkl. Skyddsnät Rektangulär",
        imgPro: 'https://www.jollyroom.se/storage/ma/9736117c1a3d40c28dc3b028df5ed210/ad357508e01b4284851d344567cca38b/400-264-0-jpg.Jpeg/CE41F7196A018C8FFDC8BA1F8D93FFEAD7D09BB6/T801-10-3164_1.jpeg',
        url: "https://www.jollyroom.se/fritid-hobby/utelek/studsmattor/pinepeak-studsmatta-inkl-skyddsnat-rektangular?ref=superdeals.nu",
      },
      {
        id: 95,
        price: "1 995 kr",
        discount: "40%",
        title: "Beemoo Activity Go Aktivitetsvagn, Black/Dark Grey",
        imgPro: 'https://www.jollyroom.se/storage/5D82F80A1A63125337D715AB8EAB5CA460F8088B8063FB99925467EFCDDEE3AC/47b253218cdc4691b5877aeabe8040dc/380-450-0-jpg.Jpeg/media/204f603e8a644231a6656c9960daec13/BM-970D-BlackGrey-1895_1.jpeg',
        url: "https://www.jollyroom.se/barnvagnar/aktivitetsvagnar/joggingvagnar/beemoo-activity-go-aktivitetsvagn-black-dark-grey?ref=superdeals.nu",
      },
      {
        id: 96,
        price: "1 199 kr",
        discount: "40%",
        title: "Thule RideAlong Cykelsits, Dark Grey",
        imgPro: 'https://www.jollyroom.se/storage/ma/70f743f12e934975b779fa2619a4b7ac/b2c8a273cb6d4bbd96f6f0624e732681/400-442-0-jpg.Jpeg/41F85720F47E0BBEEAB5BDC473A590E5FB507040/181090.jpeg',
        url: "https://www.jollyroom.se/sport/cyklar/cykelsitsar/thule-ridealong-cykelsits-dark-grey?ref=superdeals.nu",
      },
      {
        id: 113,
        price: "109 kr",
        discount: "40%",
        title: "Alice & Fox Dockstol med Sittdyna, Rosa",
        imgPro: 'https://www.jollyroom.se/storage/E6C7241EA531401FA8F0689C6D329948AD2380F8692B02773DC9879732EEB9C6/9fdfbb1eb4bf42c88090f58f787fd002/301-450-0-jpg.Jpeg/media/28ee877194574ba68a8f9a5729737999/AF-CLJR2201-Pink-2701_1.jpeg',
        url: "https://www.jollyroom.se/leksaker/dockor-gosedjur/dockvagnar-docksangar/alice-fox-dockstol-med-sittdyna-rosa?ref=superdeals.nu",
      },
      {
        id: 114,
        price: "799 kr",
        discount: "40%",
        title: "Alice & Fox Hus Ritbord med Pall, Vit",
        imgPro: 'https://www.jollyroom.se/storage/4DD58E279B41342E1328E2DEDD06DB76C007F65D9CEE81394A9DB8E7A051159F/7af3dbe3d18d4d69b40cad227796632d/400-424-0-jpg.Jpeg/media/ba7dfe09746f40ffac7103e6e82b693f/SHIDA22016-White-2675_1.jpeg',
        url: "https://www.jollyroom.se/barnrummet/barnmobler/bord/barnbord/alice-fox-hus-ritbord-med-pall-vit?ref=superdeals.nu",
      },
    ],
    image: jollyroomLogga,
  },
];

function shuffle(array) {
  return array.sort(() => Math.random() - 0.5);
}

function Barn() {
  const [shuffledRowsWithImages, setShuffledRowsWithImages] = useState([]);
  const { searchText } = useContext(SearchContext);
  useEffect(() => {
    setShuffledRowsWithImages(shuffle([...storeRows]));
  }, []);

  const filteredRows = shuffledRowsWithImages.filter((item) =>
    item?.name?.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <>
      <Helmet>
        <title>
          SuperDeals.nu - Fantastiska deals på barn- och familjeprodukter
        </title>
        <meta
          name="description"
          content="Upptäck fantastiska erbjudanden på barn- och familjeprodukter på SuperDeals.nu. Hitta de bästa rabatterna på leksaker, kläder, barnvagnar och mer."
        />
        <meta
          name="keywords"
          content="barn, familj, barnprodukter, familjeprodukter, deals på barnprodukter, superdeals barn, erbjudanden på leksaker, billiga barnkläder, rabatter på barnvagnar, leksaker, barnkläder, barnvagnar, babyleksaker, familjeerbjudanden, babykläder, barnmöbler, barnskor, föräldrar, barnomsorg, barn och familj rea, barn och familj rabatt, SuperDeals.nu"
        />
        <link rel="canonical" href="https://www.superdeals.nu/barn&familj" />

        {/* <meta property="og:title" content="SuperDeals.nu - Fantastiska deals på barn- och familjeprodukter" />
  <meta property="og:description" content="Upptäck fantastiska erbjudanden på barn- och familjeprodukter på SuperDeals.nu. Hitta de bästa rabatterna på leksaker, kläder, barnvagnar och mer." />
  <meta property="og:url" content="https://www.superdeals.nu/barn&familj" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://www.superdeals.nu/images/og-image.jpg" />
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="SuperDeals.nu - Fantastiska deals på barn- och familjeprodukter" />
  <meta name="twitter:description" content="Upptäck fantastiska erbjudanden på barn- och familjeprodukter på SuperDeals.nu. Hitta de bästa rabatterna på leksaker, kläder, barnvagnar och mer." />
  <meta name="twitter:image" content="https://www.superdeals.nu/images/twitter-image.jpg" /> */}
      </Helmet>

      {/* <Ad type="large" /> */}
      {filteredRows?.map((item, index) => (
        <Box key={index} my={2}>
          <img
            src={item.image}
            alt=""
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
          <OfferCards
            stores={item.row}
            name={item}
            storeId={`store${index + 1}`}
          />
          {/* {index !== filteredRows.length - 1 && <Ad type="small" />} */}
        </Box>
      ))}
    </>
  );
}

export default Barn;
