import React from "react";

function Ad({ type }) {
  const adStyle =
    type === "large"
      ? {
          width: "100%",
          height: "100px",
          backgroundColor: "lightgrey",
          marginBottom: "16px",
        }
      : {
          width: "100%",
          height: "50px",
          backgroundColor: "lightgrey",
          margin: "16px 0",
        };

  return <div style={adStyle}>Ad Space</div>;
}

export default Ad;
