import React from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Ad from "./Ad"; // Import the Ad component
import { CardMedia } from "@mui/material";

function AllOffers() {
  const { storeId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { from } = location.state;

  // Generate offers for each store dynamically
  const generateStoreOffers = (from) => {
    const storeOffers = {};
    const numberOfStores = 94; // Number of stores
    const offersPerStore = 6; // Number of offers per store

    for (let storeIndex = 1; storeIndex <= numberOfStores; storeIndex++) {
      const storeKey = `store${storeIndex}`;
      storeOffers[storeKey] = [];

      for (let offerIndex = 0; offerIndex < offersPerStore; offerIndex++) {
        const offer = {
          id: from.stores[offerIndex].id,
          title: from.stores[offerIndex].title,
          description: from.stores[offerIndex].description,
          image: from.stores[offerIndex].imgPro,
          url: from.stores[offerIndex].url
        };
        storeOffers[storeKey].push(offer);
      }
    }

    return storeOffers;
  };

  const storeOffers = generateStoreOffers(from);

  const offers = storeOffers[storeId] || [];

  return (
    <Box sx={{ width: "100%", minHeight: "100vh", padding: "16px" }}>
      <Ad type="large" /> {/* Include the top ad */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <CardMedia image={from.name.image} component="img" sx={{ height: '30', width: '30%' }} />
        <Link style={{ cursor: 'pointer' }} underline="hover" onClick={() => navigate(-1)}>
          Go Back...
        </Link>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          width: "100%",
        }}
      >
        {offers.map((offer) => (
          <a key={offer.id} href={offer.url} target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>
          <Card key={offer.id} sx={{ width: 250, margin: 2 }}>
            <CardContent>
              <CardMedia component="img" image={offer.image} />
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }} dangerouslySetInnerHTML={{ __html: offer.title }} />
              <Typography variant="body2" dangerouslySetInnerHTML={{ __html: offer.description }} />
            </CardContent>
          </Card>
          </a>
        ))}
      </Box>
    </Box>
  );
}

export default AllOffers;
