import React from "react";
import { Container, Box, Typography } from "@mui/material";

function CookiesPolicy() {
  return (
    <Box sx={{ backgroundColor: "background.paper", py: 5 }}>
      <Container maxWidth="md">
        <Typography variant="h4" gutterBottom>
          Cookies Policy
        </Typography>
        <Typography variant="body1" paragraph>
          På Superdeals.nu använder vi cookies för att förbättra din upplevelse på vår webbplats. Den här sidan förklarar vad cookies är, hur vi använder dem och dina val gällande deras användning.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Vad är cookies?
        </Typography>
        <Typography variant="body1" paragraph>
          Cookies är små textfiler som lagras på din enhet när du besöker en webbplats. Dessa filer innehåller information som webbplatser kan använda för olika ändamål, inklusive att förbättra din användarupplevelse, analysera trafik och för att möjliggöra vissa funktioner.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Hur vi använder cookies
        </Typography>
        <Typography variant="body1" paragraph>
          Vi använder cookies för att:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">
              Förbättra funktionaliteten på vår webbplats genom att spara dina inställningar och preferenser.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Samla in anonymiserad data för att analysera hur besökare använder vår webbplats och för att förbättra användarupplevelsen.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Möjliggöra vissa funktioner och tjänster, som att spara varukorgar och inloggningsinformation för framtida besök.
            </Typography>
          </li>
        </ul>
        <Typography variant="h6" gutterBottom>
          Dina val gällande cookies
        </Typography>
        <Typography variant="body1" paragraph>
          Du har möjlighet att kontrollera och hantera cookies i din webbläsare. Du kan blockera eller ta bort cookies om du önskar, men detta kan påverka funktionaliteten på vår webbplats och andra webbplatser du besöker.
        </Typography>
        <Typography variant="body1" paragraph>
          Genom att fortsätta använda vår webbplats samtycker du till vår användning av cookies enligt denna policy.
        </Typography>
        <Typography variant="body1">
          För mer information om hur vi hanterar personuppgifter, vänligen läs vår <a href="/privacy-policy">Integritetspolicy</a>.
        </Typography>
      </Container>
    </Box>
  );
}

export default CookiesPolicy;
