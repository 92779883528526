import React from "react";
import { Container, Box, Typography, List, ListItem } from "@mui/material";

function TermsOfService() {
  return (
    <Box sx={{ backgroundColor: "background.paper", py: 5 }}>
      <Container maxWidth="md">
        <Typography variant="h4" gutterBottom>
          Användarvillkor för Superdeals.nu
        </Typography>

        <Typography variant="body1" paragraph>
          Välkommen till Superdeals.nu. Genom att använda vår webbplats godkänner du följande användarvillkor. Läs dessa villkor noggrant.
        </Typography>

        <Typography variant="h6" gutterBottom>
          1. Acceptans av villkor
        </Typography>
        <Typography variant="body1" paragraph>
          Genom att använda vår webbplats godkänner du att följa och vara bunden av dessa användarvillkor. Om du inte godkänner villkoren, vänligen använd inte webbplatsen.
        </Typography>

        <Typography variant="h6" gutterBottom>
          2. Användning av webbplatsen
        </Typography>
        <Typography variant="body1" paragraph>
          Du får endast använda webbplatsen för lagliga ändamål och i enlighet med dessa användarvillkor. Du samtycker till att inte använda webbplatsen:
        </Typography>
        <List>
          <ListItem>
            På något sätt som bryter mot gällande lokala, nationella eller internationella lagar eller förordningar.
          </ListItem>
          <ListItem>
            För att skicka, medvetet ta emot, ladda upp, ladda ner, använda eller återanvända material som inte överensstämmer med våra innehållsstandarder.
          </ListItem>
          <ListItem>
            För att överföra eller skicka oönskat eller obehörigt reklam- eller marknadsföringsmaterial eller någon annan form av liknande uppmaning (spam).
          </ListItem>
          <ListItem>
            För att utge dig för att vara eller försöka utge dig för att vara Superdeals.nu, en anställd på Superdeals.nu, en annan användare, eller någon annan person eller enhet.
          </ListItem>
        </List>

        <Typography variant="h6" gutterBottom>
          3. Ändringar av användarvillkor
        </Typography>
        <Typography variant="body1" paragraph>
          Vi förbehåller oss rätten att när som helst ändra dessa användarvillkor utan föregående meddelande. Alla ändringar träder i kraft omedelbart när de publiceras på denna sida, och din fortsatta användning av webbplatsen innebär att du accepterar de ändrade villkoren.
        </Typography>

        <Typography variant="h6" gutterBottom>
          4. Immateriella rättigheter
        </Typography>
        <Typography variant="body1" paragraph>
          Innehållet på denna webbplats, inklusive men inte begränsat till text, grafik, logotyper, ikoner och bilder, är ägd av eller licensierad till Superdeals.nu och skyddas av internationella upphovsrättslagar. All obehörig användning av innehållet kan bryta mot upphovsrättslagar, varumärkeslagar och andra lagar.
        </Typography>

        <Typography variant="h6" gutterBottom>
          5. Begränsning av ansvar
        </Typography>
        <Typography variant="body1" paragraph>
          Superdeals.nu ansvarar inte för några direkta, indirekta, tillfälliga, följd- eller straffskador som uppstår från din användning av webbplatsen eller från något innehåll på webbplatsen.
        </Typography>

        <Typography variant="h6" gutterBottom>
          6. Länkar till tredje parts webbplatser
        </Typography>
        <Typography variant="body1" paragraph>
          Vår webbplats kan innehålla länkar till tredje parts webbplatser som inte ägs eller kontrolleras av Superdeals.nu. Vi har ingen kontroll över, och tar inget ansvar för, innehållet, sekretesspolicyer eller praxis på någon tredje parts webbplatser.
        </Typography>

        <Typography variant="h6" gutterBottom>
          7. Tillämplig lag
        </Typography>
        <Typography variant="body1" paragraph>
          Dessa användarvillkor regleras och tolkas i enlighet med Sveriges lagar, utan hänsyn till dess lagvalsprinciper.
        </Typography>

        <Typography variant="h6" gutterBottom>
          8. Kontaktinformation
        </Typography>
        <Typography variant="body1" paragraph>
          Om du har några frågor om dessa användarvillkor, vänligen kontakta oss:
        </Typography>
        <Typography variant="body1">
          <strong>Superdeals.nu</strong><br />
          Adressvägen 1<br />
          123 45 Stockholm<br />
          E-post: kontakt@superdeals.nu
        </Typography>
      </Container>
    </Box>
  );
}

export default TermsOfService;
