import kicksLogga from "../Img/kicksAd.png";
import staybeautifulLogga from "../Img/staybeautifulAd.png";
import youLogga from "../Img/youAd.png";
import lhcosmeticsLogga from "../Img/lhcosmeticsAd.png";
import nordicfeel from "../Img/nordicfeelAd.png";
import xlashLogga from "../Img/xlashAd.png";
import glowidLogga from "../Img/glowidAd.png";
import cocopandaLogga from "../Img/cocopandaAd.png";
import skincityLogga from "../Img/skincityAd.png";
import olsoskin from "../Img/osloskinAd.png";
import lykoLogga from "../Img/lykoAd.png";
import rapunzelofswedenLogga from "../Img/rapunzelofswedenAd.png";
import comforthscandinaviaLogga from "../Img/comforthscandinaviaAd.png";
import beautycosLogga from "../Img/beautycosAd.png";
import bangerheadLogga from "../Img/bangerheadAd.png";
import elevenLogga from "../Img/elevenAd.png";
import bodystoreLogga from "../Img/bodystoreAd.png";
import synoptikLogga from "../Img/synoptikAd.png";
import wellobeLogga from "../Img/wellobeAd.png";
import gymgrossistenLogga from "../Img/gymgrossistenAd.png";
import greatlifeLogga from "../Img/greatlifeAd.png";
import dozapoteketLogga from "../Img/dozapoteketAd.png";
import apoteketLogga from "../Img/apoteketAd.png";
import medsLogga from "../Img/medsAd.png";
import lifeLogga from "../Img/lifeAd.png";
import hudoteketLogga from "../Img/hudoteketAd.png";


import React, { useState, useEffect, useContext } from "react";
import { Box } from "@mui/material";
import OfferCards from "../components/OfferCards";
// import Ad from "../components/Ad";
import { SearchContext } from "../components/SearchContext";
import kicks1 from "../Img/kicks1.webp"
import kicks2 from "../Img/kicks2.webp"
import kicks3 from "../Img/kicks3.webp"
import kicks4 from "../Img/kicks4.webp"
import kicks5 from "../Img/kicks5.webp"
import kicks6 from "../Img/kicks6.webp"
import great1 from "../Img/great1.webp"
import great2 from "../Img/great2.jpg"
import great3 from "../Img/great3.jpg"
import great4 from "../Img/great4.webp"
import great5 from "../Img/great5.webp"
import great6 from "../Img/great6.webp"
import { Helmet } from "react-helmet-async";


const storeRows = [
  {
    name: "Kicks",
    row: [
      { id: 7, price:'169:-', discount:'50%', title: "Lumene", description: "Nordic-C Glow Renew Night Cream 50 ml", imgPro: kicks1, url: "https://www.kicks.se/lumene/hudvard/ansiktsvard/ansiktskram/nattkram/lumene-nordic-c-glow-renew-night-cream-50-ml" },
      { id: 8, price:'74:-', discount:'50%', title: "Isadora", description: "Contour Stick'n Brush 36 Warm Mocha", imgPro: kicks2, url: "https://www.kicks.se/isadora/makeup/bas/highlighter/isadora-contour-stickn-brush-36-warm-mocha" },
      { id: 9, price:'139:-', discount:'50%', title: "KICKS Beauty", description: "Derma Roller", imgPro: kicks3, url: "https://www.kicks.se/kicks-beauty/hudvard/ansiktsvard/punktbehandlare/kicks-beauty-derma-roller" },
      { id: 10, price:'74:-', discount:'50%', title: "Isadora", description: "Nature Enhanced <br> Cream Blush 32 Soft Pink", imgPro: kicks4, url: "https://www.kicks.se/isadora/makeup/bas/rouge/isadora-nature-enhanced-cream-blush-32-soft-pink" },
      { id: 11, price:'575:-', discount:'50%', title: "Armani", description: "Designer Cream Foundation 4", imgPro: kicks5, url: "https://www.kicks.se/armani/makeup/bas/foundation/armani-designer-cream-foundation-4" },
      { id: 12, price:'347:-', discount:'50%', title: "PÜR", description: "Best Sellers Kit Golden Medium", imgPro: kicks6, url: "https://www.kicks.se/pur/makeup/presenttips-presentaskar/pur-best-sellers-kit-golden-medium" },
    ],
    image: kicksLogga,
  },
  {
    name: "Staybeautiful",
    row: [
      { id: 7, price:'169:-', discount:'50%', title: "Lumene", description: "Nordic-C Glow Renew Night Cream 50 ml", imgPro: kicks1, url: "https://www.kicks.se/lumene/hudvard/ansiktsvard/ansiktskram/nattkram/lumene-nordic-c-glow-renew-night-cream-50-ml" },
      { id: 8, price:'74:-', discount:'50%', title: "Isadora", description: "Contour Stick'n Brush 36 Warm Mocha", imgPro: kicks2, url: "https://www.kicks.se/isadora/makeup/bas/highlighter/isadora-contour-stickn-brush-36-warm-mocha" },
      { id: 9, price:'139:-', discount:'50%', title: "KICKS Beauty", description: "Derma Roller", imgPro: kicks3, url: "https://www.kicks.se/kicks-beauty/hudvard/ansiktsvard/punktbehandlare/kicks-beauty-derma-roller" },
      { id: 10, price:'74:-', discount:'50%', title: "Isadora", description: "Nature Enhanced <br> Cream Blush 32 Soft Pink", imgPro: kicks4, url: "https://www.kicks.se/isadora/makeup/bas/rouge/isadora-nature-enhanced-cream-blush-32-soft-pink" },
      { id: 11, price:'575:-', discount:'50%', title: "Armani", description: "Designer Cream Foundation 4", imgPro: kicks5, url: "https://www.kicks.se/armani/makeup/bas/foundation/armani-designer-cream-foundation-4" },
      { id: 12, price:'347:-', discount:'50%', title: "PÜR", description: "Best Sellers Kit Golden Medium", imgPro: kicks6, url: "https://www.kicks.se/pur/makeup/presenttips-presentaskar/pur-best-sellers-kit-golden-medium" },
    ],
    image: staybeautifulLogga,
  },
  {
    name: "You",
    row: [
      { id: 7, price:'169:-', discount:'50%', title: "Lumene", description: "Nordic-C Glow Renew Night Cream 50 ml", imgPro: kicks1, url: "https://www.kicks.se/lumene/hudvard/ansiktsvard/ansiktskram/nattkram/lumene-nordic-c-glow-renew-night-cream-50-ml" },
      { id: 8, price:'74:-', discount:'50%', title: "Isadora", description: "Contour Stick'n Brush 36 Warm Mocha", imgPro: kicks2, url: "https://www.kicks.se/isadora/makeup/bas/highlighter/isadora-contour-stickn-brush-36-warm-mocha" },
      { id: 9, price:'139:-', discount:'50%', title: "KICKS Beauty", description: "Derma Roller", imgPro: kicks3, url: "https://www.kicks.se/kicks-beauty/hudvard/ansiktsvard/punktbehandlare/kicks-beauty-derma-roller" },
      { id: 10, price:'74:-', discount:'50%', title: "Isadora", description: "Nature Enhanced <br> Cream Blush 32 Soft Pink", imgPro: kicks4, url: "https://www.kicks.se/isadora/makeup/bas/rouge/isadora-nature-enhanced-cream-blush-32-soft-pink" },
      { id: 11, price:'575:-', discount:'50%', title: "Armani", description: "Designer Cream Foundation 4", imgPro: kicks5, url: "https://www.kicks.se/armani/makeup/bas/foundation/armani-designer-cream-foundation-4" },
      { id: 12, price:'347:-', discount:'50%', title: "PÜR", description: "Best Sellers Kit Golden Medium", imgPro: kicks6, url: "https://www.kicks.se/pur/makeup/presenttips-presentaskar/pur-best-sellers-kit-golden-medium" },
    ],
    image: youLogga,
  },
  {
    name: "Eleven",
    row: [
      { id: 7, price:'169:-', discount:'50%', title: "Lumene", description: "Nordic-C Glow Renew Night Cream 50 ml", imgPro: kicks1, url: "https://www.kicks.se/lumene/hudvard/ansiktsvard/ansiktskram/nattkram/lumene-nordic-c-glow-renew-night-cream-50-ml" },
      { id: 8, price:'74:-', discount:'50%', title: "Isadora", description: "Contour Stick'n Brush 36 Warm Mocha", imgPro: kicks2, url: "https://www.kicks.se/isadora/makeup/bas/highlighter/isadora-contour-stickn-brush-36-warm-mocha" },
      { id: 9, price:'139:-', discount:'50%', title: "KICKS Beauty", description: "Derma Roller", imgPro: kicks3, url: "https://www.kicks.se/kicks-beauty/hudvard/ansiktsvard/punktbehandlare/kicks-beauty-derma-roller" },
      { id: 10, price:'74:-', discount:'50%', title: "Isadora", description: "Nature Enhanced <br> Cream Blush 32 Soft Pink", imgPro: kicks4, url: "https://www.kicks.se/isadora/makeup/bas/rouge/isadora-nature-enhanced-cream-blush-32-soft-pink" },
      { id: 11, price:'575:-', discount:'50%', title: "Armani", description: "Designer Cream Foundation 4", imgPro: kicks5, url: "https://www.kicks.se/armani/makeup/bas/foundation/armani-designer-cream-foundation-4" },
      { id: 12, price:'347:-', discount:'50%', title: "PÜR", description: "Best Sellers Kit Golden Medium", imgPro: kicks6, url: "https://www.kicks.se/pur/makeup/presenttips-presentaskar/pur-best-sellers-kit-golden-medium" },
    ],
    image: elevenLogga,
  },
  {
    name: "Glowid",
    row: [
      { id: 7, price:'169:-', discount:'50%', title: "Lumene", description: "Nordic-C Glow Renew Night Cream 50 ml", imgPro: kicks1, url: "https://www.kicks.se/lumene/hudvard/ansiktsvard/ansiktskram/nattkram/lumene-nordic-c-glow-renew-night-cream-50-ml" },
      { id: 8, price:'74:-', discount:'50%', title: "Isadora", description: "Contour Stick'n Brush 36 Warm Mocha", imgPro: kicks2, url: "https://www.kicks.se/isadora/makeup/bas/highlighter/isadora-contour-stickn-brush-36-warm-mocha" },
      { id: 9, price:'139:-', discount:'50%', title: "KICKS Beauty", description: "Derma Roller", imgPro: kicks3, url: "https://www.kicks.se/kicks-beauty/hudvard/ansiktsvard/punktbehandlare/kicks-beauty-derma-roller" },
      { id: 10, price:'74:-', discount:'50%', title: "Isadora", description: "Nature Enhanced <br> Cream Blush 32 Soft Pink", imgPro: kicks4, url: "https://www.kicks.se/isadora/makeup/bas/rouge/isadora-nature-enhanced-cream-blush-32-soft-pink" },
      { id: 11, price:'575:-', discount:'50%', title: "Armani", description: "Designer Cream Foundation 4", imgPro: kicks5, url: "https://www.kicks.se/armani/makeup/bas/foundation/armani-designer-cream-foundation-4" },
      { id: 12, price:'347:-', discount:'50%', title: "PÜR", description: "Best Sellers Kit Golden Medium", imgPro: kicks6, url: "https://www.kicks.se/pur/makeup/presenttips-presentaskar/pur-best-sellers-kit-golden-medium" },
    ],
    image: glowidLogga,
  },
  {
    name: "ComforthScandinavia",
    row: [
      { id: 7, price:'169:-', discount:'50%', title: "Lumene", description: "Nordic-C Glow Renew Night Cream 50 ml", imgPro: kicks1, url: "https://www.kicks.se/lumene/hudvard/ansiktsvard/ansiktskram/nattkram/lumene-nordic-c-glow-renew-night-cream-50-ml" },
      { id: 8, price:'74:-', discount:'50%', title: "Isadora", description: "Contour Stick'n Brush 36 Warm Mocha", imgPro: kicks2, url: "https://www.kicks.se/isadora/makeup/bas/highlighter/isadora-contour-stickn-brush-36-warm-mocha" },
      { id: 9, price:'139:-', discount:'50%', title: "KICKS Beauty", description: "Derma Roller", imgPro: kicks3, url: "https://www.kicks.se/kicks-beauty/hudvard/ansiktsvard/punktbehandlare/kicks-beauty-derma-roller" },
      { id: 10, price:'74:-', discount:'50%', title: "Isadora", description: "Nature Enhanced <br> Cream Blush 32 Soft Pink", imgPro: kicks4, url: "https://www.kicks.se/isadora/makeup/bas/rouge/isadora-nature-enhanced-cream-blush-32-soft-pink" },
      { id: 11, price:'575:-', discount:'50%', title: "Armani", description: "Designer Cream Foundation 4", imgPro: kicks5, url: "https://www.kicks.se/armani/makeup/bas/foundation/armani-designer-cream-foundation-4" },
      { id: 12, price:'347:-', discount:'50%', title: "PÜR", description: "Best Sellers Kit Golden Medium", imgPro: kicks6, url: "https://www.kicks.se/pur/makeup/presenttips-presentaskar/pur-best-sellers-kit-golden-medium" },
    ],
    image: comforthscandinaviaLogga,
  },
  {
    name: "Beautycos",
    row: [
      { id: 7, price:'169:-', discount:'50%', title: "Lumene", description: "Nordic-C Glow Renew Night Cream 50 ml", imgPro: kicks1, url: "https://www.kicks.se/lumene/hudvard/ansiktsvard/ansiktskram/nattkram/lumene-nordic-c-glow-renew-night-cream-50-ml" },
      { id: 8, price:'74:-', discount:'50%', title: "Isadora", description: "Contour Stick'n Brush 36 Warm Mocha", imgPro: kicks2, url: "https://www.kicks.se/isadora/makeup/bas/highlighter/isadora-contour-stickn-brush-36-warm-mocha" },
      { id: 9, price:'139:-', discount:'50%', title: "KICKS Beauty", description: "Derma Roller", imgPro: kicks3, url: "https://www.kicks.se/kicks-beauty/hudvard/ansiktsvard/punktbehandlare/kicks-beauty-derma-roller" },
      { id: 10, price:'74:-', discount:'50%', title: "Isadora", description: "Nature Enhanced <br> Cream Blush 32 Soft Pink", imgPro: kicks4, url: "https://www.kicks.se/isadora/makeup/bas/rouge/isadora-nature-enhanced-cream-blush-32-soft-pink" },
      { id: 11, price:'575:-', discount:'50%', title: "Armani", description: "Designer Cream Foundation 4", imgPro: kicks5, url: "https://www.kicks.se/armani/makeup/bas/foundation/armani-designer-cream-foundation-4" },
      { id: 12, price:'347:-', discount:'50%', title: "PÜR", description: "Best Sellers Kit Golden Medium", imgPro: kicks6, url: "https://www.kicks.se/pur/makeup/presenttips-presentaskar/pur-best-sellers-kit-golden-medium" },
    ],
    image: beautycosLogga,
  },
  {
    name: "Cocopanda",
    row: [
      { id: 7, price:'169:-', discount:'50%', title: "Lumene", description: "Nordic-C Glow Renew Night Cream 50 ml", imgPro: kicks1, url: "https://www.kicks.se/lumene/hudvard/ansiktsvard/ansiktskram/nattkram/lumene-nordic-c-glow-renew-night-cream-50-ml" },
      { id: 8, price:'74:-', discount:'50%', title: "Isadora", description: "Contour Stick'n Brush 36 Warm Mocha", imgPro: kicks2, url: "https://www.kicks.se/isadora/makeup/bas/highlighter/isadora-contour-stickn-brush-36-warm-mocha" },
      { id: 9, price:'139:-', discount:'50%', title: "KICKS Beauty", description: "Derma Roller", imgPro: kicks3, url: "https://www.kicks.se/kicks-beauty/hudvard/ansiktsvard/punktbehandlare/kicks-beauty-derma-roller" },
      { id: 10, price:'74:-', discount:'50%', title: "Isadora", description: "Nature Enhanced <br> Cream Blush 32 Soft Pink", imgPro: kicks4, url: "https://www.kicks.se/isadora/makeup/bas/rouge/isadora-nature-enhanced-cream-blush-32-soft-pink" },
      { id: 11, price:'575:-', discount:'50%', title: "Armani", description: "Designer Cream Foundation 4", imgPro: kicks5, url: "https://www.kicks.se/armani/makeup/bas/foundation/armani-designer-cream-foundation-4" },
      { id: 12, price:'347:-', discount:'50%', title: "PÜR", description: "Best Sellers Kit Golden Medium", imgPro: kicks6, url: "https://www.kicks.se/pur/makeup/presenttips-presentaskar/pur-best-sellers-kit-golden-medium" },
    ],
    image: cocopandaLogga,
  },
  {
    name: "Bangerhead",
    row: [
      { id: 7, price:'169:-', discount:'50%', title: "Lumene", description: "Nordic-C Glow Renew Night Cream 50 ml", imgPro: kicks1, url: "https://www.kicks.se/lumene/hudvard/ansiktsvard/ansiktskram/nattkram/lumene-nordic-c-glow-renew-night-cream-50-ml" },
      { id: 8, price:'74:-', discount:'50%', title: "Isadora", description: "Contour Stick'n Brush 36 Warm Mocha", imgPro: kicks2, url: "https://www.kicks.se/isadora/makeup/bas/highlighter/isadora-contour-stickn-brush-36-warm-mocha" },
      { id: 9, price:'139:-', discount:'50%', title: "KICKS Beauty", description: "Derma Roller", imgPro: kicks3, url: "https://www.kicks.se/kicks-beauty/hudvard/ansiktsvard/punktbehandlare/kicks-beauty-derma-roller" },
      { id: 10, price:'74:-', discount:'50%', title: "Isadora", description: "Nature Enhanced <br> Cream Blush 32 Soft Pink", imgPro: kicks4, url: "https://www.kicks.se/isadora/makeup/bas/rouge/isadora-nature-enhanced-cream-blush-32-soft-pink" },
      { id: 11, price:'575:-', discount:'50%', title: "Armani", description: "Designer Cream Foundation 4", imgPro: kicks5, url: "https://www.kicks.se/armani/makeup/bas/foundation/armani-designer-cream-foundation-4" },
      { id: 12, price:'347:-', discount:'50%', title: "PÜR", description: "Best Sellers Kit Golden Medium", imgPro: kicks6, url: "https://www.kicks.se/pur/makeup/presenttips-presentaskar/pur-best-sellers-kit-golden-medium" },
    ],
    image: bangerheadLogga,
  },
  {
    name: "Lyko",
    row: [
      { id: 7, price:'169:-', discount:'50%', title: "Lumene", description: "Nordic-C Glow Renew Night Cream 50 ml", imgPro: kicks1, url: "https://www.kicks.se/lumene/hudvard/ansiktsvard/ansiktskram/nattkram/lumene-nordic-c-glow-renew-night-cream-50-ml" },
      { id: 8, price:'74:-', discount:'50%', title: "Isadora", description: "Contour Stick'n Brush 36 Warm Mocha", imgPro: kicks2, url: "https://www.kicks.se/isadora/makeup/bas/highlighter/isadora-contour-stickn-brush-36-warm-mocha" },
      { id: 9, price:'139:-', discount:'50%', title: "KICKS Beauty", description: "Derma Roller", imgPro: kicks3, url: "https://www.kicks.se/kicks-beauty/hudvard/ansiktsvard/punktbehandlare/kicks-beauty-derma-roller" },
      { id: 10, price:'74:-', discount:'50%', title: "Isadora", description: "Nature Enhanced <br> Cream Blush 32 Soft Pink", imgPro: kicks4, url: "https://www.kicks.se/isadora/makeup/bas/rouge/isadora-nature-enhanced-cream-blush-32-soft-pink" },
      { id: 11, price:'575:-', discount:'50%', title: "Armani", description: "Designer Cream Foundation 4", imgPro: kicks5, url: "https://www.kicks.se/armani/makeup/bas/foundation/armani-designer-cream-foundation-4" },
      { id: 12, price:'347:-', discount:'50%', title: "PÜR", description: "Best Sellers Kit Golden Medium", imgPro: kicks6, url: "https://www.kicks.se/pur/makeup/presenttips-presentaskar/pur-best-sellers-kit-golden-medium" },
    ],
    image: lykoLogga,
  },
  {
    name: "LhCosmetics",
    row: [
      { id: 7, price:'169:-', discount:'50%', title: "Lumene", description: "Nordic-C Glow Renew Night Cream 50 ml", imgPro: kicks1, url: "https://www.kicks.se/lumene/hudvard/ansiktsvard/ansiktskram/nattkram/lumene-nordic-c-glow-renew-night-cream-50-ml" },
      { id: 8, price:'74:-', discount:'50%', title: "Isadora", description: "Contour Stick'n Brush 36 Warm Mocha", imgPro: kicks2, url: "https://www.kicks.se/isadora/makeup/bas/highlighter/isadora-contour-stickn-brush-36-warm-mocha" },
      { id: 9, price:'139:-', discount:'50%', title: "KICKS Beauty", description: "Derma Roller", imgPro: kicks3, url: "https://www.kicks.se/kicks-beauty/hudvard/ansiktsvard/punktbehandlare/kicks-beauty-derma-roller" },
      { id: 10, price:'74:-', discount:'50%', title: "Isadora", description: "Nature Enhanced <br> Cream Blush 32 Soft Pink", imgPro: kicks4, url: "https://www.kicks.se/isadora/makeup/bas/rouge/isadora-nature-enhanced-cream-blush-32-soft-pink" },
      { id: 11, price:'575:-', discount:'50%', title: "Armani", description: "Designer Cream Foundation 4", imgPro: kicks5, url: "https://www.kicks.se/armani/makeup/bas/foundation/armani-designer-cream-foundation-4" },
      { id: 12, price:'347:-', discount:'50%', title: "PÜR", description: "Best Sellers Kit Golden Medium", imgPro: kicks6, url: "https://www.kicks.se/pur/makeup/presenttips-presentaskar/pur-best-sellers-kit-golden-medium" },
    ],
    image: lhcosmeticsLogga,
  },
  {
    name: "Nordicfeel",
    row: [
      { id: 7, price:'169:-', discount:'50%', title: "Lumene", description: "Nordic-C Glow Renew Night Cream 50 ml", imgPro: kicks1, url: "https://www.kicks.se/lumene/hudvard/ansiktsvard/ansiktskram/nattkram/lumene-nordic-c-glow-renew-night-cream-50-ml" },
      { id: 8, price:'74:-', discount:'50%', title: "Isadora", description: "Contour Stick'n Brush 36 Warm Mocha", imgPro: kicks2, url: "https://www.kicks.se/isadora/makeup/bas/highlighter/isadora-contour-stickn-brush-36-warm-mocha" },
      { id: 9, price:'139:-', discount:'50%', title: "KICKS Beauty", description: "Derma Roller", imgPro: kicks3, url: "https://www.kicks.se/kicks-beauty/hudvard/ansiktsvard/punktbehandlare/kicks-beauty-derma-roller" },
      { id: 10, price:'74:-', discount:'50%', title: "Isadora", description: "Nature Enhanced <br> Cream Blush 32 Soft Pink", imgPro: kicks4, url: "https://www.kicks.se/isadora/makeup/bas/rouge/isadora-nature-enhanced-cream-blush-32-soft-pink" },
      { id: 11, price:'575:-', discount:'50%', title: "Armani", description: "Designer Cream Foundation 4", imgPro: kicks5, url: "https://www.kicks.se/armani/makeup/bas/foundation/armani-designer-cream-foundation-4" },
      { id: 12, price:'347:-', discount:'50%', title: "PÜR", description: "Best Sellers Kit Golden Medium", imgPro: kicks6, url: "https://www.kicks.se/pur/makeup/presenttips-presentaskar/pur-best-sellers-kit-golden-medium" },
    ],
    image: nordicfeel,
  },
  {
    name: "Olsoskin",
    row: [
      { id: 7, price:'169:-', discount:'50%', title: "Lumene", description: "Nordic-C Glow Renew Night Cream 50 ml", imgPro: kicks1, url: "https://www.kicks.se/lumene/hudvard/ansiktsvard/ansiktskram/nattkram/lumene-nordic-c-glow-renew-night-cream-50-ml" },
      { id: 8, price:'74:-', discount:'50%', title: "Isadora", description: "Contour Stick'n Brush 36 Warm Mocha", imgPro: kicks2, url: "https://www.kicks.se/isadora/makeup/bas/highlighter/isadora-contour-stickn-brush-36-warm-mocha" },
      { id: 9, price:'139:-', discount:'50%', title: "KICKS Beauty", description: "Derma Roller", imgPro: kicks3, url: "https://www.kicks.se/kicks-beauty/hudvard/ansiktsvard/punktbehandlare/kicks-beauty-derma-roller" },
      { id: 10, price:'74:-', discount:'50%', title: "Isadora", description: "Nature Enhanced <br> Cream Blush 32 Soft Pink", imgPro: kicks4, url: "https://www.kicks.se/isadora/makeup/bas/rouge/isadora-nature-enhanced-cream-blush-32-soft-pink" },
      { id: 11, price:'575:-', discount:'50%', title: "Armani", description: "Designer Cream Foundation 4", imgPro: kicks5, url: "https://www.kicks.se/armani/makeup/bas/foundation/armani-designer-cream-foundation-4" },
      { id: 12, price:'347:-', discount:'50%', title: "PÜR", description: "Best Sellers Kit Golden Medium", imgPro: kicks6, url: "https://www.kicks.se/pur/makeup/presenttips-presentaskar/pur-best-sellers-kit-golden-medium" },
    ],
    image: olsoskin,
  },
  {
    name: "Rapunzelofsweden",
    row: [
      { id: 7, price:'169:-', discount:'50%', title: "Lumene", description: "Nordic-C Glow Renew Night Cream 50 ml", imgPro: kicks1, url: "https://www.kicks.se/lumene/hudvard/ansiktsvard/ansiktskram/nattkram/lumene-nordic-c-glow-renew-night-cream-50-ml" },
      { id: 8, price:'74:-', discount:'50%', title: "Isadora", description: "Contour Stick'n Brush 36 Warm Mocha", imgPro: kicks2, url: "https://www.kicks.se/isadora/makeup/bas/highlighter/isadora-contour-stickn-brush-36-warm-mocha" },
      { id: 9, price:'139:-', discount:'50%', title: "KICKS Beauty", description: "Derma Roller", imgPro: kicks3, url: "https://www.kicks.se/kicks-beauty/hudvard/ansiktsvard/punktbehandlare/kicks-beauty-derma-roller" },
      { id: 10, price:'74:-', discount:'50%', title: "Isadora", description: "Nature Enhanced <br> Cream Blush 32 Soft Pink", imgPro: kicks4, url: "https://www.kicks.se/isadora/makeup/bas/rouge/isadora-nature-enhanced-cream-blush-32-soft-pink" },
      { id: 11, price:'575:-', discount:'50%', title: "Armani", description: "Designer Cream Foundation 4", imgPro: kicks5, url: "https://www.kicks.se/armani/makeup/bas/foundation/armani-designer-cream-foundation-4" },
      { id: 12, price:'347:-', discount:'50%', title: "PÜR", description: "Best Sellers Kit Golden Medium", imgPro: kicks6, url: "https://www.kicks.se/pur/makeup/presenttips-presentaskar/pur-best-sellers-kit-golden-medium" },
    ],
    image: rapunzelofswedenLogga,
  },
  {
    name: "Skincity",
    row: [
      { id: 7, price:'169:-', discount:'50%', title: "Lumene", description: "Nordic-C Glow Renew Night Cream 50 ml", imgPro: kicks1, url: "https://www.kicks.se/lumene/hudvard/ansiktsvard/ansiktskram/nattkram/lumene-nordic-c-glow-renew-night-cream-50-ml" },
      { id: 8, price:'74:-', discount:'50%', title: "Isadora", description: "Contour Stick'n Brush 36 Warm Mocha", imgPro: kicks2, url: "https://www.kicks.se/isadora/makeup/bas/highlighter/isadora-contour-stickn-brush-36-warm-mocha" },
      { id: 9, price:'139:-', discount:'50%', title: "KICKS Beauty", description: "Derma Roller", imgPro: kicks3, url: "https://www.kicks.se/kicks-beauty/hudvard/ansiktsvard/punktbehandlare/kicks-beauty-derma-roller" },
      { id: 10, price:'74:-', discount:'50%', title: "Isadora", description: "Nature Enhanced <br> Cream Blush 32 Soft Pink", imgPro: kicks4, url: "https://www.kicks.se/isadora/makeup/bas/rouge/isadora-nature-enhanced-cream-blush-32-soft-pink" },
      { id: 11, price:'575:-', discount:'50%', title: "Armani", description: "Designer Cream Foundation 4", imgPro: kicks5, url: "https://www.kicks.se/armani/makeup/bas/foundation/armani-designer-cream-foundation-4" },
      { id: 12, price:'347:-', discount:'50%', title: "PÜR", description: "Best Sellers Kit Golden Medium", imgPro: kicks6, url: "https://www.kicks.se/pur/makeup/presenttips-presentaskar/pur-best-sellers-kit-golden-medium" },
    ],
    image: skincityLogga,
  },
  {
    name: "Xlash",
    row: [
      { id: 7, price:'169:-', discount:'50%', title: "Lumene", description: "Nordic-C Glow Renew Night Cream 50 ml", imgPro: kicks1, url: "https://www.kicks.se/lumene/hudvard/ansiktsvard/ansiktskram/nattkram/lumene-nordic-c-glow-renew-night-cream-50-ml" },
      { id: 8, price:'74:-', discount:'50%', title: "Isadora", description: "Contour Stick'n Brush 36 Warm Mocha", imgPro: kicks2, url: "https://www.kicks.se/isadora/makeup/bas/highlighter/isadora-contour-stickn-brush-36-warm-mocha" },
      { id: 9, price:'139:-', discount:'50%', title: "KICKS Beauty", description: "Derma Roller", imgPro: kicks3, url: "https://www.kicks.se/kicks-beauty/hudvard/ansiktsvard/punktbehandlare/kicks-beauty-derma-roller" },
      { id: 10, price:'74:-', discount:'50%', title: "Isadora", description: "Nature Enhanced <br> Cream Blush 32 Soft Pink", imgPro: kicks4, url: "https://www.kicks.se/isadora/makeup/bas/rouge/isadora-nature-enhanced-cream-blush-32-soft-pink" },
      { id: 11, price:'575:-', discount:'50%', title: "Armani", description: "Designer Cream Foundation 4", imgPro: kicks5, url: "https://www.kicks.se/armani/makeup/bas/foundation/armani-designer-cream-foundation-4" },
      { id: 12, price:'347:-', discount:'50%', title: "PÜR", description: "Best Sellers Kit Golden Medium", imgPro: kicks6, url: "https://www.kicks.se/pur/makeup/presenttips-presentaskar/pur-best-sellers-kit-golden-medium" },
    ],
    image: xlashLogga,
  },

    {
    name: "Bodystore",
    row: [
      { id: 211, price:'399:-', discount:'-20%', title: "Balanced Minerals", description: "Balanced Minerals är ett mineraltillskott av<br> högsta kvalitet baserat på whole food och raw food.", imgPro: great1, url: "https://www.greatlife.se/balanced-minerals-mineralkomplex" },
      { id: 212, price:'449:-', discount:'-10%', title: "Blood Builder – järntillskott", description: "Blood Builder är ett kosttillskott baserat<br> på whole food och raw food som bidrar<br> till normal bildning av röda blodkroppar.", imgPro: great2, url: "https://www.greatlife.se/blood-builder?count=90_tabletter" },
      { id: 213, price:'699:-', discount:'-12%', title: "Blood Builder – järntillskott", description: "Blood Builder är ett kosttillskott baserat<br> på whole food och raw food som bidrar<br> till normal bildning av röda blodkroppar.", imgPro: great3, url: "https://www.greatlife.se/blood-builder?count=180_tabletter" },
      { id: 214, price:'219:-', discount:'-10%', title: "Copper Bisglycinate – Koppar", description: "Copper Bisglycinate Koppar från Thorne är ett<br> koppartillskott med hög biotillgänglighet.", imgPro: great4, url: "https://www.greatlife.se/copper-bisglycinate" },
      { id: 215, price:'279:-', discount:'-20%', title: "DiCalcium Malate – Kalcium", description: "DiCalcium Malate kalcium från Thorne bidrar till<br> att bibehålla normal benstomme.", imgPro: great5, url: "https://www.greatlife.se/dicalcium-malate" },
      { id: 216, price:'379:-', discount:'15%', title: "Elderberry Immune Support<br> Gummies", description: "Supergoda gummies som innehåller <br>ekologiska fläderbär, ekologiska vilda blåbär. ", imgPro: great6, url: "https://www.greatlife.se/gummy-elderberry-immune-support-fladerbar" },
    ],
    image: bodystoreLogga,
  },

  //Importera bilderna härifrån
    {
    name: "Synoptik",
    row: [
      { id: 7, price:'169:-', discount:'50%', title: "Lumene", description: "Nordic-C Glow Renew Night Cream 50 ml", imgPro: kicks1, url: "https://www.kicks.se/lumene/hudvard/ansiktsvard/ansiktskram/nattkram/lumene-nordic-c-glow-renew-night-cream-50-ml" },
      { id: 8, price:'74:-', discount:'50%', title: "Isadora", description: "Contour Stick'n Brush 36 Warm Mocha", imgPro: kicks2, url: "https://www.kicks.se/isadora/makeup/bas/highlighter/isadora-contour-stickn-brush-36-warm-mocha" },
      { id: 9, price:'139:-', discount:'50%', title: "KICKS Beauty", description: "Derma Roller", imgPro: kicks3, url: "https://www.kicks.se/kicks-beauty/hudvard/ansiktsvard/punktbehandlare/kicks-beauty-derma-roller" },
      { id: 10, price:'74:-', discount:'50%', title: "Isadora", description: "Nature Enhanced <br> Cream Blush 32 Soft Pink", imgPro: kicks4, url: "https://www.kicks.se/isadora/makeup/bas/rouge/isadora-nature-enhanced-cream-blush-32-soft-pink" },
      { id: 11, price:'575:-', discount:'50%', title: "Armani", description: "Designer Cream Foundation 4", imgPro: kicks5, url: "https://www.kicks.se/armani/makeup/bas/foundation/armani-designer-cream-foundation-4" },
      { id: 12, price:'347:-', discount:'50%', title: "PÜR", description: "Best Sellers Kit Golden Medium", imgPro: kicks6, url: "https://www.kicks.se/pur/makeup/presenttips-presentaskar/pur-best-sellers-kit-golden-medium" },
    ],
    image: synoptikLogga,
  },

    {
    name: "Meds",
    row: [
      { id: 7, price:'169:-', discount:'50%', title: "Lumene", description: "Nordic-C Glow Renew Night Cream 50 ml", imgPro: kicks1, url: "https://www.kicks.se/lumene/hudvard/ansiktsvard/ansiktskram/nattkram/lumene-nordic-c-glow-renew-night-cream-50-ml" },
      { id: 8, price:'74:-', discount:'50%', title: "Isadora", description: "Contour Stick'n Brush 36 Warm Mocha", imgPro: kicks2, url: "https://www.kicks.se/isadora/makeup/bas/highlighter/isadora-contour-stickn-brush-36-warm-mocha" },
      { id: 9, price:'139:-', discount:'50%', title: "KICKS Beauty", description: "Derma Roller", imgPro: kicks3, url: "https://www.kicks.se/kicks-beauty/hudvard/ansiktsvard/punktbehandlare/kicks-beauty-derma-roller" },
      { id: 10, price:'74:-', discount:'50%', title: "Isadora", description: "Nature Enhanced <br> Cream Blush 32 Soft Pink", imgPro: kicks4, url: "https://www.kicks.se/isadora/makeup/bas/rouge/isadora-nature-enhanced-cream-blush-32-soft-pink" },
      { id: 11, price:'575:-', discount:'50%', title: "Armani", description: "Designer Cream Foundation 4", imgPro: kicks5, url: "https://www.kicks.se/armani/makeup/bas/foundation/armani-designer-cream-foundation-4" },
      { id: 12, price:'347:-', discount:'50%', title: "PÜR", description: "Best Sellers Kit Golden Medium", imgPro: kicks6, url: "https://www.kicks.se/pur/makeup/presenttips-presentaskar/pur-best-sellers-kit-golden-medium" },
    ],
    image: medsLogga,
  },

    {
    name: "Wellobe",
    row: [
      { id: 337, price:'399:-', discount:'-20%', title: "Balanced Minerals", description: "Balanced Minerals är ett mineraltillskott av<br> högsta kvalitet baserat på whole food och raw food.", imgPro: great1, url: "https://www.greatlife.se/balanced-minerals-mineralkomplex" },
      { id: 338, price:'449:-', discount:'-10%', title: "Blood Builder – järntillskott", description: "Blood Builder är ett kosttillskott baserat<br> på whole food och raw food som bidrar<br> till normal bildning av röda blodkroppar.", imgPro: great2, url: "https://www.greatlife.se/blood-builder?count=90_tabletter" },
      { id: 339, price:'699:-', discount:'-12%', title: "Blood Builder – järntillskott", description: "Blood Builder är ett kosttillskott baserat<br> på whole food och raw food som bidrar<br> till normal bildning av röda blodkroppar.", imgPro: great3, url: "https://www.greatlife.se/blood-builder?count=180_tabletter" },
      { id: 340, price:'219:-', discount:'-10%', title: "Copper Bisglycinate – Koppar", description: "Copper Bisglycinate Koppar från Thorne är ett<br> koppartillskott med hög biotillgänglighet.", imgPro: great4, url: "https://www.greatlife.se/copper-bisglycinate" },
      { id: 341, price:'279:-', discount:'-20%', title: "DiCalcium Malate – Kalcium", description: "DiCalcium Malate kalcium från Thorne bidrar till<br> att bibehålla normal benstomme.", imgPro: great5, url: "https://www.greatlife.se/dicalcium-malate" },
      { id: 342, price:'379:-', discount:'15%', title: "Elderberry Immune Support<br> Gummies", description: "Supergoda gummies som innehåller <br>ekologiska fläderbär, ekologiska vilda blåbär. ", imgPro: great6, url: "https://www.greatlife.se/gummy-elderberry-immune-support-fladerbar" },
    ],
    image: wellobeLogga,
  },

    {
    name: "Life",
    row: [
      { id: 271, price:'399:-', discount:'-20%', title: "Balanced Minerals", description: "Balanced Minerals är ett mineraltillskott av<br> högsta kvalitet baserat på whole food och raw food.", imgPro: great1, url: "https://www.greatlife.se/balanced-minerals-mineralkomplex" },
      { id: 272, price:'449:-', discount:'-10%', title: "Blood Builder – järntillskott", description: "Blood Builder är ett kosttillskott baserat<br> på whole food och raw food som bidrar<br> till normal bildning av röda blodkroppar.", imgPro: great2, url: "https://www.greatlife.se/blood-builder?count=90_tabletter" },
      { id: 273, price:'699:-', discount:'-12%', title: "Blood Builder – järntillskott", description: "Blood Builder är ett kosttillskott baserat<br> på whole food och raw food som bidrar<br> till normal bildning av röda blodkroppar.", imgPro: great3, url: "https://www.greatlife.se/blood-builder?count=180_tabletter" },
      { id: 274, price:'219:-', discount:'-10%', title: "Copper Bisglycinate – Koppar", description: "Copper Bisglycinate Koppar från Thorne är ett<br> koppartillskott med hög biotillgänglighet.", imgPro: great4, url: "https://www.greatlife.se/copper-bisglycinate" },
      { id: 275, price:'279:-', discount:'-20%', title: "DiCalcium Malate – Kalcium", description: "DiCalcium Malate kalcium från Thorne bidrar till<br> att bibehålla normal benstomme.", imgPro: great5, url: "https://www.greatlife.se/dicalcium-malate" },
      { id: 276, price:'379:-', discount:'15%', title: "Elderberry Immune Support<br> Gummies", description: "Supergoda gummies som innehåller <br>ekologiska fläderbär, ekologiska vilda blåbär. ", imgPro: great6, url: "https://www.greatlife.se/gummy-elderberry-immune-support-fladerbar" },
    ],
    image: lifeLogga,
  },
  
    {
    name: "Greatlife",
    row: [
      { id: 241, price:'399:-', discount:'-20%', title: "Balanced Minerals", description: "Balanced Minerals är ett mineraltillskott av<br> högsta kvalitet baserat på whole food och raw food.", imgPro: great1, url: "https://www.greatlife.se/balanced-minerals-mineralkomplex" },
      { id: 242, price:'449:-', discount:'-10%', title: "Blood Builder – järntillskott", description: "Blood Builder är ett kosttillskott baserat<br> på whole food och raw food som bidrar<br> till normal bildning av röda blodkroppar.", imgPro: great2, url: "https://www.greatlife.se/blood-builder?count=90_tabletter" },
      { id: 243, price:'699:-', discount:'-12%', title: "Blood Builder – järntillskott", description: "Blood Builder är ett kosttillskott baserat<br> på whole food och raw food som bidrar<br> till normal bildning av röda blodkroppar.", imgPro: great3, url: "https://www.greatlife.se/blood-builder?count=180_tabletter" },
      { id: 244, price:'219:-', discount:'-10%', title: "Copper Bisglycinate – Koppar", description: "Copper Bisglycinate Koppar från Thorne är ett<br> koppartillskott med hög biotillgänglighet.", imgPro: great4, url: "https://www.greatlife.se/copper-bisglycinate" },
      { id: 245, price:'279:-', discount:'-20%', title: "DiCalcium Malate – Kalcium", description: "DiCalcium Malate kalcium från Thorne bidrar till<br> att bibehålla normal benstomme.", imgPro: great5, url: "https://www.greatlife.se/dicalcium-malate" },
      { id: 246, price:'379:-', discount:'15%', title: "Elderberry Immune Support<br> Gummies", description: "Supergoda gummies som innehåller <br>ekologiska fläderbär, ekologiska vilda blåbär. ", imgPro: great6, url: "https://www.greatlife.se/gummy-elderberry-immune-support-fladerbar" },
    ],
    image: greatlifeLogga,
  },

  {
    name: "DozApoteket",
    row: [
      { id: 241, price:'399:-', discount:'-20%', title: "Balanced Minerals", description: "Balanced Minerals är ett mineraltillskott av<br> högsta kvalitet baserat på whole food och raw food.", imgPro: great1, url: "https://www.greatlife.se/balanced-minerals-mineralkomplex" },
      { id: 242, price:'449:-', discount:'-10%', title: "Blood Builder – järntillskott", description: "Blood Builder är ett kosttillskott baserat<br> på whole food och raw food som bidrar<br> till normal bildning av röda blodkroppar.", imgPro: great2, url: "https://www.greatlife.se/blood-builder?count=90_tabletter" },
      { id: 243, price:'699:-', discount:'-12%', title: "Blood Builder – järntillskott", description: "Blood Builder är ett kosttillskott baserat<br> på whole food och raw food som bidrar<br> till normal bildning av röda blodkroppar.", imgPro: great3, url: "https://www.greatlife.se/blood-builder?count=180_tabletter" },
      { id: 244, price:'219:-', discount:'-10%', title: "Copper Bisglycinate – Koppar", description: "Copper Bisglycinate Koppar från Thorne är ett<br> koppartillskott med hög biotillgänglighet.", imgPro: great4, url: "https://www.greatlife.se/copper-bisglycinate" },
      { id: 245, price:'279:-', discount:'-20%', title: "DiCalcium Malate – Kalcium", description: "DiCalcium Malate kalcium från Thorne bidrar till<br> att bibehålla normal benstomme.", imgPro: great5, url: "https://www.greatlife.se/dicalcium-malate" },
      { id: 246, price:'379:-', discount:'15%', title: "Elderberry Immune Support<br> Gummies", description: "Supergoda gummies som innehåller <br>ekologiska fläderbär, ekologiska vilda blåbär. ", imgPro: great6, url: "https://www.greatlife.se/gummy-elderberry-immune-support-fladerbar" },
    ],
    image: dozapoteketLogga,
  },


  {
    name: "Apoteket",
    row: [
      { id: 241, price:'399:-', discount:'-20%', title: "Balanced Minerals", description: "Balanced Minerals är ett mineraltillskott av<br> högsta kvalitet baserat på whole food och raw food.", imgPro: great1, url: "https://www.greatlife.se/balanced-minerals-mineralkomplex" },
      { id: 242, price:'449:-', discount:'-10%', title: "Blood Builder – järntillskott", description: "Blood Builder är ett kosttillskott baserat<br> på whole food och raw food som bidrar<br> till normal bildning av röda blodkroppar.", imgPro: great2, url: "https://www.greatlife.se/blood-builder?count=90_tabletter" },
      { id: 243, price:'699:-', discount:'-12%', title: "Blood Builder – järntillskott", description: "Blood Builder är ett kosttillskott baserat<br> på whole food och raw food som bidrar<br> till normal bildning av röda blodkroppar.", imgPro: great3, url: "https://www.greatlife.se/blood-builder?count=180_tabletter" },
      { id: 244, price:'219:-', discount:'-10%', title: "Copper Bisglycinate – Koppar", description: "Copper Bisglycinate Koppar från Thorne är ett<br> koppartillskott med hög biotillgänglighet.", imgPro: great4, url: "https://www.greatlife.se/copper-bisglycinate" },
      { id: 245, price:'279:-', discount:'-20%', title: "DiCalcium Malate – Kalcium", description: "DiCalcium Malate kalcium från Thorne bidrar till<br> att bibehålla normal benstomme.", imgPro: great5, url: "https://www.greatlife.se/dicalcium-malate" },
      { id: 246, price:'379:-', discount:'15%', title: "Elderberry Immune Support<br> Gummies", description: "Supergoda gummies som innehåller <br>ekologiska fläderbär, ekologiska vilda blåbär. ", imgPro: great6, url: "https://www.greatlife.se/gummy-elderberry-immune-support-fladerbar" },
    ],
    image: apoteketLogga,
  },

  {
    name: "Hudoteket",
    row: [
      { id: 241, price:'399:-', discount:'-20%', title: "Balanced Minerals", description: "Balanced Minerals är ett mineraltillskott av<br> högsta kvalitet baserat på whole food och raw food.", imgPro: great1, url: "https://www.greatlife.se/balanced-minerals-mineralkomplex" },
      { id: 242, price:'449:-', discount:'-10%', title: "Blood Builder – järntillskott", description: "Blood Builder är ett kosttillskott baserat<br> på whole food och raw food som bidrar<br> till normal bildning av röda blodkroppar.", imgPro: great2, url: "https://www.greatlife.se/blood-builder?count=90_tabletter" },
      { id: 243, price:'699:-', discount:'-12%', title: "Blood Builder – järntillskott", description: "Blood Builder är ett kosttillskott baserat<br> på whole food och raw food som bidrar<br> till normal bildning av röda blodkroppar.", imgPro: great3, url: "https://www.greatlife.se/blood-builder?count=180_tabletter" },
      { id: 244, price:'219:-', discount:'-10%', title: "Copper Bisglycinate – Koppar", description: "Copper Bisglycinate Koppar från Thorne är ett<br> koppartillskott med hög biotillgänglighet.", imgPro: great4, url: "https://www.greatlife.se/copper-bisglycinate" },
      { id: 245, price:'279:-', discount:'-20%', title: "DiCalcium Malate – Kalcium", description: "DiCalcium Malate kalcium från Thorne bidrar till<br> att bibehålla normal benstomme.", imgPro: great5, url: "https://www.greatlife.se/dicalcium-malate" },
      { id: 246, price:'379:-', discount:'15%', title: "Elderberry Immune Support<br> Gummies", description: "Supergoda gummies som innehåller <br>ekologiska fläderbär, ekologiska vilda blåbär. ", imgPro: great6, url: "https://www.greatlife.se/gummy-elderberry-immune-support-fladerbar" },
    ],
    image: hudoteketLogga,
  },


    {
    name: "Gymgrossisten",
    row: [
      { id: 247, price:'399:-', discount:'-20%', title: "Balanced Minerals", description: "Balanced Minerals är ett mineraltillskott av<br> högsta kvalitet baserat på whole food och raw food.", imgPro: great1, url: "https://www.greatlife.se/balanced-minerals-mineralkomplex" },
      { id: 248, price:'449:-', discount:'-10%', title: "Blood Builder – järntillskott", description: "Blood Builder är ett kosttillskott baserat<br> på whole food och raw food som bidrar<br> till normal bildning av röda blodkroppar.", imgPro: great2, url: "https://www.greatlife.se/blood-builder?count=90_tabletter" },
      { id: 249, price:'699:-', discount:'-12%', title: "Blood Builder – järntillskott", description: "Blood Builder är ett kosttillskott baserat<br> på whole food och raw food som bidrar<br> till normal bildning av röda blodkroppar.", imgPro: great3, url: "https://www.greatlife.se/blood-builder?count=180_tabletter" },
      { id: 250, price:'219:-', discount:'-10%', title: "Copper Bisglycinate – Koppar", description: "Copper Bisglycinate Koppar från Thorne är ett<br> koppartillskott med hög biotillgänglighet.", imgPro: great4, url: "https://www.greatlife.se/copper-bisglycinate" },
      { id: 251, price:'279:-', discount:'-20%', title: "DiCalcium Malate – Kalcium", description: "DiCalcium Malate kalcium från Thorne bidrar till<br> att bibehålla normal benstomme.", imgPro: great5, url: "https://www.greatlife.se/dicalcium-malate" },
      { id: 252, price:'379:-', discount:'15%', title: "Elderberry Immune Support<br> Gummies", description: "Supergoda gummies som innehåller <br>ekologiska fläderbär, ekologiska vilda blåbär. ", imgPro: great6, url: "https://www.greatlife.se/gummy-elderberry-immune-support-fladerbar" },
    ],
    image: gymgrossistenLogga,
  },


];

function shuffle(array) {
  return array.sort(() => Math.random() - 0.5);
}

function Beauty() {
  const [shuffledRowsWithImages, setShuffledRowsWithImages] = useState([]);
  const { searchText } = useContext(SearchContext);
  useEffect(() => {
    setShuffledRowsWithImages(shuffle([...storeRows]));
  }, []);

  const filteredRows = shuffledRowsWithImages.filter((item) =>
    item?.name?.toLowerCase().includes(searchText.toLowerCase())
  );
  return (
    <>

<Helmet>
  <title>SuperDeals.nu - Fantastiska deals på skönhet- och hälsoprodukter</title>
  <meta
    name="description"
    content="Upptäck fantastiska erbjudanden på skönhet- och hälsoprodukter på SuperDeals.nu. Hitta de bästa rabatterna på smink, hudvård, hårvård, vitaminer och mer."
  />
  <meta
    name="keywords"
    content="skönhet, hälsa, skönhetsprodukter, hälsoprodukter, deals på skönhetsprodukter, superdeals skönhet, erbjudanden på smink, billiga hudvårdsprodukter, rabatter på hårvårdsprodukter, smink, hudvård, hårvård, vitaminer, hälsokost, skönhet och hälsa rea, skönhet och hälsa rabatt, SuperDeals.nu, naturliga skönhetsprodukter, ekologiska hälsoprodukter, personlig vård, wellness, anti-aging, spa produkter"
  />
  <link rel="canonical" href="https://www.superdeals.nu/skonhet&halsa" />
  {/* <meta property="og:title" content="SuperDeals.nu - Fantastiska deals på skönhet- och hälsoprodukter" />
  <meta property="og:description" content="Upptäck fantastiska erbjudanden på skönhet- och hälsoprodukter på SuperDeals.nu. Hitta de bästa rabatterna på smink, hudvård, hårvård, vitaminer och mer." />
  <meta property="og:url" content="https://www.superdeals.nu/skonhet&halsa" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://www.superdeals.nu/images/og-image.jpg" />
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="SuperDeals.nu - Fantastiska deals på skönhet- och hälsoprodukter" />
  <meta name="twitter:description" content="Upptäck fantastiska erbjudanden på skönhet- och hälsoprodukter på SuperDeals.nu. Hitta de bästa rabatterna på smink, hudvård, hårvård, vitaminer och mer." />
  <meta name="twitter:image" content="https://www.superdeals.nu/images/twitter-image.jpg" /> */}
</Helmet>



      {/* <Ad type="large" /> */}
      {filteredRows.map((item, index) => (
        <Box key={index} my={2}>
          <img src={item.image} alt="" style={{display: 'block', marginLeft: 'auto', marginRight:'auto'}}/>
          <OfferCards stores={item.row} name={item} storeId={`store${index + 1}`} />
          {/* {index !== filteredRows.length - 1 && <Ad type="small" />} */}
        </Box>
      ))}
    </>
  );
}

export default Beauty;
