import React, { useRef, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import "../App.css";
import { CardMedia } from "@mui/material";
import Rating from '@mui/material/Rating';

// Funktion för att infoga radbrytningar efter ett visst antal tecken
const insertBreaks = (text, maxChars) => {
  const div = document.createElement('div');
  div.innerHTML = text;
  const cleanText = div.textContent || div.innerText || "";

  const regex = new RegExp(`(.{1,${maxChars}})(\\s|$)`, 'g');
  return cleanText.match(regex).join('<br />');
};

function OfferCards({ title, stores, storeId, name }) {
  const [ratings, setRatings] = useState({});
  const [isHovered, setIsHovered] = useState(false);
  const scrollContainerRef = useRef(null);

  const handleRatingChange = (storeId, newValue) => {
    setRatings((prevRatings) => ({
      ...prevRatings,
      [storeId]: newValue,
    }));
  };

  const scroll = (scrollOffset) => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft += scrollOffset;
    }
  };

  return (
    <Box sx={{ marginBottom: 4 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <Typography variant="h6">{title}</Typography>

        <Link to={`/se-mer/${storeId}`} state={{ from: { stores, name } }} style={{ textDecoration: 'none' }}>
          Se Mer...
        </Link>
      </Box>

      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center'
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <button className={`scroll-button left ${isHovered ? 'visible' : 'hidden'}`} onClick={() => scroll(-200)}>{"<"}</button>
        <Box
          className="scroll-container"
          sx={{
            display: "flex",
            overflowX: "auto",
            whiteSpace: "nowrap",
            scrollBehavior: 'smooth',
            "&::-webkit-scrollbar": { display: "none" },
            flex: 1,
          }}
          ref={scrollContainerRef}
        >
          {stores?.map((store, index) => (
            <a key={index} href={store.url} target="_blank" rel="noopener noreferrer">
              <Card
                key={index}
                sx={{
                  minWidth: 250,
                  height: 300,
                  margin: 1,
                  display: "inline-block",
                  position: 'relative',
                  border: '1px solid #eeeeee',
                  '&:hover': {
                    borderColor: "#e0e0e0",
                  }
                }}
              >
                <CardContent >
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', }}>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "center", mb: 1 }}
                  >
                    <CardMedia
                      component="img"
                      image={store.imgPro}
                      sx={{ height: '150px', width: '150px', objectFit: 'contain' }}
                    />
                  </Box>

                  <Typography 
                    variant="subtitle2" 
                    sx={{ maxWidth: '30px', fontWeight: 'bold', position: 'relative', right: 8 }} 
                    dangerouslySetInnerHTML={{ __html: insertBreaks(store.title, 26) }} 
                  />
                    
                  <Box sx={{ position: 'absolute', bottom: 8, left: 8 }}>
                    <Rating
                      name={`rating-${store.id}`}
                      value={ratings[store.id] || 0}
                      onChange={(event, newValue) => {
                        handleRatingChange(store.id, newValue);
                      }}
                      sx={{ position: 'relative', right: 8, }}
                    />
                  
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                      {store.price}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </a>
          ))}
        </Box>
        <button className={`scroll-button right ${isHovered ? 'visible' : 'hidden'}`} onClick={() => scroll(200)}>{"⇨"}</button>
      </Box>
    </Box>
  );
}

export default OfferCards;
