import React from "react";
import { Container, Box, Typography, Grid, Link } from "@mui/material";

function Support() {
  return (
    <Box sx={{ backgroundColor: "background.paper", py: 5 }}>
      <Container maxWidth="md">
        <Typography variant="h4" gutterBottom>
          Support
        </Typography>
        <Typography variant="body1" paragraph>
          Välkommen till vår supportsida på Superdeals.nu! Vi finns här för att hjälpa dig med eventuella frågor eller problem du kan ha.
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              Vanliga Frågor (FAQ)
            </Typography>
            <Typography variant="body1" paragraph>
              Besök vår FAQ-sektion för att få svar på de vanligaste frågorna om Superdeals.nu, inklusive hur tjänsten fungerar, hur du använder sidan, och mer.
            </Typography>
            <Link href="/faq" color="primary" underline="hover">
              Besök vår FAQ-sektion
            </Link>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              Kontaktinformation
            </Typography>
            <Typography variant="body1" paragraph>
              För personlig support eller för frågor som inte täcks i vår FAQ-sektion, är du välkommen att kontakta oss direkt. Vår kundtjänst finns tillgänglig för att hjälpa dig.
            </Typography>
            <Typography variant="body1">
              E-post: <Link href="mailto:support@superdeals.nu">support@superdeals.nu</Link><br />
              Telefon: +46 123 456 789
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Support;
