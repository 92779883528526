import React from "react";
import { Container, Box, Typography, Grid } from "@mui/material";

function FAQ() {
  return (
    <Box sx={{ backgroundColor: "background.paper", py: 5 }}>
      <Container maxWidth="md">
        <Typography variant="h4" gutterBottom>
          Vanliga Frågor (FAQ)
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Vad är Superdeals.nu?
            </Typography>
            <Typography variant="body1" paragraph>
              Superdeals.nu är en jämförelsesida som hjälper dig hitta de bästa erbjudandena på produkter från olika butiker. Vi gör det enkelt för dig att jämföra priser och hitta de bästa dealsen online.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Hur använder jag Superdeals.nu?
            </Typography>
            <Typography variant="body1" paragraph>
              För att använda Superdeals.nu, navigera till startsidan och sök efter produkter du är intresserad av. Klicka på produkterna för att se var de är tillgängliga och vilka priser som erbjuds. Klicka sedan vidare till den butik där du vill genomföra köpet.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Är det säkert att handla via Superdeals.nu?
            </Typography>
            <Typography variant="body1" paragraph>
              Ja, Superdeals.nu är en jämförelsesida som enbart listar produkter och priser från etablerade och pålitliga butiker. När du klickar vidare för att handla hamnar du direkt på butikens sida, där du genomför ditt köp på deras säkra plattform.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Hur uppdateras priserna på Superdeals.nu?
            </Typography>
            <Typography variant="body1" paragraph>
              Vi strävar efter att hålla prisinformationen på Superdeals.nu så aktuell som möjligt genom regelbundna uppdateringar från våra samarbetspartners. Observera att priserna kan variera över tid och att vi inte kan garantera att de alltid är korrekta i realtid.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Hur kan jag kontakta er om jag har ytterligare frågor?
            </Typography>
            <Typography variant="body1" paragraph>
              Om du har ytterligare frågor eller behöver support är du välkommen att besöka vår supportsida eller kontakta oss direkt via e-post på <a href="mailto:support@superdeals.nu">support@superdeals.nu</a>.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default FAQ;
